import { createReducer } from 'reduxsauce';
import { Types } from '../actions/users';
import { fromJS } from 'immutable';

// the initial state of this reducer
export const INITIAL_STATE = fromJS({
  find: {
    loading: false,
    error: null,
    data: [],
    total: 0,
    limit: 25,
    skip: 0
  },
  create: {
    loading: false,
    error: null,
    data: null
  },
  update: {
    loading: false,
    error: null,
    data: null
  },
  remove: {
    loading: false,
    error: null,
    data: null
  }
});

export const findAttempt = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['find'], fromJS({ loading: true, error: null, data: [], total: 0, limit: action.query.$limit, skip: action.query.$skip }));
};

export const findSuccess = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['find'], fromJS({ loading: false, error: null, data: action.data, total: action.total, limit: action.limit, skip: action.skip }));
};

export const findError = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['find'], fromJS({ loading: false, error: action.error }));
};

export const createAttempt = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['create'], fromJS({ loading: true, error: null, data: null }));
};

export const createSuccess = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['create'], fromJS({ loading: false, error: null, data: action.data }));
};

export const createError = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['create'], fromJS({ loading: false, error: action.error }));
};

export const updateAttempt = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['update'], fromJS({ loading: true, error: null, data: null }));
};

export const updateSuccess = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['update'], fromJS({ loading: false, error: null, data: action.data }));
};

export const updateError = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['update'], fromJS({ loading: false, error: action.error }));
};

export const removeAttempt = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['remove'], fromJS({ loading: true, error: null, data: null }));
};

export const removeSuccess = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['remove'], fromJS({ loading: false, error: null, data: action.data }));
};

export const removeError = (state = INITIAL_STATE, action) => {
  return state.mergeIn(['remove'], fromJS({ loading: false, error: action.error }));
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.FIND_ATTEMPT]: findAttempt,
  [Types.FIND_SUCCESS]: findSuccess,
  [Types.FIND_ERROR]: findError,
  [Types.CREATE_ATTEMPT]: createAttempt,
  [Types.CREATE_SUCCESS]: createSuccess,
  [Types.CREATE_ERROR]: createError,
  [Types.UPDATE_ATTEMPT]: updateAttempt,
  [Types.UPDATE_SUCCESS]: updateSuccess,
  [Types.UPDATE_ERROR]: updateError,
  [Types.REMOVE_ATTEMPT]: removeAttempt,
  [Types.REMOVE_SUCCESS]: removeSuccess,
  [Types.REMOVE_ERROR]: removeError
};

export default createReducer(INITIAL_STATE, HANDLERS);