import React, {
  Component
} from 'react';

class HomeView extends Component {

  constructor (props) {
    super(props);
  }

  render () {
    return (
      <div>
        <h1>Under Dev!!!</h1>
      </div>
    );
  }

}

export default HomeView;