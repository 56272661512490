import React from "react";
import ReactTable from "react-table";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { Link } from "react-router-dom";
import { DEFAULT_FONT_SIZE, CHECKBOX_WIDTH, CELL_SPACES } from "./constants";

const GroupsTable = ({ data, loading, updateGroup, removeGroup, navigate }) => (
  <ReactTable
    className="-striped -highlight"
    data={data}
    defaultPageSize={10}
    loading={loading}
    // getTdProps={(state, rowInfo, column, instance) => {
    //   return {
    //     onClick: (e, handleOriginal) => {
    //       console.log('A Td Element was clicked!')
    //       console.log('it produced this event:', e)
    //       console.log('It was in this column:', column)
    //       console.log('It was in this row:', rowInfo)
    //       console.log('It was in this table instance:', instance)
  
    //       // IMPORTANT! React-Table uses onClick internally to trigger
    //       // events like expanding SubComponents and pivots.
    //       // By default a custom 'onClick' handler will override this functionality.
    //       // If you want to fire the original onClick handler, call the
    //       // 'handleOriginal' function.
    //       if (handleOriginal) {
    //         console.log("handleOriginal")
    //         handleOriginal()
    //       } else {
    //         console.log("navigate")
    //          navigate(`/admin/resources/subscriptions/group/${rowInfo.row.id}`);
    //       }
    //     }
    //   }
    // }}
    defaultFilterMethod={(filter, row) => {
      return (
        `${row[filter.id]}`.toLowerCase().indexOf(filter.value.toLowerCase()) !=
        -1
      );
    }}
    filterable
    defaultSorted={[
      {
        id: "name",
        desc: false
      }
    ]}
    showPageSizeOptions={false}
    columns={[
      {
        Header: "",
        filterable: false,
        width: CHECKBOX_WIDTH,
        Cell: row => (
          <div
            style={{
              width: "100%",
              height: "100%",
              textAlign: "center"
            }}
          >
            <Icon
              className="edit-icon-hover"
              name="edit"
              onClick={e => updateGroup(e, row.original.id)}
            />
          </div>
        ),
        sortable: false,
        resizable: false
      },
      {
        Header: "GRUPO",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            width: DEFAULT_FONT_SIZE * CELL_SPACES,
            Cell: row => (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "right"
                }}
              >
                {row.value}
              </div>
            )
          },
          {
            Header: "Nombre",
            accessor: "name",
            Cell: row => <Link to={`/admin/resources/subscriptions/group/${row.original.id}`}>{row.value}</Link> 
          },
          {
            Header: "Descripcion",
            accessor: "description"
          },
          {
            Header: "Prioridad",
            accessor: "priority"
          },
          {
            Header: "Supergrupo",
            accessor: "GroupId"
          }
        ]
      },
      {
        Header: "METADATA",
        columns: [
          {
            Header: "Creado",
            filterable: false,
            id: "createdAt",
            accessor: d => moment(d.createdAt).format("DD-MM-YYYY HH:mm"),
            width: 145
          },
          {
            Header: "Modificado",
            filterable: false,
            id: "updatedAt",
            accessor: d => moment(d.createdAt).format("DD-MM-YYYY HH:mm"),
            width: 145
          }
        ]
      },
      {
        Header: "",
        filterable: false,
        width: CHECKBOX_WIDTH,
        Cell: row => (
          <div
            style={{
              width: "100%",
              height: "100%",
              textAlign: "center"
            }}
          >
            <Icon
              className="trash-icon-hover"
              name="trash"
              onClick={removeGroup.bind(null, row.original.id)}
            />
          </div>
        ),
        sortable: false,
        resizable: false
      }
    ]}
  />
);

export default GroupsTable;
