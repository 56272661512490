import React, { Component } from 'react';
import {
  Button, Breadcrumb, Container, Modal,
  Divider, Header, Icon, Form,
  Grid, Segment, Message
} from 'semantic-ui-react';
import { Route } from "react-router-dom";
import shortid from 'shortid';
import { translate } from 'react-i18next';
import Radium from 'radium';
import { push } from "react-router-redux";
import { connect } from 'react-redux';
import * as ROUTES from '../../../routes';
import SectionList from './SectionList';
import SectionView from './SectionView';

const INITIAL_STATE = {
  sections: {
    loading: false,
    data: [],
    error: null
  },
  upsertSectionModal: {
    open: false,
    loading: false,
    values: {
      SectionId: null,
      name: '',
      description: '',
      sort: 0,
      security: 0
    },
    error: null
  }
};

class Documentation extends Component {

  constructor (props) {
    super(props);

    this.state = INITIAL_STATE;

    this.SectionService = props.services.feathers.service('api/sections');
    this.TopicService = props.services.feathers.service('api/topics');
  }

  findSections = () => {
    this.setState({
      sections: {
        ...this.state.sections,
        loading: true,
        error: null
      }
    });

    this.SectionService.find({
      query: {
        SectionId: null,
        $limit: 999999,
        $sort: {
          sort: 1
        }
      }
    })
      .then((response => {
        //console.log(response);
        this.setState({
          sections: {
            ...this.state.sections,
            loading: false,
            data: response.data
          }
        });

      }), (error) => {
        this.setState({
          sections: {
            ...this.state.sections,
            loading: false,
            error
          }
        });
      });
  }

  sectionFormInputHandler = (e, input) => {
    let name;
    let value;

    if (input) {
      name = input.id;
      value = input.value;
    } else {
      name = e.target.id;
      value = e.target.value;
    }

    this.setState({
      upsertSectionModal: {
        ...this.state.upsertSectionModal,
        values: {
          ...this.state.upsertSectionModal.values,
          [name]: value
        }
      }
    });
  }

  openUpsertSectionModal = (section = null) => {
    if (section) {
      this.setState({
        upsertSectionModal: {
          ...this.state.upsertSectionModal,
          open: true,
          values: {
            ...section
          }
        }
      });
    } else {
      this.setState({
        upsertSectionModal: {
          ...this.state.upsertSectionModal,
          open: true
        }
      });
    }
  }

  dismissUpsertSectionModal = () => {
    if (this.state.upsertSectionModal.loading) {
      return;
    }

    this.setState({
      upsertSectionModal: INITIAL_STATE.upsertSectionModal
    });
  }

  upsertSection = (e) => {
    e.preventDefault();

    const { upsertSectionModal } = this.state;

    if (upsertSectionModal.loading) {
      return;
    }

    this.setState({
      upsertSectionModal: {
        ...upsertSectionModal,
        loading: true,
        error: null
      }
    }, () => {
      if (upsertSectionModal.values.id) {
        this.SectionService.patch(upsertSectionModal.values.id, upsertSectionModal.values)
          .then((response) => {
            this.setState({
              upsertSectionModal: INITIAL_STATE.upsertSectionModal
            }, this.findSections);
          }, (error) => {
            this.setState({
              upsertSectionModal: {
                ...upsertSectionModal,
                loading: false,
                error
              }
            });
          });
      } else {
        const shorturl = shortid.generate();

        this.SectionService.create({
          ...upsertSectionModal.values,
          shorturl,
          mask: shorturl
        })
          .then((response) => {
            this.setState({
              upsertSectionModal: INITIAL_STATE.upsertSectionModal
            }, this.findSections);
          }, (error) => {
            this.setState({
              upsertSectionModal: {
                ...upsertSectionModal,
                loading: false,
                error
              }
            });
          });
      }
    });
  }

  mapSectionsToOptions = (sections) => sections.map((section, index) => ({
    key: index,
    text: section.name,
    value: section.id
  }))

  componentDidMount () {
    this.findSections();
  }

  static mapStateToProps (state) {
    return {
      authentication: state.authentication.toJS()
    };
  }

  static mapDispatchToProps (dispatch) {
    return {
      navigate: path => dispatch(push(path))
    };
  }

  render () {
    const { services: { feathers }, authentication: { user }, navigate, match } = this.props;
    const { sections, upsertSectionModal } = this.state;

    return (
      <Container fluid style={{ padding: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Section link>Principal</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Documentation</Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Grid>
          <Grid.Column width={4}>
            <Segment attached="top">
              <Header>
                Secciones
                <Icon name="add" onClick={this.openUpsertSectionModal.bind(null, null)} />
              </Header>
            </Segment>
            <Segment attached>
              <Form>
                <Form.Input placeholder="Buscar tema..." />
              </Form>
            </Segment>
            <Segment attached="bottom">
              <SectionList loading={sections.loading} sections={sections.data} openUpsertSectionModal={this.openUpsertSectionModal} />
            </Segment>
          </Grid.Column>
          <Grid.Column width={12}>
            <Route
              exact path={ROUTES.DOCUMENTATION_SECTION}
              render={(props) =>
                <SectionView
                  {...props} feathers={feathers} user={user}
                  navigate={navigate} sections={sections.data}
                  openUpsertSectionModal={this.openUpsertSectionModal} />}
            />
          </Grid.Column>
        </Grid>
        <Modal
          open={upsertSectionModal.open} onClose={this.dismissUpsertSectionModal} size="small">
          <Modal.Header>Select a Photo</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form onSubmit={this.upsertSection} loading={upsertSectionModal.loading}>
                <Form.Group widths="equal">
                  <Form.Select
                    id="SectionId" label="Seccion"
                    options={this.mapSectionsToOptions(sections.data)} loading={sections.loading}
                    onChange={this.sectionFormInputHandler} value={upsertSectionModal.values.SectionId} />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    id="name" label="Nombre"
                    onChange={this.sectionFormInputHandler} value={upsertSectionModal.values.name} />
                  <Form.Input
                    id="description" label="Descripcion"
                    onChange={this.sectionFormInputHandler} value={upsertSectionModal.values.description} />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    id="sort" label="Orden" type="number"
                    onChange={this.sectionFormInputHandler} value={upsertSectionModal.values.sort} />
                  <Form.Input
                    id="security" label="Seguridad" type="number" min={0}
                    onChange={this.sectionFormInputHandler} value={upsertSectionModal.values.security} />
                </Form.Group>
              </Form>
              {
                upsertSectionModal.error &&
                  <Message negative>
                    <Message.Header>{upsertSectionModal.error.name}</Message.Header>
                    <p>{upsertSectionModal.error.message}</p>
                  </Message>
              }
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic icon="remove" negative content="Cancelar"
              disabled={upsertSectionModal.loading}
              onClick={this.dismissUpsertSectionModal} />
            <Button
              icon="checkmark" positive content="Aceptar"
              disabled={upsertSectionModal.loading}
              onClick={this.upsertSection} />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }

}

const StyledView = Radium(Documentation);
const i18nEnhancement = translate('translations')(StyledView);

export default connect(Documentation.mapStateToProps, Documentation.mapDispatchToProps)(i18nEnhancement);