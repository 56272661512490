import { debounce } from "lodash";

export const TICKET_FILTERS = {
  OPEN: "TICKET_FILTERS_OPEN",
  ALL: "TICKET_FILTERS_ALL",
  NOT_REPLIED: "TICKET_FILTERS_NOT_REPLIED",
  SEARCH: "TICKET_FILTERS_SEARCH",
};

export const INITIAL_STATE = {
  groups: {
    loading: false,
    data: [],
    all: [],
    error: null,
  },
  tickets: {
    loading: false,
    data: [],
    error: null,
  },
  createTicket: {
    loading: false,
    error: null,
  },
  activities: {
    loading: false,
    data: [],
    error: null,
  },
  user: {
    loading: false,
    value: null,
    error: null,
  },
  active: {
    ticket: null,
    group: null,
    filter: null,
    searchTerm: null,
  },
};

const helperCreateSubscriptions = (
  service,
  handler,
  events = ["created", "updated", "patched", "removed"]
) => {
  let removers = [];
  let subscriptions = events.reduce((partial, anEvent) => {
    let handlerFunc = handler(anEvent);
    removers.push(() => service.removeListener(anEvent, handlerFunc));
    return { ...partial, [anEvent]: service.on(anEvent, handlerFunc) };
  }, {});
  return {
    subscriptions,
    remover: () => removers.forEach((element) => element()),
  };
};

export default class Logic {
  constructor(options) {
    this.feathers = options.feathers;
    this.kind = options.kind || "ticket";
    // LISTENERS
    this.listeners = [];

    this.limit = 50;
    this.noMoreContent = false;

    // FILTERS
    this.filter = TICKET_FILTERS.NOT_REPLIED;
    this.groupId = null;
    this.ticketId = null;
    this.searchTerm = null;

    // USER
    this.user = {
      loading: false,
      value: options.user,
      error: null,
    };

    // STATS
    this.stats = {
      loading: false,
      data: [],
      error: null,
    };

    // TICKETS
    this.tickets = {
      open: [],
      all: [],
      notReplied: [],
      loading: false,
      error: null,
    };

    this.createTicketMemory = {
      loading: false,
      error: null,
    };

    // GROUPS
    this.groups = {
      data: [],
      loading: false,
      error: null,
    };

    // ACTIVITIES

    this.activities = {
      data: [],
      loading: false,
      error: null,
    };
  }

  loadMoreContent = debounce(() => {
    // //console.log(
    //   "loadMoreContent",
    //   this.limit,
    //   this.noMoreContent,
    //   this.tickets.loading
    // );
    if (!this.noMoreContent && !this.tickets.loading) {
      //console.log("increasedLimit", this.limit, " to ", this.limit + 50);
      this.tickets = {
        ...this.tickets,
        loading: true,
      };
      this.propagate();
      this.limit = this.limit + 50;
      this.loadTickets();
    }
  }, 1000);

  signIn = (username, password) => {
    this.user.loading = true;
    this.user.error = null;
    this.propagate();
    this.feathers
      .authenticate({
        strategy: "local",
        username: username,
        password: password,
      })
      .then((response) =>
        this.feathers.passport.verifyJWT(response.accessToken)
      )
      .then((payload) => this.feathers.service("api/users").get(payload.userId))
      .then((user) => {
        this.user = {
          loading: false,
          value: user,
          error: null,
        };
        this.propagate();
      })
      .catch((error) => {
        this.user = {
          loading: false,
          value: null,
          error,
        };
        this.propagate();
      });
  };

  // FILTERS
  setTicketsFilter = (filter = TICKET_FILTERS.OPEN) => {
    this.filter = filter;
    this.searchTerm = null;
    this.ticketId = null;
    this.limit = 50;
    this.tickets = {
      all: [],
      open: [],
      notReplied: [],
      loading: true,
      error: null,
    };
    this.propagate();
    this.loadTickets();
  };

  setActiveTicket = (ticketId = null) => {
    if (ticketId != this.ticketId) {
      this.ticketId = ticketId;
      this.activities.loading = true;
      this.activities.data = [];
      this.activities.error = null;
      if (ticketId) {
        this.markSeen(ticketId);
        // aTitle = `Ticket #${ticketId} - Sistel Helpdesk`;
      }

      this.propagate();
      this.loadActivities();
    }
  };

  setSearchTerm = (term = null) => {
    this.searchTerm = term;
    this.ticketId = null;
    this.activities.loading = false;
    this.activities.data = [];
    this.activities.error = null;
    this.limit = 50;
    this.tickets = {
      all: [],
      open: [],
      notReplied: [],
      loading: true,
      error: null,
    };
    this.propagate();
    this.loadTickets();
  };

  setActiveGroup = (groupId = null) => {
    if (groupId != this.groupId) {
      this.searchTerm = null;
      this.groupId = groupId;
      this.ticketId = null;
      this.limit = 50;
      this.tickets.loading = true;
      this.propagate();
      this.loadTickets();
    }
  };

  clearActiveGroup = () => {
    this.setActiveGroup();
  };

  clearActiveTicket = () => {
    this.setActiveTicket();
  };

  // EXTERNAL LISTENER
  listenToChanges = (listener = () => {}) => {
    this.listeners = [...this.listeners, listener];
    this.propagate();
  };

  // INTERNAL LISTENING

  startListeningToEvents = () => {
    this.feathersListeners = {
      subscription: helperCreateSubscriptions(
        this.feathers.service("api/subscriptions"),
        this._onSubscriptionListener
      ),
      group: helperCreateSubscriptions(
        this.feathers.service("api/groups"),
        this._onGroupListener
      ),
      ticket: helperCreateSubscriptions(
        this.feathers.service("api/tickets"),
        this._onTicketListener
      ),
      activites: helperCreateSubscriptions(
        this.feathers.service("api/ticket-activities"),
        this._onTicketActivityListener
      ),
    };
  };

  stopListeningToEvents = () => {
    this.feathersListeners.subscription.remover();
    this.feathersListeners.group.remover();
    this.feathersListeners.ticket.remover();
    this.feathersListeners.activites.remover();
  };

  _onSubscriptionListener = (event) => (subscription) => {
    this.loadGroups();
    this.loadTickets();
  };

  _onTicketActivityListener = (event) => (activity) => {
    //console.log("document.title.update");
    let countNotif = document.title.match(/\((\d+)\)/g);
    let aTitle = `${
      !document.hasFocus()
        ? `(${
            countNotif && countNotif.length > 0
              ? Number(countNotif[0].replace("(", "").replace(")", "")) + 1
              : 1
          }) `
        : ""
    }`;
    let subTitle = document.title.replace(/(\(\d+\))/g, "");
    // //console.log("titleA", aTitle, subTitle);
    document.title = `${aTitle}${subTitle}`;
    this.loadActivities();
    this.markSeen();
    this.loadTickets();
    this.loadStats();
  };

  _onGroupListener = (event) => (group) => {
    this.loadGroups();
  };

  _onTicketListener = (event) => (ticket) => {
    //console.log("document.title.update");
    let countNotif = document.title.match(/\((\d+)\)/g);
    let aTitle = `${
      !document.hasFocus()
        ? `(${
            countNotif && countNotif.length > 0
              ? Number(countNotif[0].replace("(", "").replace(")", "")) + 1
              : 1
          }) `
        : ""
    }`;
    let subTitle = document.title.replace(/(\(\d+\))/g, "");
    // //console.log("titleA", aTitle, subTitle);
    document.title = `${aTitle}${subTitle}`;
    this.loadStats();
    this.loadTickets();
  };

  // NETWORK ACTIVITIES (TAG)
  moveActivitesToNewTicket = async (subject, activityIds, groupId) => {
    //console.log("moveActivitiesToNewTicket", subject, activityIds, groupId);
    this.tickets = {
      ...this.tickets,
      loading: true,
      error: null,
    };
    this.propagate();
    try {
      let result = await this.feathers
        .service("api/move-activities")
        .create({ subject, activityIds, groupId });
      this.ticketId = result.id;
      this.groupId = result.GroupId;
      this.propagate();
    } catch (error) {
      //console.log("ERROR MOVING TO NEW TICKET", error);
      this.tickets = {
        ...this.tickets,
        loading: false,
        error,
      };
      this.propagate();
    }
  };

  moveTicket = async (newGroupId) => {
    let ticket = this.tickets.all.find((a) => a.id == this.ticketId);
    if (!ticket || !this.ticketId) {
      return;
    }
    if (ticket.GroupId == newGroupId) {
      return;
    }
    this.tickets = {
      ...this.tickets,
      loading: true,
      error: null,
    };
    this.propagate();
    try {
      let result = await this.feathers
        .service("api/tickets")
        .patch(this.ticketId, { GroupId: newGroupId });
      let allTickets = [
        ...this.tickets.all.map((a) =>
          a.id == result.id ? { ...result, lastActivity: a.lastActivity } : a
        ),
      ];
      this.tickets = {
        loading: false,
        error: null,
        ...this.organizedTickets(allTickets),
      };
      this.propagate();
    } catch (error) {
      this.tickets = {
        ...this.tickets,
        loading: false,
        error,
      };
      this.propagate();
    }
  };

  toggleTagForTicket = async (tag = "flagged") => {
    let ticket = this.tickets.all.find((a) => a.id == this.ticketId);
    if (!ticket || !this.ticketId) {
      return;
    }
    this.tickets = {
      ...this.tickets,
      loading: true,
      error: null,
    };
    this.propagate();
    let tags = [...ticket.tags];
    if (ticket.tags.some((a) => a == tag)) {
      tags = [...tags.filter((a) => a != tag)];
    } else {
      tags = [...tags, tag];
    }
    try {
      let result = await this.feathers
        .service("api/tickets")
        .patch(this.ticketId, { tags });
      let allTickets = [
        ...this.tickets.all.map((a) =>
          a.id == result.id ? { ...result, lastActivity: a.lastActivity } : a
        ),
      ];
      this.tickets = {
        loading: false,
        error: null,
        ...this.organizedTickets(allTickets),
      };
      this.propagate();
    } catch (error) {
      this.tickets = {
        ...this.tickets,
        loading: false,
        error,
      };
      this.propagate();
    }
  };

  togglePublishedForTicket = async () => {
    let ticket = this.tickets.all.find((a) => a.id == this.ticketId);
    if (
      !ticket ||
      !this.ticketId ||
      !this.user.value.roles.some((a) => a == "wikipublish")
    ) {
      return;
    }
    this.tickets = {
      ...this.tickets,
      loading: true,
      error: null,
    };
    this.propagate();
    let global = !ticket.global;
    try {
      let result = await this.feathers
        .service("api/tickets")
        .patch(this.ticketId, { global });
      //console.log("resultPatchTogglePublished", result);
      let allTickets = [
        ...this.tickets.all.map((a) =>
          a.id == result.id ? { ...result, lastActivity: a.lastActivity } : a
        ),
      ];
      this.tickets = {
        loading: false,
        error: null,
        ...this.organizedTickets(allTickets),
      };
      this.propagate();
    } catch (error) {
      //console.log("ticketPublishedToggleError", error);
      this.tickets = {
        ...this.tickets,
        loading: false,
        error,
      };
      this.propagate();
    }
  };

  closeTicket = async () => {
    if (!this.ticketId) {
      return;
    }
    const oldId = this.ticketId;
    this.tickets = {
      ...this.tickets,
      loading: true,
      error: null,
    };
    this.propagate();
    try {
      let result = this.feathers
        .service("api/tickets")
        .patch(this.ticketId, { status: "closed" });
      //console.log("resultCloseTicket", result);
      this.ticketId = null;
      this.tickets = {
        ...this.tickets,
        loading: false,
        error: null,
        ...this.organizedTickets(this.tickets.all.filter((a) => a.id != oldId)),
      };
      this.activities = {
        ...this.activities,
        data: [],
      };
      this.propagate();
      // this.loadTickets();
    } catch (error) {
      console.error("errorCloseTicket", error);
    }
  };

  // NETWORK ACTIVITIES (CREATE)

  deleteActivity = async (activityId) => {
    this.activities = {
      ...this.activites,
      data: this.activities.data.filter((a) => a.id != activityId),
      loading: true,
      error: null,
    };
    this.propagate();
    try {
      let result = await this.feathers
        .service("api/ticket-activities")
        .remove(activityId);
      this.loadActivities();
    } catch (error) {}
  };

  toggleTicketStatus = async () => {
    let ticket = this.tickets.all.find((a) => a.id == this.ticketId);
    if (!ticket || !this.ticketId) {
      return;
    }
    this.tickets = {
      ...this.tickets,
      loading: true,
      error: null,
    };
    this.propagate();
    let status = ticket.status == "open" ? "closed" : "open";
    try {
      let result = await this.feathers
        .service("api/tickets")
        .patch(this.ticketId, { status });
      let allTickets = [
        ...this.tickets.all.map((a) =>
          a.id == result.id ? { ...result, lastActivity: a.lastActivity } : a
        ),
      ];
      this.tickets = {
        loading: false,
        error: null,
        ...this.organizedTickets(allTickets),
      };
      this.propagate();
    } catch (error) {
      this.tickets = {
        ...this.tickets,
        loading: false,
        error,
      };
      this.propagate();
    }
  };

  swapTickets = async (firstId, secondId) => {
    let firstTicket = this.tickets.all.find((a) => a.id == firstId);
    let secondTicket = this.tickets.all.find((b) => b.id == secondId);
    if (
      !firstTicket ||
      !secondTicket ||
      firstTicket.GroupId != secondTicket.GroupId
    ) {
      console.error("swapping messages from different Groups or not found");
      return false;
    }
    //console.log("swapping tickets");
    this.tickets = {
      open: [],
      all: [],
      notReplied: [],
      loading: true,
      error: null,
    };
    this.propagate();
    try {
      // //console.log(
      //   "swapFirstTicket",
      //   { ...firstTicket },
      //   { ...firstTicket, customSortIndex: secondTicket.sortindex }
      // );

      // //console.log(
      //   "swapSecondTicket",
      //   { ...secondTicket },
      //   { ...secondTicket, customSortIndex: firstTicket.sortindex }
      // );
      let [firstPatch, secondPatch] = await Promise.all([
        this.feathers.service("api/tickets").patch(firstTicket.id, {
          customSortIndex: secondTicket.sortindex,
        }),
        this.feathers.service("api/tickets").patch(secondTicket.id, {
          customSortIndex: firstTicket.sortindex,
        }),
      ]);
    } catch (error) {
      //console.log("swapError", error);
      if (error) {
        let [undoPatch1, undoPatch2] = await Promise.all([
          this.feathers.service("api/ticket-activities").patch(firstTicket.id, {
            customSortIndex: firstTicket.sortindex,
          }),
          this.feathers
            .service("api/ticket-activities")
            .patch(secondTicket.id, {
              customSortIndex: secondTicket.sortindex,
            }),
        ]);
      }
    }
    this.loadTickets();
  };

  swapMessages = async (firstId, secondId) => {
    let firstMessage = this.activities.data.find((a) => a.id == firstId);
    let secondMessage = this.activities.data.find((b) => b.id == secondId);
    if (
      !firstMessage ||
      !secondMessage ||
      firstMessage.TicketId != secondMessage.TicketId
    ) {
      console.error("swapping messages from different tickets or not found");
      return false;
    }
    this.activities = {
      data: [],
      loading: true,
      error: null,
    };
    this.propagate();
    try {
      let [firstPatch, secondPatch] = await Promise.all([
        this.feathers.service("api/ticket-activities").patch(firstMessage.id, {
          customSortIndex: secondMessage.customSortIndex,
        }),
        this.feathers.service("api/ticket-activities").patch(secondMessage.id, {
          customSortIndex: firstMessage.customSortIndex,
        }),
      ]);
    } catch (error) {
      //console.log("swapError", error);
      if (error) {
        let [undoPatch1, undoPatch2] = await Promise.all([
          this.feathers
            .service("api/ticket-activities")
            .patch(firstMessage.id, {
              customSortIndex: firstMessage.customSortIndex,
            }),
          this.feathers
            .service("api/ticket-activities")
            .patch(secondMessage.id, {
              customSortIndex: secondMessage.customSortIndex,
            }),
        ]);
      }
    }
    this.loadActivities();
  };

  createTicket = async (subject, groupId, body, attachments) => {
    this.createTicketMemory = {
      loading: true,
      error: null,
    };
    this.propagate();
    try {
      let newTicket = await this.feathers.service("api/tickets").create({
        id: undefined,
        UserId: this.user.value.id,
        GroupId: groupId,
        subject,
        priority: 4,
        tags: [],
        type: this.kind,
        activities: [
          {
            body,
            type: "message",
            TicketCreatorId: this.user.id,
            UserId: this.user.id,
            mediaType: "html",
            UserShortDescription: this.user.fullName,
          },
          ...attachments.map((anAttachment) => ({
            body: anAttachment,
            mediaType: /\.(gif|jpg|jpeg|tiff|png)$/i.test(anAttachment)
              ? "image"
              : "attachment",
            TicketCreatorId: this.user.id,
            UserId: this.user.id,
            type: "message",
            UserShortDescription: this.user.fullName,
          })),
        ],
      });
      this.createTicketMemory = {
        loading: false,
        error: null,
      };
      this.propagate();
      this.setTicketsFilter(TICKET_FILTERS.OPEN);
      this.loadTickets();
      this.setActiveTicket(newTicket.id);
    } catch (error) {
      this.createTicketMemory = {
        loading: false,
        error,
      };
      this.propagate();
    }
  };

  patchActivity = async (activityId, body) => {
    this.activities = {
      data: [],
      loading: false,
      error: null,
    };
    this.propagate();
    try {
      await this.feathers
        .service("api/ticket-activities")
        .patch(activityId, { body: body });
    } catch (error) {
      //console.log(error);
    }
    this.loadActivities();
  };

  patchSubject = async (subject) => {
    this.activities = {
      data: [],
      loading: true,
      error: null,
    };
    this.propagate();
    console.log("this.ticketId", this.ticketId);
    try {
      await this.feathers
        .service("api/tickets")
        .patch(this.ticketId, { subject });
    } catch (error) {
      console.log(error);
    }
    this.loadTickets();
    this.loadActivities();
  };

  createActivities = async (
    htmlBody = null,
    attachments = [],
    customSortStartPoint = null,
    priority = 4,
    asSystem = false
  ) => {
    const temporalNonce = Math.random();
    const currentTicketId = Number(this.ticketId);
    const activeTicket = this.tickets.all.find((a) => a.id == currentTicketId);
    let attachmentActivities = attachments.map((anAttachment) => ({
      body: anAttachment,
      mediaType: /\.(gif|jpg|jpeg|tiff|png)$/i.test(anAttachment)
        ? "image"
        : "attachment",
      TicketCreatorId: activeTicket.UserId,
      TicketId: currentTicketId,
      UserId: this.user.value.id,
      role:
        this.kind == "topic"
          ? "agent"
          : (
              this.groups.data.find((a) => a.id == activeTicket.GroupId) || {
                subscription: { permissions: [] },
              }
            ).subscription.permissions.reduce((p, c) => {
              let split = c.split("Ro");

              if (split.length == 2) {
                return split[0];
              }
              return p;
            }, null),
      priority,
      type: asSystem ? "publicEvent" : "message",
      UserShortDescription: asSystem ? "Sistema" : this.user.value.fullName,
    }));

    let htmlActivity = !htmlBody
      ? null
      : {
          body: htmlBody,
          mediaType: "html",
          TicketCreatorId: activeTicket.UserId,
          TicketId: currentTicketId,
          UserId: this.user.value.id,
          role:
            this.kind == "topic"
              ? "agent"
              : (
                  this.groups.data.find(
                    (a) => a.id == activeTicket.GroupId
                  ) || {
                    subscription: { permissions: [] },
                  }
                ).subscription.permissions.reduce((p, c) => {
                  let split = c.split("Ro");

                  if (split.length == 2) {
                    return split[0];
                  }
                  return p;
                }, null),
          priority,
          type: asSystem ? "publicEvent" : "message",
          UserShortDescription: asSystem ? "Sistema" : this.user.value.fullName,
        };

    let temporalActivites = !htmlActivity
      ? []
      : [
          {
            ...htmlActivity,
            id: temporalNonce,
            nonce: temporalNonce,
            status: "sending",
          },
        ];
    temporalActivites = [
      ...temporalActivites,
      ...attachmentActivities.map((a) => ({
        ...a,
        id: temporalNonce,
        status: "sending",
        nonce: temporalNonce,
      })),
    ];
    let lastCustomSortIndex = null;
    if (customSortStartPoint) {
      let activitiesToPatch = await this.activities.data.filter(
        (a) => a.customSortIndex && a.customSortIndex >= customSortStartPoint
      );
      temporalActivites = temporalActivites.map((a, idx) => ({
        customSortIndex: customSortStartPoint + idx,
      }));
      lastCustomSortIndex =
        temporalActivites.sort(
          (a, b) => a.customSortIndex > b.customSortIndex
        )[0].customSortIndex || null;
    }

    //this.activities.data = [...this.activities.data, ...temporalActivites.filter(a => !a.customSortIndex)];
    let tmp = [...temporalActivites];
    let tmpAll = [...this.activities.data, ...tmp];
    // temporalActivites
    //   .filter(a => !!a.customSortIndex)
    //   .forEach((a, idx) => {
    //     tmp.splice(a.customSortIndex + idx, 0, {
    //       ...a,
    //       customSortIndex: a.customSortIndex + idx
    //     });
    //     tmpAll.splice(a.customSortIndex + idx, 0, {
    //       ...a,
    //       customSortIndex: a.customSortIndex + idx
    //     });
    //   });

    // tmpAll.reduce((p, c, idx) => {
    //   if (!c.customSortIndex) {
    //     return p;
    //   }
    //   if (c.customSortIndex <= p) {
    //     tmpAll[idx].customSortIndex = c.customSortIndex + 1;
    //     tmpAll[idx].status = "patching";
    //     return c.customSortIndex + 1;
    //   }
    //   return c.customSortIndex;
    // }, -1);

    this.activities.data = tmpAll;
    temporalActivites = tmpAll.filter((a) => !!a.id && !!a.nonce);
    let patchableActivities = tmpAll.filter(
      (a) => !!a.id && !a.nonce && a.status == "patching"
    );
    if (patchableActivities.length > 0) {
      let patchedActivities = await Promise.all(
        patchableActivities.map((a) =>
          this.feathers
            .service("api/ticket-activities")
            .patch(a.id, { ...a, status: null })
        )
      );
    }

    this.propagate();
    try {
      let newActivities = await this.feathers
        .service("api/ticket-activities")
        .create(
          !!htmlActivity
            ? [htmlActivity, ...attachmentActivities]
            : [...attachmentActivities]
        );

      if (this.ticketId == currentTicketId) {
        this.activities.data = [
          ...this.activities.data.filter((a) => a.nonce != temporalNonce),
          ...newActivities,
        ];
        this.propagate();
      }
    } catch (error) {
      //console.log("failederror", error);
      if (this.ticketId == currentTicketId) {
        this.activities.data = this.activities.data.map((a) =>
          a.nonce == temporalNonce ? { ...a, status: "failed" } : a
        );
        this.propagate();
      }
    }
  };

  // NETWORK ACTIVITIES (LOAD)
  markSeen = async (ticketId) => {
    this.feathers.service("api/seen-ticket").get(ticketId);
  };

  loadStats = async () => {
    this.stats = {
      ...this.stats,
      loading: true,
      error: null,
    };

    try {
      let stats = await this.feathers
        .service("api/user-stats")
        .find({ query: { UserId: this.user.value.id, type: this.kind } });
      this.stats = {
        loading: false,
        data: stats,
        error: null,
      };
      this.groups.data = this.groups.data.map((aGroup) => ({
        ...aGroup,
        stats: stats.find((a) => a.GroupId == aGroup.id) || null,
      }));
      this.propagate();
    } catch (error) {
      //console.log("statserror", error);
      this.stats = {
        loading: false,
        data: [],
        error,
      };
      this.propagate();
    }
  };

  loadActivities = async () => {
    try {
      let sort = {
        createdAt: 1,
        id: 1,
      };
      if (this.kind == "topic") {
        sort = {
          customSortIndex: 1,
        };
      }
      //console.log("sort", sort);
      let activities = await this.feathers
        .service("api/ticket-activities")
        .find({
          query: {
            TicketId: this.ticketId,
            $sort: {
              ...sort,
            },
          },
        });

      if (
        activities.total > 0 &&
        this.ticketId == activities.data[0].TicketId
      ) {
        this.activities = {
          data: activities.data,
          loading: false,
          error: null,
        };
        this.propagate();
      }
    } catch (error) {
      this.activities = {
        data: [],
        loading: false,
        error,
      };
      this.propagate();
    }
  };

  loadGroups = async (loading = true) => {
    if (loading) {
      this.groups = {
        ...this.groups,
        loading,
        error: null,
      };
      this.propagate();
    }

    try {
      let subscriptions = await this.feathers
        .service("api/subscriptions")
        .find({
          query: {
            UserId: this.user.value.id,
            $limit: 999999999,
            $or: [
              {
                exclusiveType: null,
              },
              {
                exclusiveType: this.kind,
              },
            ],
          },
        });
      let collectionSubIds = subscriptions.data
        .map((a) => a.SubscriptionCollectionId)
        .filter((a) => !!a);
      console.log("LOG: loadGroups -> collectionSubIds", collectionSubIds);

      let gIds = subscriptions.data.map((a) => a.GroupId).filter((a) => !!a);
      collectionSubIds = [...collectionSubIds];
      console.log("LOG: loadGroups -> gIds", gIds);
      let subscriptionCollections = await this.feathers
        .service("api/subscription-collections")
        .find({
          query: {
            $or: [
              // {UserId: params.user.id},
              { GroupId: { $in: gIds } },
              { id: { $in: collectionSubIds } },
              { UserId: this.user.value.id },
            ],
            $limit: -1,
          },
        });
      console.log(
        "LOG: Service -> find -> subscriptionCollections",
        subscriptionCollections
      );

      let longConfig = subscriptionCollections.reduce(
        (p, c) => [...p, ...c.configuration],
        []
      );
      console.log("LOG: Service -> find -> longConfig", longConfig);
      let currGroups = longConfig
        .filter((a) => a.type == (this.kind == "topic" ? "topic" : "regular"))
        .map((a) => ({ id: a.GroupId, recursive: a.recursive }));
      console.log("LOG: Service -> find -> currGroups", currGroups);
      gIds = [...gIds, ...currGroups.map((a) => a.id)];
      let recGroups = currGroups.filter((a) => !!a.recursive);
      currGroups = recGroups;
      while (currGroups.length > 0) {
        currGroups = await this.feathers.service("api/groups").find({
          query: {
            GroupId: { $in: currGroups.map((a) => a.id) },
          },
        });
        currGroups = currGroups.data;
        gIds = [...gIds, ...currGroups.map((a) => a.id)];
      }
      console.log("LOG: Service -> find -> groupIds", gIds);
      let pIds = subscriptions.data
        .filter((a) => a.childrenInherit)
        .map((a) => a.GroupId);
      if (this.ticketId) {
        let activeTicket =
          this.tickets.all.find((a) => a.id == this.ticketId) || null;
        if (activeTicket) {
          pIds = [...pIds, activeTicket.GroupId];
        }
      }
      let groupQuery = {
        query: {
          $limit: 999999999,
          $sort: {
            [this.kind == "topic" ? "id" : "priority"]: 1,
            name: 1,
          },
          // $or: [
          //   {
          //     id: {
          //       $in: gIds
          //     }
          //   },
          //   {
          //     GroupId: {
          //       $in: pIds
          //     }
          //   }
          // ]
        },
      };
      //console.log("groupQuery", groupQuery);
      let groups = await this.feathers.service("api/groups").find(groupQuery);
      console.log("groups", groups);
      const getChildrenOfGroup = (groupId, data) => {
        return data
          .filter((a) => a.GroupId === groupId)
          .reduce(
            (p, c) => [...p, c.id, ...getChildrenOfGroup(c.id, data)],
            []
          );
      };

      const inheritanceIds = pIds.reduce(
        (p, c) => [...p, ...getChildrenOfGroup(c, groups.data)],
        []
      );
      console.log("inh", inheritanceIds);
      groups.data = groups.data.filter(
        (a) =>
          gIds.some((b) => b === a.id) || inheritanceIds.some((b) => b === a.id)
      );
      //console.log("groups", groups);
      //console.log("subscriptions", subscriptions);

      groups = groups.data.map((aGroup) => ({
        ...aGroup,
        subscription: subscriptions.data.find(
          (a) => a.GroupId == aGroup.id && a.UserId == this.user.value.id
        ),
      }));
      //console.log("groups", groups);
      this.groups = {
        data: groups,
        loading: false,
        error: null,
      };
      console.log("GROUPS", this.groups);
      await this.loadStats();
      //console.log("this", this);
      this.propagate();
    } catch (error) {
      console.log("loadGroupsError", error);
      this.groups = {
        ...this.groups,
        loading: false,
        error,
      };

      this.propagate();
    }
  };

  loadLastActivites = async (tickets = this.tickets.all) => {
    if (tickets.length <= 0) {
      this.propagate();
      return;
    }
    let lastActivities = await this.feathers.service("api/lastactivity").find({
      query: {
        ids: tickets.map((a) => a.id),
        $isWiki: this.kind == "topic",
      },
    });
    let mappedTickets = tickets.map((aTicket) => ({
      ...aTicket,
      lastActivity:
        lastActivities.find((l) => l.TicketId == aTicket.id) || null,
    }));
    this.tickets = {
      loading: false,
      error: null,
      ...this.organizedTickets(mappedTickets),
    };
    this.propagate();
  };

  organizedTickets = (tickets) => ({
    all: tickets,
    open: tickets.filter((a) => a.status == "open"),
    notReplied: tickets.filter(
      (a) =>
        a.status == "open" &&
        ((a.lastActivity && a.lastActivity.UserId != this.user.value.id) ||
          (a.tags && a.tags.some((b) => b == "flagged")) ||
          a.id == this.ticketId)
    ),
  });

  loadTickets = async (loading = true) => {
    try {
      let gId = this.groupId;
      let _ids =
        gId && (!this.searchTerm || this.kind != "topic") ? [gId] : null;
      //console.log("LOG: loadTickets -> _ids", _ids);
      let lastActivities = await this.feathers
        .service("api/lastactivity")
        .find({
          query: {
            ids: _ids,
            $isWiki: this.kind == "topic",
            status:
              this.filter == TICKET_FILTERS.ALL ||
              this.filter == TICKET_FILTERS.SEARCH
                ? undefined
                : "open",
            $limit: this.limit || 50,
            $notReplied: this.filter == TICKET_FILTERS.NOT_REPLIED,
            $activeTicketId: this.ticketId || undefined,
            $query:
              this.searchTerm &&
              (this.kind == "topic" || this.filter == TICKET_FILTERS.SEARCH)
                ? this.searchTerm
                : undefined,
          },
        });

      let tickets = lastActivities;

      if (this.kind == "topic") {
        tickets.data = tickets.data.sort((a, b) => {
          if (!a.sortindex) {
            return 1;
          } else if (!b.sortindex) {
            return -1;
          }
          return a.sortindex > b.sortindex ? 1 : -1;
        });
      }

      if (tickets.data.length < tickets.total) {
        this.noMoreContent = false;
      } else {
        this.noMoreContent = true;
      }
      this.tickets = {
        loading: false,
        error: null,
        ...this.organizedTickets(tickets.data),
      };
      this.propagate();
      if (this.kind == "topic" && this.tickets.open.length > 0) {
        // //console.log(
        //   "ticketId",
        //   this.ticketId,
        //   "b",
        //   !this.tickets.open.some(a => a.id == this.ticketId)
        // );
        if (
          !this.ticketId ||
          !this.tickets.open.some((a) => a.id == this.ticketId)
        ) {
          //console.log("c");
          this.setActiveTicket(this.tickets.open[0].id);
        }
      } else if (this.kind == "topic" && this.tickets.open.length == 0) {
        try {
          let childGroups = this.groups.data.filter(
            (a) => a.GroupId == this.groupId
          );
          //console.log("childGroups", childGroups);
          if (childGroups.length > 0) {
            this.setActiveGroup(
              childGroups.sort((a, b) => a.metadata.sort - b.metadata.sort)[0]
                .id
            );
          }
          //console.log("setchild");
        } catch (error) {
          //console.log("errorsettingchild", error);
        }
      }
    } catch (error) {
      this.tickets = {
        ...this.tickets,
        loading: false,
        error,
        // open: [],
        // all: [],
        // notReplied: []
      };
      this.propagate();
    }
  };

  // CORE

  propagate = () => {
    let newState = { ...INITIAL_STATE };

    newState.groups = { ...this.groups };
    newState.groups.all = [...newState.groups.data];
    newState.tickets.loading = this.tickets.loading;
    const sortFnGen = (kind = this.kind) => {
      let field = kind == "topic" ? "id" : "priority";
      return (a, b) => {
        if (a[field] == b[field]) {
          return a["name"] > b["name"] ? 1 : -1;
        }
        if (a[field] > b[field]) {
          return 1;
        } else {
          return -1;
        }
      };
    };

    switch (this.filter) {
      case TICKET_FILTERS.OPEN:
        newState.tickets.data = this.tickets.open;
        let groupsWithTickets = newState.groups.data.filter(
          (a) =>
            this.stats.data.find(
              (b) => b.GroupId == a.id && b.openticketcount > 0
            ) != undefined
        );
        let parents = [
          ...new Set(
            groupsWithTickets.map((a) => a.GroupId).filter((a) => !!a)
          ),
        ];
        //console.log("PARENTSIDS", parents);
        parents = parents
          .map((a) => newState.groups.all.find((b) => b.id == a))
          .filter((a) => !!a && (!a.stats || a.stats.openticketcount == 0));
        //console.log("PARENTS", parents);
        if (this.kind == "topic") {
          newState.groups.data = newState.groups.data.filter(
            (a) => a.type == "topic"
          );
        } else {
          newState.groups.data = [...groupsWithTickets, ...parents];
          newState.groups.data = newState.groups.data.sort(
            sortFnGen(this.kind)
          );
        }

        break;
      case TICKET_FILTERS.NOT_REPLIED:
        newState.tickets.data = this.tickets.notReplied;
        let groupsWithTicketsNotReplied = newState.groups.data.filter(
          (a) =>
            this.stats.data.find(
              (b) => b.GroupId == a.id && b.importantticketcount > 0
            ) != undefined
        );
        let parentsNotReplied = [
          ...new Set(
            groupsWithTicketsNotReplied.map((a) => a.GroupId).filter((a) => !!a)
          ),
        ];
        //console.log("PARENTSIDS", parentsNotReplied);
        parentsNotReplied = parentsNotReplied
          .map((a) => newState.groups.all.find((b) => b.id == a))
          .filter(
            (a) => !!a && (!a.stats || a.stats.importantticketcount == 0)
          );
        //console.log("PARENTS", parentsNotReplied);
        newState.groups.data = [
          ...groupsWithTicketsNotReplied,
          ...parentsNotReplied,
        ];

        newState.groups.data = newState.groups.data.sort(sortFnGen(this.kind));
        break;
      default:
        newState.tickets.data = this.tickets.all;
    }

    //   [this.kind == "topic" ? "id" : "priority"]: 1,
    //   name: 1
    // },
    if (this.kind == "topic") {
    } else if (!this.user.value.roles.some((a) => a == "sysadmin")) {
      newState.tickets.data = newState.tickets.data.sort((a, b) =>
        a.global && !b.global
          ? -1
          : b.global && !a.global
          ? 1
          : a.lastActivity.createdAt > b.lastActivity.createdAt
          ? -1
          : 1
      );
    } else {
      newState.tickets.data = newState.tickets.data.sort((a, b) =>
        a.lastActivity.createdAt > b.lastActivity.createdAt ? -1 : 1
      );
    }

    if (
      !!this.groupId &&
      (this.kind != "topic" || !this.searchTerm || this.searchTerm.length <= 0)
    ) {
      newState.tickets.data = newState.tickets.data.filter(
        (a) => a.GroupId == this.groupId
      );
    }

    if (!this.ticketId) {
      this.activities.data = [];
      this.activities.loading = false;
    }

    newState.activities = {
      loading: this.activities.loading,
      data: this.activities.data,
      error: this.activities.error,
    };

    newState.user = {
      ...this.user,
    };

    if (this.groupId) {
      newState.active.group =
        this.groups.data.find((a) => a.id == this.groupId) || null;
    } else {
      newState.active.group = null;
    }

    if (this.ticketId) {
      newState.active.ticket =
        this.tickets.all.find((a) => a.id == this.ticketId) || null;
    } else {
      newState.active.ticket = null;
    }

    newState.createTicket = {
      ...this.createTicketMemory,
    };

    newState.active.filter = this.filter;
    let countNotif = document.title.match(/\((\d+)\)/g);
    //console.log("countNotif", countNotif);
    let aTitle =
      !document.hasFocus() && countNotif && countNotif.length > 0
        ? `${countNotif[0]} `
        : "";
    if (newState.active.ticket) {
      aTitle += `Ticket #${newState.active.ticket.id} - Sistel Helpdesk`;
    } else if (newState.active.group) {
      aTitle += `Grupo ${newState.active.group.name} - Sistel Helpdesk`;
    } else {
      let base = ``;
      switch (newState.active.filter) {
        case TICKET_FILTERS.ALL:
          base += `Tickets`;
          break;
        case TICKET_FILTERS.NOT_REPLIED:
          base += `Tickets Sin Responder`;
          break;
        case TICKET_FILTERS.OPEN:
          base += `Tickets Abiertos`;
          break;
        case TICKET_FILTERS.SEARCH:
          base += `Buscando: ${newState.active.searchTerm}`;
      }
      aTitle += `${base} - Sistel Helpdesk`;
    }
    document.title = aTitle;
    // ACTUAL PROPAGATION
    //console.log("newState", newState);
    this.notifyListeners(this.listeners, newState);
  };

  notifyListeners = (listeners, value) => {
    if (value.tickets.data.length <= 0) {
      //console.log("newValue", value);
    }
    listeners.forEach((element) => {
      if (!element) {
        return;
      }
      try {
        element(value);
      } catch (error) {}
    });
  };
}
