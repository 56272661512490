import React, { Component } from "react";
import {
  Button, Breadcrumb, Container,
  Divider, Header, Item,
  Icon, Grid, List,
  Segment, Loader, Message, Label
} from "semantic-ui-react";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import Radium from "radium";
import moment from "moment";
import { truncate } from 'lodash';
import { Link } from "react-router-dom";
import * as Routes from "../../../routes";
const styles = {};

class GroupTicketsContainer extends Component {
  constructor(props) {
    super(props);
  }

  static mapStateToProps(state) {
    return {
      routing: state.routing,
      authentication: state.authentication
    };
  }

  render() {
    const { t, tickets, revertSort } = this.props;

    if (!!tickets.loading) {
      return (
        <Container style={styles.container}>
          <Loader active />
        </Container>
      );
    }
    if (!!tickets.error) {
      return (
        <Container style={styles.container}>
          <Segment>
            <Message error header={tickets.error.message} />
          </Segment>
        </Container>
      );
    }

    if (!tickets.data || tickets.data.length <= 0) {
      return (
        <Container style={styles.container}>
          <Message warning header={t("group.messages.noTickets")} />
        </Container>
      );
    }

    const sortedTickets = tickets.data;

    return (
      <List divided relaxed size="large">
        {sortedTickets.map((ticket, index) => (
          <List.Item key={index}>
            <List.Content
              style={{
                fontSize: 15,
                color: "gray",
                fontWeight: "bold",
                width: "15em",
                textAlign: "left"
              }}
              verticalAlign="top"
              floated="right"
            >
              <List.Content
                style={{
                  fontSize: 13,
                  fontWeight: "normal",
                  color: "lightgray",
                  textAlign: "left",
                  paddingRight: "5px",
                  width: "15em"
                }}
              >
                {!ticket.lastActivity
                  ? null
                  : moment(ticket.lastActivity.createdAt).format(
                    "DD/MM/YYYY HH:mm"
                  )}
              </List.Content>
              {!ticket.lastActivity ? null : `${ticket.lastActivity.fullName}:`}
              <List.Content
                style={{
                  fontSize: 15,
                  fontWeight: "normal",
                  color: "gray",
                  textAlign: "left",
                  paddingRight: "5px",
                  width: "15em"
                }}
                verticalAlign="middle"
              >
                {!ticket.lastActivity
                  ? null
                  : `${
                    ticket.lastActivity.mediaType == "image" ||
                      ticket.lastActivity.type == "image"
                      ? "📷Photo"
                      : ticket.lastActivity.body
                        .replace(/<\/?[^>]+(>|$)/g, "")
                        .slice(0, 70)
                  }...`}
              </List.Content>
            </List.Content>
            <List.Content style={{ marginBottom: ticket.tags ? 0 : 6 }}>
              <List.Header
                as={Link}
                to={Routes.TICKET.replace(":GroupId", ticket.GroupId).replace(
                  ":id",
                  ticket.id
                )}
              >
                {truncate(ticket.subject, { length: 80 })}
              </List.Header>
              <List.Description as="a">
                {moment(ticket.updatedAt).fromNow()}
              </List.Description>
            </List.Content>
            <List.Icon
              name={ticket.status === "open" ? "circle" : "circle outline"}
              size="small"
              color={
                ticket.status === "closed"
                  ? "grey"
                  : ticket.priority === 1
                    ? "red"
                    : ticket.priority === 2
                      ? "orange"
                      : ticket.priority === 3
                        ? "yellow"
                        : "blue"
              }
              verticalAlign="middle" />
            <List.Icon
              name={
                ticket.tags
                  ? ticket.tags.includes("snoozed")
                    ? "clock"
                    : "clock outline"
                  : "clock outline"
              }
              size="small"
              style={{ paddingLeft: 10, paddingRight: 10 }}
              verticalAlign="middle"
              onClick={() => this.props.ticketAddTag(ticket, "snoozed")} />
            <List.Icon
              name={
                ticket.tags
                  ? ticket.tags.includes("important")
                    ? "star"
                    : "star outline"
                  : "star outline"
              }
              color="orange"
              size="small"
              verticalAlign="middle"
              onClick={() => this.props.ticketAddTag(ticket, "important")} />
            <List.Content
              verticalAlign="bottom"
              style={{ color: "grey", fontWeight: "bold" }}>
              {!ticket.tags
                ? null
                : ticket.tags.map(t => {
                  return (
                    <Label tag size="tiny" key={t}>
                      {t.toUpperCase()}
                    </Label>
                  );
                })}
            </List.Content>
          </List.Item>
        ))}
      </List>
    );
  }
}

const StyledView = Radium(GroupTicketsContainer);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  GroupTicketsContainer.mapStateToProps,
  GroupTicketsContainer.mapDispatchToProps
)(i18nEnhancement);
