import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { visibilityActions } from "../../store/actions/visibility";
import { Creators } from "../../store/actions/authentication";
import ConnectServices from "../../providers/ConnectServices";
import SemaphoreView from "../views/common/SemaphoreView";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import update from "immutability-helper";
import { findIndex } from "lodash";
import moment from "moment";
import { Divider, Dropdown, Feed, Menu, Popup } from "semantic-ui-react";
import * as Routes from "../../routes";
import colorPalette from "../../styles/colorPalette";

const INITIAL_STATE = {
  notifications: {
    loading: false,
    data: [],
    error: null
  }
};

class NavigationBar extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
    this.FeathersService = props.services.feathers;
    this.NotificationService = this.FeathersService.service("/notifications");
  }

  componentWillMount() {
    this.NotificationService.on("created", (notification, context) => {
      if (!notification.notified && notification.channel == "internal") {
        this.setState(
          update(this.state, {
            notifications: {
              data: {
                $unshift: [notification]
              }
            }
          })
        );
      }
    });

    this.NotificationService.on("updated", (notification, context) => {
      let {
        notifications: { data }
      } = this.state;

      let index = findIndex(data, n => n.id === notification.id);

      if (index !== -1) {
        this.setState(
          update(this.state, {
            notifications: {
              data: {
                [index]: {
                  $set: notification
                }
              }
            }
          })
        );
      }
    });

    this.NotificationService.on("patched", (notification, context) => {
      let {
        notifications: { data }
      } = this.state;

      let index = findIndex(data, n => n.id === notification.id);

      if (index !== -1) {
        this.setState(
          update(this.state, {
            notifications: {
              data: {
                [index]: {
                  $set: notification
                }
              }
            }
          })
        );
      }
    });
  }

  findNotifications = () => {
    this.setState(
      update(this.state, {
        notifications: {
          $merge: {
            loading: true,
            data: [],
            error: null
          }
        }
      })
    );

    this.NotificationService.find({
      query: {
        channel: "internal",
        UserId: this.props.authentication.user.id,
        $sort: {
          createdAt: -1
        }
      }
    })
      .then(response => {
        this.setState(
          update(this.state, {
            notifications: {
              $merge: {
                loading: false,
                data: response.data,
                error: null
              }
            }
          })
        );
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            notifications: {
              $merge: {
                loading: false,
                error
              }
            }
          })
        );
      });
  };

  updateNotificationStatus = () => {
    let {
      notifications: { data }
    } = this.state;

    this.NotificationService.patch(
      null,
      { notified: true },
      {
        query: {
          id: {
            $in: data.map(n => n.id)
          }
        }
      }
    )
      .then(response => {
        //console.log("@NotificationView", response);
      })
      .catch(error => {
        console.error("@NotificationView", error);
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.authentication.user) {
      if (!prevProps.authentication.user) {
        this.findNotifications();
      }
    }
  }

  componentDidMount() {}

  static mapStateToProps(state) {
    return {
      routing: state.routing,
      authentication: state.authentication.toJS()
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path)),
      logOut: () => dispatch(Creators.logout()),
      toggleNavigationSidebar: () =>
        dispatch(visibilityActions.toggleNavigationSidebar())
    };
  }

  render() {
    let {
      authentication: { user, loading },
      t,
      navigate,
      logOut,
      toggleNavigationSidebar
    } = this.props;
    let { notifications } = this.state;

    let unnotifiedNotifications = notifications.data.filter(n => !n.notified)
      .length;

    const signOut = () => {
      this.FeathersService.logout()
        .then(r => {
          logOut();
          navigate(Routes.LOGIN);
        })
        .catch(e => {
          // console.error("issue logging out", e);
        });
    };

    if (this.props.mobile) {
      return (
        <Menu style={{ marginBottom: 0 }}>
          <Menu.Item as={Link} to={"/dashboard"}>
            {/*<img src={logoBase64} />*/}
            Tickets
          </Menu.Item>
          <Menu.Item as={Link} to={"/wiki/open/405/50183"} content="Manuales" />
          <Menu.Menu position="right">
            <Menu.Item
              icon="alarm"
              content={<strong>{unnotifiedNotifications}</strong>}
              as={Link}
              to={Routes.NOTIFICATIONS}
            />
            <Menu.Item
              icon="list layout"
              active={false}
              onClick={toggleNavigationSidebar}
            />
          </Menu.Menu>
        </Menu>
      );
    } else {
      return (
        <Menu
          inverted
          stackable
          size="huge"
          style={{
            borderRadius: 0,
            margin: 0,
            backgroundColor: "#566673",
            color: "red"
            // borderBottom: `1px ${colorPalette.highlight} solid`
          }}>
          <Menu.Item fitted as={Link} to={"/dashboard"} style={{ padding: 0 }}>
            <img
              src={require("../../assets/img/sistel-logo-wide.png")}
              style={{ margin: 0, width: 120 }}
            />
          </Menu.Item>
          <Menu.Item as={Link} to={"/dashboard"}>
            {/*<img src={Assets.logo.simple} />*/}
            Tickets
          </Menu.Item>
          <Menu.Item
            as="a"
            target="_blank"
            href={"/wiki/open/405/50183"}
            content="Manuales"
          />
          {!!loading ? (
            ""
          ) : !user ? (
            <Menu.Menu
              position="right"
              style={{ marginLeft: "0px !important" }}>
              <div style={{ padding: "5px" }}>
                <img
                  src={require("../../assets/img/logo_destek.png")}
                  height={38}
                />
              </div>
              <Menu.Item
                name="signin"
                as={Link}
                to={Routes.LOGIN}
                content={t("navbar.signin")}
                active={false}
                icon="sign in"
              />
            </Menu.Menu>
          ) : (
            <Menu.Menu position="right">
              <div style={{ padding: "5px" }}>
                <img
                  src={require("../../assets/img/logo_destek.png")}
                  height={38}
                />
              </div>

              {(!!user && user.id === 2) || user.id === 1 ? (
                <Menu.Item>
                  <SemaphoreView />
                </Menu.Item>
              ) : null}
              <NotificationsPopup
                notifications={notifications.data}
                updateNotificationStatus={this.updateNotificationStatus}
              />
              <Dropdown item text={"Suscripciones"}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="feed"
                    as={Link}
                    to={Routes.GROUPS}
                    text={"Grupos"}
                  />
                  <Dropdown.Item
                    icon="list"
                    as={Link}
                    to={Routes.WATCHLISTS}
                    text={"Watch Lists"}
                  />
                </Dropdown.Menu>
              </Dropdown>

              <Menu.Item as={Link} to={Routes.ADMIN} content="Admin" />
              <Dropdown item text={(!!user && user.username) || "Loading..."}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="user"
                    as={Link}
                    to={Routes.PROFILE}
                    text={t("navbar.profile")}
                  />
                  <Dropdown.Item
                    icon="sign out"
                    text={t("navbar.signout")}
                    onClick={signOut}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          )}{" "}
        </Menu>
      );
    }
  }
}

NavigationBar.propTypes = {
  mobile: PropTypes.bool
};

NavigationBar.defaultProps = {
  mobile: false
};

const i18nEnhancement = translate("translations")(NavigationBar);
const withServices = ConnectServices(i18nEnhancement);

const mapNotificationIcon = notification => {
  const selector = {
    tickets: () => "mail"
  };
  const func = selector[notification.resourceType];

  if (func) {
    return func();
  } else {
    return "remove";
  }
};

const NotificationsPopup = ({ notifications, updateNotificationStatus }) => (
  <Popup
    flowing
    hoverable
    on="click"
    onOpen={updateNotificationStatus}
    trigger={
      <Menu.Item
        icon="alarm"
        content={
          <strong>{notifications.filter(n => !n.notified).length}</strong>
        }
      />
    }>
    <Feed size="small">
      {notifications.map((notification, index) => {
        let now = moment();
        let notificationTime = moment(notification.createdAt);
        let diff = now.diff(notificationTime, "minutes");
        let timeElapsedSinceNotif = "";

        if (diff < 60) {
          // less than an hour
          timeElapsedSinceNotif = `Hace ${diff} minutos`;
        } else if (diff >= 60 && diff < 60 * 24) {
          // less than a day
          timeElapsedSinceNotif = `Hace ${Math.floor(diff / 60)} horas`;
        } else {
          // more than a day
          timeElapsedSinceNotif = notificationTime.format("DD/MM HH:mm");
        }

        return (
          <Feed.Event key={index}>
            <Feed.Label icon={mapNotificationIcon(notification)} />
            <Feed.Content
              style={
                notification.notified ? { backgroundColor: "lightgrey" } : {}
              }>
              <Feed.Summary>
                <Link to={`/tickets/${notification.resourceId}`}>
                  Ticket#
                  {notification.resourceId}
                </Link>{" "}
                {notification.title}
                <Feed.Date>{timeElapsedSinceNotif}</Feed.Date>
              </Feed.Summary>
              <Feed.Meta>{notification.content.body}</Feed.Meta>
            </Feed.Content>
          </Feed.Event>
        );
      })}
    </Feed>
    <Divider />
    <Link to="/notifications">Ver Todas</Link>
  </Popup>
);

export default connect(
  NavigationBar.mapStateToProps,
  NavigationBar.mapDispatchToProps
)(withServices);
