import React, { Component } from "react";
import { Breadcrumb, Container, Divider } from "semantic-ui-react";
import { translate } from "react-i18next";
import Radium from "radium";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import * as Routes from "../../../routes";

class AdminView extends Component {

  static mapStateToProps (state) {
    return {
      authentication: state.authentication
    };
  }

  static mapDispatchToProps (dispatch) {
    return {
      navigate: path => dispatch(push(path))
    };
  }

  render () {
    return (
      <Container style={{ padding: 12 }}>
        <Breadcrumb.Section as={Link} to={Routes.DASHBOARD}>
          Principal
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb>
          <Breadcrumb.Section active>Administracion</Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        ADMINISTRACION <br />
        <Link to="/admin/resources">Administrar Recursos</Link>
      </Container>
    );
  }
}

const StyledView = Radium(AdminView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  AdminView.mapStateToProps,
  AdminView.mapDispatchToProps
)(i18nEnhancement);
