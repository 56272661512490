import React, { Component } from "react";
import {
  Form,
  Button,
  Breadcrumb,
  Input,
  Container,
  Divider,
  Header,
  Icon,
  Grid,
  List,
  Transition,
  Image,
  Segment,
  Dimmer,
  Loader,
  Message,
  Label,
  Popup
} from "semantic-ui-react";
import { translate } from "react-i18next";
import Radium from "radium";
import colorPalette from "../../../styles/colorPalette";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import moment from "moment";
import ReactQuill, { Quill } from "react-quill";
import * as Routes from "../../../routes";
import ImageDrop from "./ImageDrop";
import "react-quill/dist/quill.snow.css";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const styles = {};

Quill.register("modules/imageDrop", ImageDrop);

class TicketCreateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      subject: "",
      images: [],
      loadingImage: false,
      dimmer: {
        subject: false,
        text: false,
        emergency: false
      }
    };
    this.ticketActions = this.props.storeActions.tickets;
    this.feathers = this.FeathersService = this.props.services.feathers;
  }

  removeImage = imageItem => {
    this.setState({
      images: this.state.images.filter(i => i.uri != imageItem.uri)
    });
  };

  _handleShow = name => {
    this.setState({
      dimmer: {
        ...this.state.dimmer,
        [name]: false
      }
    });
  };

  _handleHide = name => {
    this.setState({
      dimmer: {
        ...this.state.dimmer,
        [name]: true
      }
    });
  };

  componentDidMount() {
    moment.locale(window.navigator.language);
    let editor = document.getElementById("editor");
  }

  static mapStateToProps(state) {
    return {
      routing: state.routing,
      authentication: state.authentication,
      tickets: state.tickets.toJS()
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path)),
      dispatch
    };
  }

  writePermissionsCalculate = GroupId => {
    const { authentication } = this.props;
    const user = authentication.get("user").toJS();

    let subscription = user.subscriptions.find(s => s.GroupId == GroupId);

    if (!subscription) {
      return { canWrite: false, message: "noSubscription", role: null };
    }

    const { permissions } = subscription;
    const role =
      permissions.indexOf("clientRole") != -1
        ? "clientRole"
        : permissions.indexOf("agentRole") != -1
          ? "agentRole"
          : null;

    if (!role) {
      return { canWrite: false, role: null, message: "noRole" };
    }

    if (permissions.indexOf("create") != -1) {
      return { canWrite: true, message: "", role };
    }

    return { canWrite: false, message: "noCreatePermission", role };
  };

  createMessage = (ticket, body, images = []) => {
    const writePermissions = this.writePermissionsCalculate(
      this.props.match.params.GroupId
    );
    const { authentication } = this.props;

    if (!writePermissions.canWrite) {
      return Promise.reject(false);
    }

    if (!writePermissions.role) {
      return Promise.reject(false);
    }

    let type =
      writePermissions.role == "clientRole"
        ? "message"
        : writePermissions.role == "agentRole"
          ? "messageReply"
          : null;

    if (!type) {
      return Promise.reject(false);
    }

    const user = authentication.get("user").toJS();
    const additionalActivities = images.map(uri => {
      return {
        body: uri,
        type,
        mediaType: "image",
        TicketCreatorId: user.id,
        UserShortDescription: user.fullName
      };
    });

    return this.createTicket(
      ticket,
      {
        body,
        type,
        TicketCreatorId: user.id,
        mediaType: "html",
        UserShortDescription: user.fullName
      },
      additionalActivities
    );
  };

  createTicket = (ticket, initialActivity, additionalActivities = []) => {
    const { subject, emergency } = ticket;
    let otherTicket = { ...ticket };
    delete otherTicket.subject;
    delete otherTicket.emergency;
    const { dispatch, authentication, navigate } = this.props;
    //console.log(this.props);
    const user = authentication.get("user").toJS();
    const { GroupId } = this.props.match.params;
    const completeTicket = {
      activities: !!initialActivity
        ? [initialActivity, ...additionalActivities]
        : !!additionalActivities && additionalActivities.length > 0
          ? [...additionalActivities]
          : undefined,
      id: undefined,
      UserId: user.id,
      GroupId: GroupId,
      subject,
      priority: !emergency ? 4 : 1,
      extraFields: { ...otherTicket }
    };

    dispatch(this.ticketActions.createAttempt(completeTicket));

    return this.feathers
      .service("api/tickets")
      .create(completeTicket)
      .then(result => {
        dispatch(this.ticketActions.createSuccess(result));
        navigate(
          Routes.TICKET.replace(":GroupId", result.GroupId).replace(
            ":id",
            result.id
          )
        );

        this.setState({
          text: ""
        });

        return result;
      })
      .catch(error => {
        dispatch(this.ticketActions.createError(error));
      });
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  handleChangeEvent = (event, data) => {
    this.handleChange(event.target.name, event.target.value);
  };

  handleToggleEvent = (event, data) => {
    this.handleChange("emergency", data.checked);
  };

  _fieldNotEmpty = (fieldName, minLength = 5) => {
    let value = this.state[fieldName];
    return value.length > minLength;
  };

  _imageRequiredFullfiled = () => {
    let GroupId = this.props.match.params.GroupId;
    const { authentication } = this.props;
    const user = authentication.get("user").toJS();
    let subscription = user.subscriptions.find(s => s.GroupId == GroupId);
    if (!subscription) {
      return false;
    }
    let { metadata } = subscription;
    if (!metadata || !metadata.requireAttachment) {
      return true;
    }
    if (this.state.images.length < 1) {
      return false;
    }
  };

  modules = {
    // imageDrop: {
    //   handleLoading: (value = true) => {
    //     this.setState({
    //       loadingImage: value
    //     });
    //   },
    //   handleFile: aFile => {
    //     this.setState({
    //       loadingImage: true,
    //       progress: 0
    //     });
    //     let formData = new FormData();
    //     formData.append("uri", aFile, aFile.name);
    //     let request = new XMLHttpRequest();
    //     request.open("POST", "/api/file");
    //     request.send(formData);
    //     request.addEventListener("progress", evt => {
    //       if (evt.lengthComputable) {
    //         var percentComplete = (evt.loaded / evt.total) * 100;
    //         this.setState({
    //           progress: percentComplete
    //         });
    //       } else if (aFile.size) {
    //         this.setState({
    //           progress: Math.min(1, evt.loaded / aFile.size)
    //         });
    //       }
    //     });
    //     request.addEventListener("load", evt => {
    //       this.setState({
    //         images: [
    //           ...this.state.images,
    //           {
    //             uri: `https://s3.us-east-2.amazonaws.com/beehive-store/${
    //               JSON.parse(evt.target.response).id
    //             }`,
    //             type: aFile.type
    //           }
    //         ],
    //         loadingImage: false,
    //         progress: 0
    //       });
    //     });
    //   } /*,
    //   handleUri: (base64, type) => {
    //     this.setState({
    //       loadingImage: true
    //     });
    //     this.feathers.service('api/upload').create({uri: base64})
    //       .then(response => {
    //         //console.log('upload response', response);
    //         this.setState({
    //           images: [...this.state.images, {uri: `https://s3.us-east-2.amazonaws.com/beehive-store/${response.id}`, type}],
    //           loadingImage: false
    //         });
    //       }).catch(e => {
    //         //console.log('upload error', e);
    //         this.setState({
    //           loadingImage: false
    //         })
    //       })
    //   }*/
    // }
  };

  handleInit = () => {
    this.pond.allowPaste = true;
    this.pond.dropOnPage = true;
    //console.log(this.pond);
    this.pond._pond.setOptions({
      allowFileTypeValidation: true,
      acceptedFileTypes: ["image/*"]
    });
  };

  render() {
    const { t, authentication, tickets } = this.props;
    const user = authentication.get("user").toJS();

    if (!!tickets.loading) {
      return (
        <Container style={styles.container}>
          <Loader active />
        </Container>
      );
    }

    if (!user) {
      return null;
    }

    let GroupId = this.props.match.params.GroupId;
    const writePermissions = this.writePermissionsCalculate(GroupId);

    if (!writePermissions.canWrite) {
      return <Message>{writePermissions.message}</Message>;
    }

    const AutoDisplay = props => {
      const { component, shouldShow } = props;

      if (!!shouldShow) {
        return component;
      }

      return null;
    };

    const subscription = user.subscriptions.find(e => e.GroupId == GroupId);
    const group = subscription.Group;
    //console.log("writePermissions", writePermissions);
    //console.log("group", subscription.group);

    const requiredImage =
      !!subscription.metadata && !!subscription.metadata.requireAttachment;

    const extraFields =
      !!subscription.metadata && !!subscription.metadata.rules
        ? subscription.metadata.rules
        : {};

    const validateFields = Object.keys(extraFields).reduce((prev, curr) => {
      if (!prev) {
        return prev;
      }
      return (
        !extraFields[curr].required ||
        (this.state[curr] != undefined && this.state[curr].length > 0)
      );
    }, true);
    const extraFieldsAreComplete = () => {
      return validateFields;
    };
    return (
      <Container style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Section link>Principal</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link>{group.name}</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link>Tickets</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Create</Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column witdh={16}>
              <Header dividing size="large">
                <Header.Content>
                  CREATE TICKET IN GROUP {group.name.toUpperCase()}
                  <Header.Subheader>
                    {!requiredImage ? null : t("tickets.create.imagerequired")}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width="10">
              <Segment basic>
                <Segment basic size={"huge"}>
                  <Input
                    fluid
                    placeholder={t("tickets.create.subject")}
                    name="subject"
                    onChange={this.handleChangeEvent}
                  />
                </Segment>
                <Segment basic size="huge">
                  <Transition
                    visible={this._fieldNotEmpty("subject")}
                    animation="fly right"
                    duration={500}
                  >
                    <div>
                      {Object.keys(extraFields).map(aKey => {
                        let fieldObject = extraFields[aKey];
                        let currentValue = this.state[aKey];
                        let showError =
                          !!fieldObject.required &&
                          (!currentValue || currentValue.length <= 0);
                        //console.log(currentValue);
                        return (
                          <Input
                            fluid
                            required
                            label={fieldObject.label}
                            key={aKey}
                            value={currentValue}
                            error={showError}
                            placeholder={fieldObject.label}
                            name={aKey}
                            onChange={this.handleChangeEvent}
                          />
                        );
                      })}
                    </div>
                  </Transition>
                </Segment>
                <Segment
                  basic
                  size={"huge"}
                  loading={!!this.state.loadingImage}
                >
                  <Transition
                    visible={this._fieldNotEmpty("subject")}
                    animation="fly right"
                    duration={500}
                  >
                    <Form.Field name="text">
                      <Header>{t("tickets.create.text")}</Header>
                      <ReactQuill
                        value={this.state.text}
                        style={{ height: "200px", marginBottom: "50px" }}
                        onChange={text => this.handleChange("text", text)}
                        modules={this.modules}
                      />
                      <FilePond
                        ref={ref => {
                          this.pond = ref;
                        }}
                        allowMultiple={true}
                        maxFiles={5}
                        dropOnPage={true}
                        name="uri"
                        server="/api/file"
                        oninit={this.handleInit}
                        onprocessfile={e => {
                          // Set current file objects to this.state
                          //console.log("e", e);
                          this.setState({
                            images: this.pond.getFiles()
                          });
                        }}
                        onupdatefiles={fileItems => {
                          // Set current file objects to this.state
                          //console.log("fileItems", fileItems);
                          this.setState({
                            images: fileItems
                          });
                        }}
                      />
                      {/* <Image.Group size="small">
                        {this.state.images.map((anImage, x) => {
                          return (
                            <Image
                              style={{
                                height: 100,
                                overflow: "hidden",
                                objectFit: "cover",
                                margin: 5
                              }}
                              key={`image-${x}attachment`}
                              src={anImage.uri}
                              label={{
                                as: "a",
                                style: { marginRight: -3.5 },
                                onClick: () => {
                                  this.removeImage(anImage);
                                },
                                color: "red",
                                corner: "right",
                                icon: "trash"
                              }}
                            />
                          );
                        })}
                      </Image.Group> */}
                    </Form.Field>
                  </Transition>
                </Segment>
                <Segment basic size={"small"}>
                  <Transition
                    visible={
                      this._fieldNotEmpty("text", 20) &&
                      this._fieldNotEmpty("subject") &&
                      this._imageRequiredFullfiled() &&
                      extraFieldsAreComplete()
                    }
                    animation="fly right"
                    duration={500}
                  >
                    <Form.Field inline>
                      <Button
                        color={!!this.state.emergency ? "red" : "green"}
                        fluid
                        disabled={
                          !extraFieldsAreComplete() ||
                          this.state.images.reduce(
                            (p, a) => p || !a.serverId,
                            false
                          )
                        }
                        onClick={() =>
                          this.createMessage(
                            {
                              subject: this.state.subject,
                              emergency: this.state.emergency,
                              ...Object.keys(extraFields).reduce(
                                (prev, current) => {
                                  return {
                                    ...prev,
                                    [current]: this.state[current]
                                  };
                                },
                                {}
                              )
                            },
                            this.state.text,
                            this.state.images.map(a =>
                              a.serverId.replace(/"/g, "")
                            )
                          )
                        }
                        size={"huge"}
                        icon={!!this.state.emergency ? "emergency" : "add"}
                        content={
                          !!this.state.emergency
                            ? t("tickets.create.createEmergency")
                            : t("tickets.create.createTicket")
                        }
                      />
                      <Divider />
                      <Header>{t("tickets.create.emergency")}</Header>
                      <Form.Checkbox
                        name="emergency"
                        toggle
                        checked={this.state.emergency}
                        onChange={this.handleToggleEvent}
                      />
                      <Segment basic>
                        {!this.state.emergency ? null : (
                          <Label basic size="huge" color="red">
                            {t("tickets.create.emergencyAlert")}
                          </Label>
                        )}
                      </Segment>
                    </Form.Field>
                  </Transition>
                </Segment>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const StyledView = Radium(TicketCreateView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  TicketCreateView.mapStateToProps,
  TicketCreateView.mapDispatchToProps
)(i18nEnhancement);
