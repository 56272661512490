import React, { Component } from "react";
import {
  Accordion, Breadcrumb, Card, Divider, Modal, Grid,
  Button, Dropdown, Input, Menu, Header, Icon, Form,
  Label, List, Segment, Select, Message, Search
} from "semantic-ui-react";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { orderBy, range } from 'lodash';
import Radium from "radium";
import * as Routes from "../../../routes";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import update from 'immutability-helper';
import FilteredCollection from '../layout/FilteredCollection';

const styles = {};
const INITIAL_STATE = {
  filters: {
    open: true,
    name: '',
    ownerFilter: 'owned'
  },
  sort: {
    by: 'name',
    order: 'asc'
  },
  watchlists: {
    loading: false,
    error: null,
    data: [],
    successMessage: '',
    errorMessage: ''
  },
  watchlistsCount: {
    loading: false,
    error: null,
    data: [],
    successMessage: '',
    errorMessage: ''
  },
  upsertModal: {
    open: false,
    loading: false,
    error: null,
    values: {
      id: null,
      name: '',
      description: '',
      type: 'watchlist',
      priority: 0
    }
  },
  removeModal: {
    open: false,
    loading: false,
    error: null,
    values: {
      id: null,
      name: ''
    }
  },
  ticketsModal: {
    open: false,
    loading: false,
    error: null,
    watchlist: null,
    data: [],
    search: {
      value: '',
      loading: false,
      error: null,
      data: []
    }
  },
  usersModal: {
    open: false,
    loading: false,
    error: null,
    watchlist: null,
    data: [],
    search: {
      value: '',
      loading: false,
      error: null,
      data: []
    }
  }
};

class WatchListsView extends Component {
  constructor (props) {
    super(props);

    this.state = INITIAL_STATE;

    this.WatchListService = props.services.feathers.service('api/watchlists');
    this.UsersService = props.services.feathers.service('api/users');
    this.TicketsService = props.services.feathers.service('api/tickets');
    this.WatchListTicketsService = props.services.feathers.service('api/watchlist-tickets');
    this.WatchListUsersService = props.services.feathers.service('api/watchlist-users');
  }

  setSuccessMessage = (message, cb) => {
    this.setState(update(this.state, {
      watchlists: {
        $merge: {
          successMessage: message
        }
      }
    }), () => {
      if (cb && typeof cb === 'function') {
        cb();
      }
    });
  }

  setNameSearchFilter = (e, input) => {
    this.setState(update(this.state, {
      filters: {
        $merge: {
          name: input.value
        }
      }
    }));
  }

  setErrorMessage = (message, cb) => {
    this.setState(update(this.state, {
      watchlists: {
        $merge: {
          errorMessage: message
        }
      }
    }), () => {
      if (cb && typeof cb === 'function') {
        cb();
      }
    });
  }

  filterCollectionByName = (data) => {
    let { filters: { name }} = this.state;

    return data.filter((watchlist) => {
      return watchlist.name.match(new RegExp(name, 'gi'));
    });
  }

  setSortOrder = (by = 'name', order = 'asc') => {
    this.setState(update(this.state, {
      sort: {
        $merge: {
          by,
          order
        }
      }
    }));
  }

  countWatchLists = (cb) => {
    this.setState(update(this.state, {
      watchlistsCount: {
        $merge: {
          loading: true
        }
      }
    }));

    return this.WatchListService.find({
      query: {
        count: true
      }
    })
      .then(response => {
        this.setState(update(this.state, {
          watchlistsCount: {
            $merge: {
              loading: false,
              data: response
            }
          }
        }), () => {
          if (cb && typeof cb === 'function') {
            cb();
          }
        });
      })
      .catch(error => {
        console.error(error);
        this.setState(update(this.state, {
          watchlistsCount: {
            $merge: {
              loading: false,
              error,
              errorMessage: error.message
            }
          }
        }));
      });
  }

  findWatchLists = () => {
    let { filters } = this.state;

    this.setState(update(this.state, {
      watchlists: {
        $merge: {
          loading: true
        }
      }
    }));

    return this.WatchListService.find({
      query: {
        ownerFilter: filters.ownerFilter
      }
    })
      .then(response => {
        this.setState(update(this.state, {
          watchlists: {
            $merge: {
              loading: false,
              data: response
            }
          }
        }));
      })
      .catch(error => {
        console.error(error);
        this.setState(update(this.state, {
          watchlists: {
            $merge: {
              loading: false,
              error,
              errorMessage: error.message
            }
          }
        }));
      });
  }

  sortWatchlists = (data) => {
    let { sort: { by, order } } = this.state;

    return orderBy(data,
      [watchlist => {
        if (typeof watchlist[by] === 'string') {
          return watchlist[by].toLowerCase();
        } else {
          return watchlist[by];
        }
      }],
      [order]);
  }

  handleFormInput = (e, input) => {
    this.setState(update(this.state, {
      upsertModal: {
        values: {
          $merge: {
            [input.name]: input.value
          }
        }
      }
    }));
  }

  toggleFilters = () => {
    this.setState(update(this.state, {
      filters: {
        $merge: {
          open: !this.state.filters.open
        }
      }
    }));
  }

  applyFilters = (ownerFilter) => {
    this.setState(update(this.state, {
      filters: {
        $merge: {
          ownerFilter
        }
      }
    }), this.findWatchLists);
  }

  openUpsertModal = (watchlist) => {
    if (watchlist) {
      this.setState(update(this.state, {
        upsertModal: {
          $merge: {
            open: true,
            values: {
              id: watchlist.id,
              name: watchlist.name,
              description: watchlist.description,
              type: watchlist.type,
              priority: watchlist.priority
            }
          }
        }
      }));
    } else {
      this.setState(update(this.state, {
        upsertModal: {
          $merge: {
            open: true
          }
        }
      }));
    }
  }

  dismissUpsertModal = (e) => {
    if (this.state.upsertModal.loading) {
      return;
    }

    this.setState(update(this.state, {
      upsertModal: {
        $set: INITIAL_STATE.upsertModal
      }
    }));
  }

  openRemoveModal = (watchlist) => {
    this.setState(update(this.state, {
      removeModal: {
        $merge: {
          open: true,
          values: {
            id: watchlist.id,
            name: watchlist.name
          }
        }
      }
    }));
  }

  openTicketsModal = (watchlist) => {
    this.setState(update(this.state, {
      ticketsModal: {
        $merge: {
          open: true,
          watchlist
        }
      }
    }), this.findWatchlistTickets);
  }

  dismissRemoveModal = (e) => {
    if (this.state.removeModal.loading) {
      return;
    }

    this.setState(update(this.state, {
      removeModal: {
        $set: INITIAL_STATE.removeModal
      }
    }));
  }

  openUsersModal = (watchlist) => {
    this.setState(update(this.state, {
      usersModal: {
        $merge: {
          open: true,
          watchlist
        }
      }
    }), this.findWatchlistUsers);
  }

  dismissUsersModal = (e) => {
    if (this.state.usersModal.loading) {
      return;
    }

    this.setState(update(this.state, {
      usersModal: {
        $set: INITIAL_STATE.usersModal
      }
    }));
  }

  searchUsersInputHandler = (e, input) => {
    this.setState(update(this.state, {
      usersModal: {
        search: {
          $merge: {
            value: input.value
          }
        }
      }
    }), this.searchUsers);
  }

  searchTicketsInputHandler = (e, input) => {
    this.setState(update(this.state, {
      ticketsModal: {
        search: {
          $merge: {
            value: input.value
          }
        }
      }
    }), this.searchTickets);
  }

  removeWatchlistTicket = ({ id: TicketId }) => {
    let {
      ticketsModal: {
        watchlist: { id: GroupId }
      }
    } = this.state;

    this.setState(update(this.state, {
      ticketsModal: {
        $merge: {
          loading: true
        }
      }
    }));

    this.WatchListTicketsService.remove({ GroupId, TicketId })
      .then(response => {
        this.setState(update(this.state, {
          usersModal: {
            $merge: {
              loading: false
            }
          }
        }), this.findWatchlistTickets);
      })
      .catch(error => {
        this.setState(update(this.state, {
          usersModal: {
            $merge: {
              loading: false,
              error
            }
          }
        }));
      });
  }

  removeWatchlistUser = ({ GroupId, UserId }) => {
    this.setState(update(this.state, {
      usersModal: {
        $merge: {
          loading: true
        }
      }
    }));

    this.WatchListUsersService.remove({ GroupId, UserId })
      .then(response => {
        this.setState(update(this.state, {
          usersModal: {
            $merge: {
              loading: false
            }
          }
        }), this.findWatchlistUsers);
      })
      .catch(error => {
        this.setState(update(this.state, {
          usersModal: {
            $merge: {
              loading: false,
              error
            }
          }
        }));
      });
  }

  findWatchlistUsers = () => {
    let { usersModal } = this.state;

    this.setState(update(this.state, {
      usersModal: {
        $merge: {
          loading: true
        }
      }
    }));

    this.WatchListUsersService.find({
      query: {
        GroupId: usersModal.watchlist.id
      }
    })
      .then(response => {
        this.setState(update(this.state, {
          usersModal: {
            $merge: {
              loading: false,
              data: response
            }
          }
        }));
      })
      .catch(error => {
        this.setState(update(this.state, {
          usersModal: {
            $merge: {
              loading: false,
              error
            }
          }
        }));
      });
  }

  findWatchlistTickets = () => {
    let { ticketsModal } = this.state;

    this.setState(update(this.state, {
      ticketsModal: {
        $merge: {
          loading: true
        }
      }
    }));

    this.WatchListTicketsService.find({
      query: {
        GroupId: ticketsModal.watchlist.id
      }
    })
      .then(response => {
        //console.log(response);
        this.setState(update(this.state, {
          ticketsModal: {
            $merge: {
              loading: false,
              data: response.watchedTickets
            }
          }
        }));
      })
      .catch(error => {
        //console.log(error);
        this.setState(update(this.state, {
          ticketsModal: {
            $merge: {
              loading: false,
              error
            }
          }
        }));
      });
  }

  searchUsers = () => {
    let { usersModal } = this.state;

    if (usersModal.search.value.length >= 3) {
      this.setState(update(this.state, {
        usersModal: {
          search: {
            $merge: {
              loading: true
            }
          }
        }
      }));

      this.UsersService.find({
        query: {
          $or: {
            fullName: {
              $like: `%${usersModal.search.value}%`
            },
            username: {
              $like: `%${usersModal.search.value}%`
            }
          }
        }
      })
        .then(response => {
          //console.log(response);
          this.setState(update(this.state, {
            usersModal: {
              search: {
                $merge: {
                  loading: false,
                  data: response.data.map(u => ({
                    id: u.id,
                    title: u.fullName,
                    description: u.username
                  }))
                }
              }
            }
          }));
        })
        .catch(error => {

        });
    }
  }

  userResultSelect = (e, input, x) => {
    let { usersModal } = this.state;

    this.setState(update(this.state, {
      usersModal: {
        $merge: {
          loading: true
        }
      }
    }));

    this.WatchListUsersService.create({
      UserId: input.result.id,
      GroupId: usersModal.watchlist.id,
      owner: false
    })
      .then(response => {
        this.setState(update(this.state, {
          usersModal: {
            $merge: {
              loading: false
            }
          }
        }), this.findWatchlistUsers);
      })
      .catch(error => {
        //console.log(error);
        this.setState(update(this.state, {
          usersModal: {
            $merge: {
              loading: false,
              error
            }
          }
        }));
      });
  }

  ticketResultSelect = (e, input, x) => {
    let { ticketsModal } = this.state;

    this.setState(update(this.state, {
      ticketsModal: {
        $merge: {
          loading: true
        }
      }
    }));

    this.WatchListTicketsService.create({
      TicketId: input.result.id,
      GroupId: ticketsModal.watchlist.id
    })
      .then(response => {
        this.setState(update(this.state, {
          ticketsModal: {
            $merge: {
              loading: false
            }
          }
        }), this.findWatchlistTickets);
      })
      .catch(error => {
        //console.log(error);
        this.setState(update(this.state, {
          ticketsModal: {
            $merge: {
              loading: false,
              error
            }
          }
        }));
      });
  }

  searchTickets = () => {
    let { ticketsModal } = this.state;

    this.setState(update(this.state, {
      ticketsModal: {
        search: {
          $merge: {
            loading: true
          }
        }
      }
    }));

    this.TicketsService.find({
      query: {
        id: ticketsModal.search.value
      }
    })
      .then(response => {
        //console.log(response);
        this.setState(update(this.state, {
          ticketsModal: {
            search: {
              $merge: {
                loading: false,
                data: response.data.map(t => ({
                  id: t.id,
                  title: `#${t.id}`,
                  description: t.subject
                }))
              }
            }
          }
        }));
      })
      .catch(error => {

      });
  }

  dismissTicketsModal = (e) => {
    if (this.state.ticketsModal.loading) {
      return;
    }

    this.setState(update(this.state, {
      ticketsModal: {
        $set: INITIAL_STATE.ticketsModal
      }
    }));
  }

  removeWatchlist = () => {
    let { removeModal } = this.state;

    if (removeModal.loading || !removeModal.values.id) {
      return;
    }

    this.setState(update(this.state, {
      removeModal: {
        $merge: {
          loading: true
        }
      }
    }));

    this.WatchListService.remove(removeModal.values.id)
      .then(response => {
        this.setState(update(this.state, {
          removeModal: {
            $set: INITIAL_STATE.removeModal
          }
        }), this.findWatchLists);
      })
      .catch(error => {
        this.setState(update(this.state, {
          removeModal: {
            $merge: {
              loading: false,
              error
            }
          }
        }));
      });
  }

  submitUpsert = (e) => {
    e.preventDefault();

    let { upsertModal: { values } } = this.state;

    this.setState(update(this.state, {
      upsertModal: {
        $merge: {
          loading: true
        }
      }
    }));

    if (values.id) {
      this.WatchListService.patch(values.id, values)
        .then(response => {
          this.setState(update(this.state, {
            upsertModal: {
              $set: INITIAL_STATE.upsertModal
            }
          }), () => {
            this.setSuccessMessage('Lista de seguimiento actualizada', this.findWatchLists);
          });
        })
        .catch(error => {
          this.setState(update(this.state, {
            upsertModal: {
              $merge: {
                loading: false,
                error
              }
            }
          }));
        });
    } else {
      this.WatchListService.create(values)
        .then(response => {
          this.setState(update(this.state, {
            upsertModal: {
              $set: INITIAL_STATE.upsertModal
            }
          }), () => {
            this.setSuccessMessage('Lista de seguimiento creada', this.findWatchLists);
          });
        })
        .catch(error => {
          this.setState(update(this.state, {
            upsertModal: {
              $merge: {
                loading: false,
                error
              }
            }
          }));
        });
    }

  }

  componentDidMount () {
    this.countWatchLists(this.findWatchLists);
  }

  static mapStateToProps (state) {
    return {
      user: !state.authentication ? null : state.authentication.toJS().user
    };
  }

  static mapDispatchToProps (dispatch) {
    return {
      navigate: path => dispatch(push(path)),
      dispatch
    };
  }

  render () {
    let { filters, sort, upsertModal, ticketsModal, usersModal, removeModal, watchlists, watchlistsCount } = this.state;

    return (
      <FilteredCollection
        breadcrumb={
          <Breadcrumb style={{ paddingTop: 16 }}>
            <Breadcrumb.Section as={Link} to={Routes.DASHBOARD}>Principal</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>Suscripciones</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Watchlists</Breadcrumb.Section>
          </Breadcrumb>
        }
        header={
          <Header dividing size="large">
            <Header.Content>
              WATCHLISTS
              <Header.Subheader>Listas de Seguimiento</Header.Subheader>
            </Header.Content>
          </Header>
        }
        headerMobile={
          <Header dividing size="large" textAlign="center">
            <Header.Content>
              WATCHLISTS
              <Header.Subheader>Listas de Seguimiento</Header.Subheader>
            </Header.Content>
          </Header>
        }
        filter={
          <Accordion>
            <Accordion.Title active={filters.open} index={0} onClick={this.toggleFilters}>
              <Icon name="filter" />
              Filtros
            </Accordion.Title>
            <Accordion.Content active={filters.open}>
              <Form onSubmit={this.applyFilters}>
                <Menu vertical size="large" fluid>
                  <Menu.Item>
                    <Input
                      icon="search" transparent placeholder="Buscar..."
                      value={filters.name} onChange={this.setNameSearchFilter} />
                  </Menu.Item>
                  <Menu.Item
                    name="withTickets"
                    active={filters.ownerFilter === 'owned'}
                    onClick={this.applyFilters.bind(null, 'owned')}>
                    <Label color="orange">
                      {watchlistsCount.loading ? '...' : watchlistsCount.data.owned}
                    </Label>
                    Propias
                  </Menu.Item>
                  <Menu.Item
                    name="withoutTickets"
                    active={filters.ownerFilter === 'notowned'}
                    onClick={this.applyFilters.bind(null, 'notowned')}>
                    <Label color="orange">
                      {watchlistsCount.loading ? '...' : watchlistsCount.data.notOwned}
                    </Label>
                    No Propias
                  </Menu.Item>
                  <Menu.Item
                    name="withoutTickets"
                    active={filters.ownerFilter === 'all'}
                    onClick={this.applyFilters.bind(null, 'all')}>
                    <Label color="orange">
                      {watchlistsCount.loading ? '...' : watchlistsCount.data.total}
                    </Label>
                    Todas
                  </Menu.Item>
                </Menu>
              </Form>
            </Accordion.Content>
          </Accordion>
        }
        collectionView={
          <div>
            <Menu>
              <Dropdown item icon="list" simple>
                <Dropdown.Menu>
                  <Dropdown.Item icon="add" content="Nuevo" onClick={this.openUpsertModal.bind(null, null)} />
                  <Dropdown.Divider />
                  <Dropdown.Item icon="download" content="Export" />
                  <Dropdown.Item icon="share" content="Share" />
                </Dropdown.Menu>
              </Dropdown>
              <Menu.Item
                icon="refresh"
                onClick={this.findWatchLists} />
              <Menu.Item
                icon="add"
                onClick={this.openUpsertModal.bind(null, null)} />
              <Menu.Item
                icon={sort.order === 'asc' ? 'sort alphabet descending' : 'sort alphabet ascending' }
                position="right"
                onClick={this.setSortOrder.bind(null, 'name', sort.order === 'asc' ? 'desc' : 'asc')} />
            </Menu>
            {
              watchlists.successMessage &&
              <Message
                positive onDismiss={this.setSuccessMessage.bind(null, '')}
                header="Exito!"
                content={watchlists.successMessage} />
            }
            {
              watchlists.errorMessage &&
              <Message
                negative onDismiss={this.setErrorMessage.bind(null, '')}
                header="Error!"
                content={watchlists.errorMessage} />
            }
            <Segment loading={watchlists.loading} vertical>
              <Card.Group itemsPerRow={3} stackable>
                {
                  this.sortWatchlists(this.filterCollectionByName(watchlists.data)).map((watchlist, index) => {
                    return (
                      <Card key={index}>
                        <Card.Content>
                          <Card.Header content={watchlist.name} />
                          <Card.Meta>
                            <a onClick={this.openTicketsModal.bind(null, watchlist)}>Tickets</a>
                          </Card.Meta>
                          <Card.Meta>
                            <a onClick={this.openUsersModal.bind(null, watchlist)}>Usuarios</a>
                          </Card.Meta>
                          <Card.Description content={watchlist.description} />
                        </Card.Content>
                        <Card.Content extra>
                          <Button.Group fluid>
                            <Button basic icon="trash" negative onClick={this.openRemoveModal.bind(null, watchlist)} />
                            <Button icon="edit" content="Editar" color="orange" onClick={this.openUpsertModal.bind(null, watchlist)} />
                          </Button.Group>
                        </Card.Content>
                      </Card>
                    );
                  })
                }
              </Card.Group>
              <Modal open={upsertModal.open} onClose={this.dismissUpsertModal} size="small">
                <Modal.Header>Upsert Watchlist</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <Form disabled={upsertModal.loading}>
                      <Form.Input name="name" label="Nombre" value={upsertModal.values.name} onChange={this.handleFormInput} />
                      <Form.TextArea name="description" label="Descripcion" value={upsertModal.values.description} onChange={this.handleFormInput} />
                    </Form>
                    {
                      upsertModal.error &&
                      <Message
                        negative
                        header={upsertModal.error.name}
                        content={upsertModal.error.message} />
                    }
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <Button basic negative icon="remove" content="Cancelar" onClick={this.dismissUpsertModal} disabled={upsertModal.loading} />
                  <Button positive icon="checkmark" content={upsertModal.loading ? 'Procesando...' : 'Aceptar'} onClick={this.submitUpsert} disabled={upsertModal.loading} />
                </Modal.Actions>
              </Modal>
              <Modal open={removeModal.open} onClose={this.dismissRemoveModal} dimmer="blurring" basic size="small">
                <Header icon="trash" content="Borrar Lista" />
                <Modal.Content>
                  <p>Seguro desea borrar la lista {removeModal.values.name}?</p>
                  {
                    removeModal.error &&
                    <Message
                      negative
                      header={removeModal.error.name}
                      content={removeModal.error.message} />
                  }
                </Modal.Content>
                <Modal.Actions>
                  <Button basic color="red" inverted disabled={removeModal.loading} onClick={this.dismissRemoveModal}>
                    <Icon name="remove" /> No
                  </Button>
                  <Button color="green" inverted disabled={removeModal.loading} onClick={this.removeWatchlist}>
                    <Icon name="checkmark" /> {removeModal.loading ? 'Procesando...': 'Si'}
                  </Button>
                </Modal.Actions>
              </Modal>
              <Modal open={ticketsModal.open} onClose={this.dismissTicketsModal} closeIcon>
                <Header icon="tags" content="Tickets" />
                <Modal.Content as={Segment} loading={ticketsModal.loading} vertical>
                  <Search
                    loading={ticketsModal.search.loading}
                    onResultSelect={this.ticketResultSelect}
                    onSearchChange={this.searchTicketsInputHandler}
                    results={ticketsModal.search.data}
                    value={ticketsModal.search.value} />
                  <List divided relaxed>
                    {
                      ticketsModal.data.map((ticket, index) => (
                        <List.Item key={index}>
                          <List.Content floated="right">
                            <Button icon="trash" negative basic onClick={this.removeWatchlistTicket.bind(null, ticket)} />
                          </List.Content>
                          <List.Icon name="user" size="large" verticalAlign="middle" />
                          <List.Content>
                            <List.Header as="a">#{ticket.id}</List.Header>
                            <List.Description>{ticket.subject}</List.Description>
                          </List.Content>
                        </List.Item>
                      ))
                    }
                  </List>
                </Modal.Content>
              </Modal>
              <Modal open={usersModal.open} onClose={this.dismissUsersModal} closeIcon>
                <Header icon="users" content="Usuarios" />
                <Modal.Content as={Segment} loading={usersModal.loading} vertical>
                  <Search
                    loading={usersModal.search.loading}
                    onResultSelect={this.userResultSelect}
                    onSearchChange={this.searchUsersInputHandler}
                    results={usersModal.search.data}
                    value={usersModal.search.value} />
                  <List divided relaxed>
                    {
                      usersModal.data.map((watchlistUser, index) => (
                        <List.Item key={index}>
                          {
                            !watchlistUser.owner && (
                              <List.Content floated="right">
                                <Button icon="trash" negative basic onClick={this.removeWatchlistUser.bind(null, watchlistUser)} />
                              </List.Content>
                            )
                          }
                          <List.Icon name="user" size="large" verticalAlign="middle" />
                          <List.Content>
                            <List.Header as="a">{watchlistUser.user.fullName}</List.Header>
                            <List.Description>{watchlistUser.user.username}</List.Description>
                          </List.Content>
                        </List.Item>
                      ))
                    }
                  </List>
                </Modal.Content>
              </Modal>
            </Segment>
          </div>
        } />
    );
  }
}

const StyledView = Radium(WatchListsView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  WatchListsView.mapStateToProps,
  WatchListsView.mapDispatchToProps
)(i18nEnhancement);