import React, { Component } from "react";
import { Form, Message } from "semantic-ui-react";

const INITIAL_STATE = {
  loading: false,
  values: {
    email: "",
    username: "",
    password: "",
    fullName: "",
    roles: ["client"],
    subscription: "client"
  },
  error: null,
  message: {
    title: "",
    content: "",
    type: "positive"
  }
};

const ROLES = [
  {
    key: 0,
    value: "sysadmin",
    text: "Sysadmin"
  },
  {
    key: 1,
    value: "agent",
    text: "Agent"
  },
  {
    key: 2,
    value: "supervisor",
    text: "Supervisor"
  },
  {
    key: 3,
    value: "client",
    text: "Cliente"
  }
];

const SUBS = [
  {
    key: 0,
    value: "agent",
    text: "Agente"
  },
  {
    key: 1,
    value: "supervisor",
    text: "Supervisor"
  },
  {
    key: 2,
    value: "client",
    text: "Cliente"
  }
];

class CreateUserSubForm extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
    this.UserService = props.services.feathers.service("api/users");
    this.SubscriptionService = props.services.feathers.service(
      "api/subscriptions"
    );
  }

  inputHandler = (e, input) => {
    this.setState({
      values: {
        ...this.state.values,
        [e.target.id]: input.value
      }
    });
  };

  permissionsSelector = type => {
    const selector = {
      client: ["create", "readOwn", "writeOwn", "clientRole"],
      supervisor: ["readAll", "agentRole"],
      agent: ["writeAll", "readAll", "agentRole", "create"]
    };

    return selector[type];
  };

  submit = e => {
    e.preventDefault();

    const { values } = this.state;

    this.setState(
      {
        loading: true,
        error: null,
        message: INITIAL_STATE.message
      },
      async () => {
        try {
          const user = await this.UserService.create({
            email: values.email,
            username: values.email,
            password: values.password,
            fullName: values.fullName,
            roles: values.roles
          });

          await this.SubscriptionService.create({
            GroupId: this.props.group.id,
            UserId: user.id,
            permissions: this.permissionsSelector(values.subscription),
            notifications: {}
          });

          this.setState({
            loading: false,
            message: {
              type: "positive",
              title: "Exito",
              content: "Usuario y Suscripcion creada"
            }
          });
        } catch (error) {
          this.setState({
            loading: false,
            error: error,
            message: {
              type: "negative",
              title: error.name,
              content: error.message
            }
          });
        }
      }
    );
  };

  selectMessageColor = type => {
    const selector = {
      positive: "green",
      negative: "red",
      warning: "orange"
    };

    return selector[type];
  };

  render() {
    const { loading, message, values } = this.state;

    return (
      <Form loading={loading} onSubmit={this.submit}>
        <Form.Group widths="equal">
          <Form.Input
            id="email"
            label="Email"
            onChange={this.inputHandler}
            value={values.email}
          />
          <Form.Input
            id="email"
            label="Username"
            disabled
            onChange={this.inputHandler}
            value={values.email}
          />
          <Form.Input
            id="fullName"
            label="Nombre"
            onChange={this.inputHandler}
            value={values.fullName}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            id="password"
            label="Clave"
            onChange={this.inputHandler}
            value={values.password}
          />
          <Form.Select
            id="roles"
            label="Roles"
            options={ROLES}
            multiple
            onChange={this.inputHandler}
            value={values.roles}
          />
          <Form.Select
            id="subscription"
            label="Suscripcion"
            options={SUBS}
            onChange={this.inputHandler}
            value={values.subscription}
          />
        </Form.Group>
        {message.title &&
          message.content && (
            <Message color={this.selectMessageColor(message.type)}>
              <Message.Header>{message.title}</Message.Header>
              <p>{message.content}</p>
            </Message>
          )}
        <Form.Group widths="equal">
          <Form.Button
            basic
            content="Cancelar"
            disabled={loading}
            negative
            fluid
            onClick={this.props.dismiss}
          />
          <Form.Button
            content="Aceptar"
            disabled={loading}
            positive
            fluid
            onClick={this.submit}
          />
        </Form.Group>
      </Form>
    );
  }
}

export default CreateUserSubForm;
