import React from "react";
import ReactTable from "react-table";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { Link } from "react-router-dom";
import { DEFAULT_FONT_SIZE, CHECKBOX_WIDTH, CELL_SPACES } from "./constants";

const UsersTable = ({ data, loading, updateUser, removeUser }) => (
  <ReactTable
    className="-striped -highlight"
    data={data}
    defaultPageSize={10}
    filterable
    defaultFilterMethod={(filter, row) => {
      return (
        `${row[filter.id]}`.toLowerCase().indexOf(filter.value.toLowerCase()) !=
        -1
      );
    }}
    defaultSorted={[
      {
        id: "fullName",
        desc: false
      }
    ]}
    loading={loading}
    showPageSizeOptions={false}
    columns={[
      {
        Header: "",
        filterable: false,
        width: CHECKBOX_WIDTH,
        Cell: row => (
          <div
            style={{
              width: "100%",
              height: "100%",
              textAlign: "center"
            }}
          >
            <Icon
              className="edit-icon-hover"
              name="edit"
              onClick={updateUser.bind(null, {
                id: row.original.id,
                email: row.original.email,
                username: row.original.username,
                roles: row.original.roles,
                fullName: row.original.fullName
              })}
            />
          </div>
        ),
        sortable: false,
        resizable: false
      },
      {
        Header: "USUARIO",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            width: DEFAULT_FONT_SIZE * CELL_SPACES,
            Cell: row => (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "right"
                }}
              >
                {row.value}
              </div>
            )
          },
          {
            Header: "Username",
            accessor: "username",
            Cell: row => <Link to={`/admin/resources/subscriptions/user/${row.original.id}`}>{row.value}</Link> 
          },
          {
            Header: "Email",
            accessor: "email"
          },
          {
            Header: "Nombre",
            accessor: "fullName"
          }
        ]
      },
      {
        Header: "METADATA",
        columns: [
          {
            filterable: false,
            Header: "Creado",
            id: "createdAt",
            accessor: d => moment(d.createdAt).format("DD-MM-YYYY HH:mm"),
            width: 145
          },
          {
            filterable: false,
            Header: "Modificado",
            id: "updatedAt",
            accessor: d => moment(d.createdAt).format("DD-MM-YYYY HH:mm"),
            width: 145
          }
        ]
      },
      {
        Header: "",
        width: CHECKBOX_WIDTH,
        filterable: false,
        Cell: row => (
          <div
            style={{
              width: "100%",
              height: "100%",
              textAlign: "center"
            }}
          >
            <Icon
              className="trash-icon-hover"
              name="trash"
              onClick={removeUser.bind(null, row.original.id)}
            />
          </div>
        ),
        sortable: false,
        resizable: false
      }
    ]}
  />
);

export default UsersTable;
