import React, { Component } from 'react';
import {
  Breadcrumb, Container, Divider,
  Header, Icon, Grid, List, Segment
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import Radium from 'radium';
import { connect } from 'react-redux';

class GroupTicketsView extends Component {

  render () {
    return (
      <Container style={{ padding: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Section link>Principal</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link>{`{grupo}`}</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Tickets</Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Grid>
          <Grid.Row>
            <Grid.Column witdh={16}>
              <Header dividing size="large">
                {`{GRUPO}`}
                <Header.Subheader>
                  TICKETS
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header attached="top">
                <Icon name="group" />
                <Header.Content>
                  Viendo {`{GRUPO}`}
                  <Header.Subheader>
                    Tickets pertenecientes al grupo {`{grupo}`}
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Segment attached color="blue">
                <List divided relaxed>
                  {
                    [1, 2, 3, 4, 5, 6, 7, 8].map((group, index) => (
                      <List.Item key={index}>
                        <List.Icon name="ticket" size="large" verticalAlign="middle" />
                        <List.Content>
                          <List.Header as="a">Semantic-Org/Semantic-UI</List.Header>
                          <List.Description as="a">Updated 10 mins ago</List.Description>
                        </List.Content>
                      </List.Item>
                    ))
                  }
                </List>
              </Segment>
              <Segment attached="bottom">
                FOOTER
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }

}

const StyledView = Radium(GroupTicketsView);
const i18nEnhancement = translate('translations')(StyledView);

export default connect(GroupTicketsView.mapStateToProps, GroupTicketsView.mapDispatchToProps)(i18nEnhancement);