import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  findAttempt: null,
  findError: ['error'],
  findSuccess: ['entries'],
  createAttempt: ['object'],
  createError: ['error'],
  createSuccess: ['object'],
  getAttempt: null,
  getError: ['error'],
  getSuccess: ['detail']
}, {prefix: "TICKETS_"});
