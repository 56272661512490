import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intersection } from 'lodash';

const defaultOptions = {

};

const ControlHOC = (ComponentToWrap, roles = [], options = defaultOptions) => {
  class ControledHOC extends Component {

    static mapDispatchToProps (dispatch) {
      return {

      };
    }

    static mapStateToProps (state) {
      return {
        authentication: state.authentication.toJS()
      };
    }

    render () {
      const { authentication } = this.props;

      if (authentication.user && intersection(roles, authentication.user.roles).length !== 0) {
        return (
          <ComponentToWrap {...this.props} />
        );
      } else {
        return null;
      }
    }
  }

  return connect(
    ControledHOC.mapStateToProps,
    ControledHOC.mapDispatchToProps
  )(ControledHOC);
};

export default ControlHOC;
