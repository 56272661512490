import React, { Component } from "react";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import _ from "lodash";
import Radium from "radium";
import {
  Button,
  Breadcrumb,
  Image,
  Container,
  Popup,
  Item,
  Accordion,
  Form,
  Menu,
  Input,
  Dropdown,
  Label,
  Divider,
  Header,
  Modal,
  Icon,
  Grid,
  List,
  Checkbox,
  Segment,
  Statistic,
  Loader,
  Message,
  Tab,
  Pagination,
} from "semantic-ui-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomToolbar from "./CustomToolbar";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import * as ROUTES from "../../../../routes";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import ChatsList from "./ChatsList";
import GroupsList from "./GroupsList";
import ConversationComponent from "./ConversationComponent";
import NewConversationsWindow from "./NewConversationsWindow";
import Logic, { TICKET_FILTERS } from "../../../../services/logic";
import { INITIAL_STATE } from "../../../../services/logic";
// const { convertHtmlToDelta } = require("node-quill-converter");
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

var formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  // 'image'
  // 'video'
];
class ChatView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      images: [],
      searchTerm: "",
      preview: null,
      pdfPromise: null,
      moveModal: false,
      moveModalTo: null,
      newConversationMaximized: false,
      moveToTicketModal: false,
      moveToTicketTitle: "",
      selectedRows: [],
      pauseUpdating: false,
      editting: false,
    };

    const user = props.authentication.get("user").toJS();
    const kind = props.match.path.indexOf("wiki") != -1 ? "topic" : "ticket";
    this.logic = new Logic({ feathers: props.services.feathers, user, kind });
    if (kind === "topic") {
      this.logic.setTicketsFilter(TICKET_FILTERS.OPEN);
      if (!props.match.params.group && !props.match.params.ticket) {
        this.logic.setActiveGroup(405);
        this.logic.setActiveTicket(50183);
      } else {
        console.log("settingtopicfromurl");
        // this.logic.setTicketsFilter(TICKET_FILTERS.NOT_REPLIED);
        this.logic.setActiveGroup(props.match.params.group);
        this.logic.setActiveTicket(props.match.params.ticket);
      }
    }
  }

  static mapStateToProps(state) {
    return {
      routing: state.routing,
      authentication: state.authentication,
      subscriptions: state.subscriptions.toJS(),
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: (path) => dispatch(push(path)),
      dispatch,
    };
  }

  changeListener = (param) => {
    if (
      true ||
      param.activities.data.length == this.state.activities.data.length
    ) {
      this.setState({
        ...param,
      });
      return;
    }
    this.setState({
      ...param,
    });
    let scroll = this.props.match.path.indexOf("wiki") != -1 ? 0 : 99999999999;

    (document.getElementById("conversation") || {}).scrollTop = scroll;

    window.setTimeout(
      () =>
        ((document.getElementById("conversation") || {}).scrollTop = scroll),
      200
    );
  };

  handleInit = (a) => {
    if (!this.pond) {
      return;
    }
    this.pond._pond.setOptions({
      acceptedFileTypes: [
        "image/*",
        "application/x-rar-compressed",
        "application/zip",
        "application/xml",
        "text/xml",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/plain",
        "application/rtf",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/csv",
        "application/octet-stream",
      ],
      labelIdle:
        'Arrastra tus archivos e imagenes adjuntos aquí o <span class="filepond--label-action"> seleccionar de mi computadora </span>',
      allowPaste: true,
      dropOnPage: true,
      timeout: 10000000,
    });
  };

  componentDidMount() {
    this.logic.listenToChanges(this.changeListener);
    let { filter, group, ticket, activity } = this.props.match.params;
    window.onfocus = () => {
      if (this.state.active.ticket) {
        document.title = `Ticket #${ticket} - Sistel Helpdesk`;
      } else if (this.state.active.group) {
        document.title = `Grupo ${this.state.active.group.name} - Sistel Helpdesk`;
      } else {
        let base = ``;
        switch (this.state.active.filter) {
          case TICKET_FILTERS.ALL:
            base += `Tickets`;
            break;
          case TICKET_FILTERS.NOT_REPLIED:
            base += `Tickets Sin Responder`;
            break;
          case TICKET_FILTERS.OPEN:
            base += `Tickets Abiertos`;
            break;
          case TICKET_FILTERS.SEARCH:
            base += `Buscando: ${this.state.active.searchTerm}`;
        }
        document.title = `${base} - Sistel Helpdesk`;
      }
    };

    if (filter != undefined) {
      if (filter == "forceall") {
        this.logic.setTicketsFilter(TICKET_FILTERS.ALL);
      } else {
        this.logic.setTicketsFilter(TICKET_FILTERS[filter.toUpperCase()]);
      }

      if (group != undefined) {
        this.logic.setActiveGroup(group);
        if (ticket != undefined) {
          this.logic.setActiveTicket(ticket);
        }
      }
    }
    this.logic.startListeningToEvents();
    this.logic.loadGroups();
    this.logic.loadTickets();
    window.setInterval(() => {
      if (this.state.pauseUpdating) {
        this.logic.stopListeningToEvents();
        return;
      }
      //console.log("intervalTime");
      this.logic.stopListeningToEvents();
      this.logic.startListeningToEvents();
      this.logic.loadGroups(false);
      this.logic.loadTickets();
      if (this.state.active.ticket) {
        this.logic.loadActivities();
      }
    }, 10000);
  }

  componentWillUnmount() {
    this.logic.stopListeningToEvents();
  }

  Editor = {
    modules: {
      keyboard: {
        bindings: {
          go: {
            key: "Enter",
            ctrlKey: false,
            handler: (...params) => {
              this.onSend();
            },
          },
          newlinecustom: {
            key: "Enter",
            ctrlKey: true,
            handler: ({ index }) => {
              this.mainEditor.getEditor().insertText(index, "\n");
            },
          },
        },
      },
    },
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.activities) {
      return;
    }
    if (prevProps.match.params.ticket != this.props.match.params.ticket) {
      this.setState({
        selectedRows: [],
      });
    }
    if (this.state.activities.data.length != prevState.activities.data.length) {
      if (this.props.match.path.indexOf("wiki") != -1) {
        (document.getElementById("conversation") || {}).scrollTop = 0;
      } else {
        (document.getElementById("conversation") || {}).scrollTop = 9999999;
      }
    }

    if (
      prevProps.match.path.indexOf("wiki") !=
      this.props.match.path.indexOf("wiki")
    ) {
      window.location.reload();
    }
    if (
      this.props.match.params.filter == "forceall" &&
      (prevProps.match.params.group != this.props.match.params.group ||
        prevProps.match.params.ticket != this.props.match.params.ticket)
    ) {
      //console.log("FORCE ALL RECEIVED");
      let { filter, group, ticket, activity } = this.props.match.params;
      if (filter != undefined) {
        if (filter == "forceall") {
          this.logic.setTicketsFilter(TICKET_FILTERS.ALL);
        } else {
          this.logic.setTicketsFilter(TICKET_FILTERS[filter.toUpperCase()]);
        }

        if (group != undefined) {
          this.logic.setActiveGroup(group);
          if (ticket != undefined) {
            this.logic.setActiveTicket(ticket);
          }
        }
      }
    }
  };

  closeImageModal = () => {
    this.setState({
      preview: null,
      pauseUpdating: false,
    });
  };

  closeMoveModal = () => {
    this.setState({
      moveModal: false,
      moveModalTo: null,
      pauseUpdating: false,
    });
  };

  closeMoveToTicketModal = () => {
    this.setState({
      moveToTicketModal: false,
      moveToTicketTitle: "",
      pauseUpdating: false,
    });
  };

  openMoveToTicketModal = () => {
    this.setState({
      moveToTicketModal: true,
      pauseUpdating: true,
      moveToTicketTitle:
        this.state.selectedRows.length > 0
          ? this.state.activities.data
              .find((a) => a.id == this.state.selectedRows[0])
              .body.replace(/(&nbsp;|<([^>]+)>)/gi, "")
          : "",
    });
  };

  openImageModal = (url) => {
    this.setState({
      preview: url,
      pauseUpdating: true,
    });
  };

  flagClicked = () => {
    this.onSend(false);
    this.logic.toggleTagForTicket();
  };

  debouncedSearch = (event, data) => {
    if (data.value.length > 3) {
      this._debouncedSearch(event, data);
    }
  };

  _debouncedSearch = _.debounce((event, data) => {
    //console.log("search", data);
    this.logic.setSearchTerm(data.value);
  }, 800);

  closeTicketClicked = () => {
    this.onSend(false);
    this.logic.closeTicket();
  };
  onSend = (doAlert = true, priority = 4, asSystem = false) => {
    let attachments = this.state.images.map((a) =>
      a.serverId.replace(/"/g, "")
    );
    let body = this.mainEditor.getEditorContents();
    if (
      (!body || body.length <= 0 || body == "<p><br></p>") &&
      attachments.length < 1
    ) {
      if (doAlert) {
        alert("Primero tienes que cargar una imagen y/o escribir un mensaje.");
        this.mainEditor.focus();
      }
      return;
    } else if (body == "<p><br></p>") {
      body = null;
    }
    if (!this.state.editting) {
      this.logic.createActivities(body, attachments, null, priority, asSystem);
    } else {
      let selectedRow = this.state.selectedRows[0];
      if (selectedRow == "subject") {
        this.logic.patchSubject(body.replace(/<[^>]*>?/gm, ""));
      } else {
        this.logic.patchActivity(selectedRow, body);
      }
    }

    this.mainEditor.state.value = "";
    this.mainEditor.getEditor().setText("");
    this.setState({ images: [], editting: false, selectedRows: [] });
    if (this.pond && this.pond._pond) {
      this.pond._pond.removeFiles();
    }

    this.mainEditor.focus();
  };

  clearEditor = () => {
    if (this.mainEditor) {
      this.mainEditor.state.value = "";
      this.mainEditor.getEditor().setText("");
    }
    this.setState({ images: [] });
    if (this.pond) {
      this.pond._pond.removeFiles();
    }
    if (this.mainEditor) {
      this.mainEditor.focus();
    }
  };

  closeToBottom = () => {
    // this.loadMoreContent();
  };

  render() {
    let { groups, tickets, activities, user, active } = this.state;
    // console.log("tickets.loading", tickets.loading);
    if (groups.loading || groups.length == 0) {
      return <Loader active />;
    }

    let { filter, group, ticket, activity } = this.props.match.params;

    // if (!tickets.loading && tickets.data.length < 12) {
    //   this.logic.loadMoreContent();
    // }
    let clone = { ...groups };
    switch (active.filter) {
      case TICKET_FILTERS.ALL:
        clone.data = groups.data.map((a) => ({
          ...a,
          stats: (a.stats && a.stats.totalcount) || 0,
        }));
        break;
      case TICKET_FILTERS.NOT_REPLIED:
        clone.data = groups.data.map((a) => ({
          ...a,
          stats: (a.stats && a.stats.importantticketcount) || 0,
        }));
        break;
      default:
        clone.data = groups.data.map((a) => ({
          ...a,
          stats: (a.stats && a.stats.openticketcount) || 0,
        }));
    }

    // let chatsContainer = document.getElementById("chatsListContainer");
    // if (chatsContainer && !chatsContainer.onscroll) {
    //   chatsContainer.onscroll = () => {
    //     // this.logic.loadMoreContent();
    //   };
    // }

    let downloadSectionButton = (sectionId) => {
      return (
        <Button
          icon="file pdf"
          basic
          style={{
            border: null,
            boxShadow: "0 0 0 0px rgba(34,36,38,.15) inset",
          }}
          onClick={async () => {
            this.setState({ pdfPromise: "loading" });
            try {
              let response = await this.props.services.feathers
                .service("api/wiki-section-draw")
                .get(sectionId);
              //console.log("response", response);
              this.setState({ pdfPromise: response.id });
            } catch (error) {
              //console.log("error", error);
              this.setState({ pdfPromise: "error" });
            }
          }}
        />
      );
    };

    const buildUrl = (
      aFilter = !!filter
        ? filter
        : this.props.match.path.indexOf("wiki") != -1
        ? "open"
        : active.filter == TICKET_FILTERS.NOT_REPLIED
        ? "not_replied"
        : active.filter == TICKET_FILTERS.OPEN
        ? "open"
        : "all",
      aGroup = group,
      aTicket = ticket,
      anActivity = activity,
      ticketGroup = null,
      forceAll = false
    ) => {
      let url = `/${this.props.match.path.split("/")[1]}`;
      if (!!aFilter) {
        url += `/${aFilter == "forceall" && !forceAll ? "all" : aFilter}`;
        if (!!aGroup || !!ticketGroup) {
          url += `/${aGroup || ticketGroup}`;
          if (!!aTicket) {
            url += `/${aTicket}`;
            if (!!anActivity) {
              url += `/${anActivity}`;
            }
          }
        }
      }
      return url;
    };

    const getFullPathOfNames = (group, groups, separator = " : ") => {
      if (!group.GroupId) {
        return group.name;
      }
      let parent = groups.find((a) => a.id == group.GroupId);
      if (!parent) {
        return group.name;
      }
      return `${getFullPathOfNames(parent, groups, separator)}${separator}${
        group.name
      }`;
    };

    return (
      <Container
        fluid
        key="maincontainer111"
        id="noGutter"
        style={{
          display: "flex",
          maxHeight: "100vh",
        }}
        key="chatView"
      >
        <div
          key="mainviewflex"
          style={{ display: "flex", flex: 1, minWidth: "30vw" }}
        >
          <div
            style={{
              flex: 1,
              backgroundColor:
                this.logic.kind == "ticket" ? "#767676" : "white",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            className={active.ticket ? "noShowMobile" : "showMobile"}
          >
            {this.props.match.path.indexOf("wiki") != -1 ? null : (
              <Menu
                secondary
                fluid
                widths={2}
                style={{ marginBottom: 0 }}
                inverted
                attached
              >
                {/* <Menu.Item
                  icon={true}
                  active={active.filter == TICKET_FILTERS.OPEN}
                  as={Link}
                  to={buildUrl("open")}
                  onClick={() =>
                    this.logic.setTicketsFilter(TICKET_FILTERS.OPEN)
                  }>
                  <Icon name="circle" color="blue" fitted />
                </Menu.Item> */}
                {/* <Menu.Item
                  icon={true}
                  active={active.filter == TICKET_FILTERS.ALL}
                  as={Link}
                  to={buildUrl("all")}
                  onClick={() =>
                    this.logic.setTicketsFilter(TICKET_FILTERS.ALL)
                  }>
                  <Icon name="circle outline" color="grey" fitted />
                </Menu.Item> */}
                <Menu.Item
                  icon={true}
                  active={active.filter == TICKET_FILTERS.NOT_REPLIED}
                  as={Link}
                  to={buildUrl("not_replied")}
                  onClick={() =>
                    this.logic.setTicketsFilter(TICKET_FILTERS.NOT_REPLIED)
                  }
                >
                  <center>
                    <Icon name="check circle" color="orange" fitted /> Activos
                  </center>{" "}
                </Menu.Item>
                <Menu.Item
                  icon={true}
                  active={active.filter == TICKET_FILTERS.SEARCH}
                  onClick={() =>
                    this.logic.setTicketsFilter(TICKET_FILTERS.SEARCH)
                  }
                >
                  <center>
                    <Icon name="search" color="white" fitted /> Todos
                  </center>{" "}
                </Menu.Item>
              </Menu>
            )}

            <GroupsList
              configuration={clone}
              type={this.logic.kind}
              downloadSectionButtonFn={downloadSectionButton}
              buildUrl={buildUrl}
              filter={active.filter}
              hideStats={active.filter == TICKET_FILTERS.SEARCH}
              onSelect={(...params) => {
                let body = !!this.mainEditor
                  ? this.mainEditor.getEditorContents()
                  : "";
                let go = true;
                if (!!body && body.length > 0 && body != "<p><br></p>") {
                  go = window.confirm(
                    "Todavia no has enviado el ticket. Estás seguro que quieres cambiarlo?"
                  );
                }
                if (!go) {
                  return;
                }
                this.clearEditor();
                this.logic.setActiveGroup(...params);
                this.setState({
                  images: [],
                  editting: false,
                  selectedRows: [],
                });
                this.clearEditor();
              }}
              active={active.group}
            />
          </div>
          <div
            id="chatsListContainer"
            className={active.ticket ? "noShowMobile" : "showMobile"}
            style={{
              flex: 2,
              backgroundColor: "white",
              height: "100%",
              borderRightColor: "lightgray",
              borderRightWidth: "1px",
              borderRightStyle: "solid",
              overflowY: "auto",
              minWidth: "30vw",
            }}
          >
            {active.filter != TICKET_FILTERS.SEARCH &&
            this.logic.kind != "topic" ? null : (
              <Input
                iconPosition="left"
                icon={<Icon name="search" inverted circular link />}
                loading={tickets.loading}
                type="text"
                key={active.group ? active.group.id : "allsearch"}
                onChange={this.debouncedSearch}
                fluid
                clearable
              />
            )}
            <ChatsList
              showLogos={active.group == null}
              user={user.value}
              buildUrl={buildUrl}
              groups={groups}
              swapTickets={this.logic.swapTickets}
              active={active.ticket}
              type={this.logic.kind}
              configuration={tickets}
              loading={tickets.loading}
              loadMore={this.logic.loadMoreContent}
              onSelect={(...something) => {
                if (!!this.mainEditor && !!this.pond) {
                  let body = !!this.mainEditor
                    ? this.mainEditor.getEditorContents()
                    : "";
                  console.log("body", body);
                  let go = true;
                  if (!!body && body.length > 0 && body != "<p><br></p>") {
                    go = window.confirm(
                      "Todavia no has enviado el ticket. Estás seguro que quieres cambiarlo?"
                    );
                  }
                  if (!go) {
                    return;
                  }
                  this.mainEditor.state.value = "";
                  this.mainEditor.getEditor().setText("");

                  this.pond._pond.removeFiles();
                  this.mainEditor.focus();
                }
                this.setState({
                  images: [],
                  editting: false,
                  selectedRows: [],
                });
                this.logic.setActiveTicket(...something);
                this.clearEditor();
              }}
            />
          </div>
          <div
            id="conversationContainer"
            key="convedcont"
            className={active.ticket ? "showMobile" : "noShowMobile"}
            style={{
              flex: 3,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              height: "100%",
            }}
          >
            <div
              style={{
                borderBottomColor: "#eeeeee",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                backgroundColor: this.state.editting ? "#2185d0" : undefined,
              }}
              className={active.ticket ? "showMobile" : "noShowMobile"}
            >
              <Button
                basic
                name="Volver"
                icon="arrow left"
                as={Link}
                to={buildUrl(undefined, undefined, null, null)}
                onClick={this.logic.clearActiveTicket}
                style={{ margin: 10 }}
              >
                Volver
              </Button>
              {active.ticket && active.ticket.type == "topic" ? (
                <Button
                  basic
                  content="Descargar PDF"
                  icon="pdf"
                  onClick={async () => {
                    this.setState({ pdfPromise: "loading" });
                    try {
                      let response = await this.props.services.feathers
                        .service("api/wiki-draw")
                        .get(active.ticket.id);
                      //console.log("response", response);
                      this.setState({ pdfPromise: response.id });
                    } catch (error) {
                      //console.log("error", error);
                      this.setState({ pdfPromise: "error" });
                    }
                  }}
                />
              ) : null}

              {active.ticket &&
              active.ticket.type == "topic" &&
              this.logic.user.value.roles.some(
                (a) => a == "wikiedit" || a == "wikipublish"
              ) ? (
                <Button
                  positive={!active.ticket.global}
                  negative={!!active.ticket.global}
                  onClick={this.logic.togglePublishedForTicket}
                  disabled={
                    !active.ticket ||
                    (active.ticket.type == "topic" &&
                      !this.logic.user.value.roles.some(
                        (a) => a == "wikipublish"
                      )) ||
                    tickets.loading
                  }
                  name="Publicar"
                  icon="arrow left"
                  style={{ margin: 10 }}
                >
                  {active.ticket.global ? "Marcar Privado" : "Marcar Publico"}
                </Button>
              ) : null}
              {active.ticket &&
              active.ticket.type == "topic" &&
              this.logic.user.value.roles.some(
                (a) => a == "wikiedit" || a == "wikipublish"
              ) ? (
                <Button
                  negative={true}
                  onClick={this.logic.closeTicket}
                  disabled={
                    !active.ticket ||
                    (active.ticket.type == "topic" &&
                      !this.logic.user.value.roles.some(
                        (a) => a == "wikipublish"
                      )) ||
                    tickets.loading
                  }
                  name="Publicar"
                  icon="trash"
                  style={{ margin: 10 }}
                >
                  Eliminar Articulo
                </Button>
              ) : null}
              {!this.state.editting ? null : (
                <div
                  style={{
                    textAlign: "right",
                    color: "white",
                    fontWeight: "bold",
                    padding: "10px",
                  }}
                >
                  Editando mensaje #{this.state.selectedRows[0]}
                </div>
              )}
            </div>
            {active.ticket ? (
              <ConversationComponent
                previewImage={this.openImageModal}
                activities={activities}
                swapMessages={this.logic.swapMessages}
                selectedRows={this.state.selectedRows}
                editting={this.state.editting}
                flipRowSelection={(rowId) => {
                  if (this.state.editting) {
                    this.clearEditor();
                  }
                  this.setState({
                    editting: false,
                    selectedRows: this.state.selectedRows.find(
                      (a) => a == rowId
                    )
                      ? this.state.selectedRows.filter((a) => a != rowId)
                      : active.ticket.type == "topic"
                      ? [rowId]
                      : [...this.state.selectedRows, rowId],
                  });
                }}
                setEdittingSelection={(rowId) => {
                  if (rowId == "subject") {
                    this.mainEditor
                      .getEditor()
                      .clipboard.dangerouslyPasteHTML(active.ticket.subject);
                    this.setState({
                      selectedRows: ["subject"],
                      editting: true,
                    });
                    return;
                  }
                  let activity = activities.data.find((a) => a.id == rowId);
                  if (!activity) {
                    this.clearEditor();
                  }
                  // this.mainEditor.state.value = activity.body;
                  this.mainEditor
                    .getEditor()
                    .clipboard.dangerouslyPasteHTML(activity.body);
                  this.setState({
                    selectedRows: [rowId],
                    editting: true,
                  });
                }}
                type={active.ticket && active.ticket.type}
                ticket={active.ticket}
                subject={active.ticket && active.ticket.subject}
                user={user.value}
                buildUrl={buildUrl}
                closeTicket={this.closeTicketClicked}
                deleteActivity={this.logic.deleteActivity}
              />
            ) : null}
            {!active.ticket ||
            (active.ticket.type == "topic" &&
              !this.logic.user.value.roles.some(
                (a) => a == "wikiedit"
              )) ? null : (
              <div
                key="teditor-1"
                className="text-editor"
                style={{ position: "relative" }}
              >
                <Button.Group
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  <Button
                    disabled={
                      this.state.images.length > 0 &&
                      this.state.images.reduce((p, a) => {
                        return p || !a.serverId;
                      }, false)
                    }
                    onClick={() => this.onSend()}
                    color={this.state.editting ? "green" : "blue"}
                  >
                    {this.state.editting
                      ? `Confirmar Edición de ticket #${this.state.selectedRows[0]}`
                      : "Enviar"}
                  </Button>
                  {!this.state.editting ? null : (
                    <Button
                      disabled={
                        this.state.images.length > 0 &&
                        this.state.images.reduce((p, a) => {
                          return p || !a.serverId;
                        }, false)
                      }
                      onClick={() => {
                        this.setState({
                          selectedRows: [],
                          editting: false,
                        });
                      }}
                      color="red"
                    >
                      Cancelar Edición
                    </Button>
                  )}
                  {user.value &&
                  user.value.roles.some((a) => a == "sysadmin") ? (
                    <Popup
                      on="click"
                      inverted
                      trigger={<Button icon="caret up" color="blue" />}
                    >
                      <Button
                        fluid
                        onClick={() => this.onSend(true, 1, true)}
                        disabled={
                          tickets.loading ||
                          (this.state.images.length > 0 &&
                            this.state.images.reduce((p, a) => {
                              return p || !a.serverId;
                            }, false))
                        }
                        content="Enviar como Urgencia"
                        icon={"heartbeat"}
                        color={"red"}
                      />
                      <Button
                        fluid
                        onClick={() => this.onSend(true, 3, true)}
                        disabled={
                          tickets.loading ||
                          (this.state.images.length > 0 &&
                            this.state.images.reduce((p, a) => {
                              return p || !a.serverId;
                            }, false))
                        }
                        content="Enviar como Alerta"
                        icon={"warning sign"}
                        color={"yellow"}
                      />
                      <Button
                        fluid
                        onClick={() => this.onSend(true, 4, true)}
                        disabled={
                          tickets.loading ||
                          (this.state.images.length > 0 &&
                            this.state.images.reduce((p, a) => {
                              return p || !a.serverId;
                            }, false))
                        }
                        content="Enviar como Sistema"
                        icon={"bullhorn"}
                        color={"grey"}
                      />
                    </Popup>
                  ) : null}

                  <Popup
                    on="click"
                    inverted
                    trigger={<Button icon="ellipsis vertical" color="grey" />}
                  >
                    {active.ticket.type == "topic" ? null : (
                      <Button
                        disabled={tickets.loading}
                        icon={
                          active.ticket.tags.some((a) => a == "flagged")
                            ? "flag"
                            : "flag outline"
                        }
                        color="orange"
                        onClick={this.flagClicked}
                      />
                    )}
                    {user.value &&
                    user.value.roles.some((a) => a == "sysadmin") ? (
                      <Button
                        onClick={
                          active.ticket.status == "open"
                            ? this.closeTicketClicked
                            : this.logic.toggleTicketStatus
                        }
                        disabled={tickets.loading}
                        icon={
                          active.ticket.status == "open" ? "close" : "share"
                        }
                        color={active.ticket.status == "open" ? "red" : "green"}
                      />
                    ) : null}
                    {(user.value &&
                      user.value.roles.some((a) => a == "sysadmin") &&
                      active.ticket.type != "topic") ||
                    (active.ticket.type == "topic" &&
                      user.value.roles.some((a) => a == "wikiedit")) ? (
                      <Button
                        onClick={() => {
                          this.logic.stopListeningToEvents();
                          this.setState({
                            moveModal: true,
                            moveModalTo: active.ticket.GroupId,
                            pauseUpdating: true,
                          });
                        }}
                        disabled={tickets.loading}
                        icon={"folder"}
                        color={"blue"}
                      />
                    ) : null}
                    {user.value &&
                    user.value.roles.some(
                      (a) => a == "sysadmin" && active.ticket.type != "topic"
                    ) ? (
                      <Button
                        onClick={this.openMoveToTicketModal}
                        disabled={
                          tickets.loading || this.state.selectedRows.length <= 0
                        }
                        icon={"plus"}
                        color={"green"}
                      />
                    ) : null}
                  </Popup>
                </Button.Group>
                {!!this.state.newConversationMaximized ||
                !!this.state.moveToTicketModal ? null : (
                  <ReactQuill
                    id="main-editor"
                    key="rq-main-chatview"
                    formats={formats}
                    modules={this.Editor.modules}
                    ref={(ref) => {
                      //console.log("reference created");
                      this.mainEditor = ref;
                    }}
                    theme={"snow"}
                  >
                    <div
                      style={{
                        height: active.ticket.type == "topic" ? "30vh" : "10vh",
                      }}
                      key="internalreactquill"
                    />
                  </ReactQuill>
                )}
              </div>
            )}
            {!!this.state.newConversationMaximized ||
            !!this.state.moveToTicketModal ||
            !active.ticket ||
            this.state.editting ||
            (active.ticket.type == "topic" &&
              !this.logic.user.value.roles.some(
                (a) => a == "wikiedit"
              )) ? null : (
              <div
                style={{
                  backgroundColor: "#F1F0EF",
                  minHeight: "70px",
                  height: "100px",
                  paddingRight: "80px",
                  overflowY: "hidden",
                }}
              >
                <FilePond
                  ref={(ref) => {
                    this.pond = ref;
                  }}
                  allowMultiple={false}
                  maxFiles={5}
                  acceptedFileTypes={[]}
                  dropOnPage={true}
                  name="uri"
                  server="/api/file"
                  oninit={this.handleInit}
                  onprocessfile={(e) => {
                    // Set current file objects to this.state

                    this.setState({
                      images: this.pond.getFiles(),
                    });
                  }}
                  onupdatefiles={(fileItems) => {
                    // Set current file objects to this.state

                    this.setState({
                      images: fileItems,
                    });
                  }}
                >
                  {/* {this.state.images.map(file => (
                        <File key={file.file} src={file.file} origin="local" />
                    ))} */}
                </FilePond>
              </div>
            )}
          </div>
        </div>
        <Modal
          dimmer="blurring"
          size="fullscreen"
          open={this.state.preview != null}
          onClose={this.closeImageModal}
        >
          {!this.state.preview ? null : (
            <a
              onClick={(e) => {
                if (!e.ctrlKey && !e.metaKey) {
                  e.preventDefault();
                }
                this.closeImageModal();
              }}
              href={this.state.preview}
              target="_blank"
            >
              <Image fluid src={this.state.preview} />
            </a>
          )}
        </Modal>
        <Modal
          open={this.state.moveToTicketModal}
          onClose={this.closeMoveToTicketModal}
        >
          <Modal.Header>
            Mover mensajes seleccionados a un nuevo ticket
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Input
                label={"Asunto del nuevo ticket"}
                value={this.state.moveToTicketTitle}
                onChange={(e, { value }) =>
                  this.setState({ moveToTicketTitle: value })
                }
                fluid
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {`${this.state.selectedRows.length} mensajes seleccionados a mover.`}
            <Button color="red" onClick={this.closeMoveToTicketModal}>
              <Icon name="cross" /> Cancelar
            </Button>
            <Button
              color="green"
              disabled={
                this.state.selectedRows.length <= 0 ||
                this.state.moveToTicketTitle.length <= 0
              }
              onClick={() => {
                this.logic.moveActivitesToNewTicket(
                  this.state.moveToTicketTitle,
                  this.state.selectedRows,
                  active.ticket.GroupId
                );
                this.closeMoveToTicketModal();
              }}
            >
              <Icon name="folder" /> Mover
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.moveModal}
          onClose={this.closeMoveModal}
          key="moveModalForm"
        >
          <Modal.Header>Mover conversación a otro grupo</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Dropdown
                value={this.state.moveModalTo}
                allowAdditions={false}
                onChange={(_, { value }) => {
                  this.setState({
                    moveModalTo: value,
                  });
                }}
                className="square"
                defaultValue={null}
                placeholder="Seleccionar Grupo"
                fluid
                search
                key="movemodaltokey"
                selection
                options={(groups.all || [])
                  .filter(
                    (b) =>
                      b.type ==
                      (!active.ticket
                        ? ""
                        : active.ticket.type == "topic"
                        ? "topic"
                        : "regular")
                  )
                  .map((a) => ({
                    key: a.id,
                    text: getFullPathOfNames(a, groups.all),
                    value: a.id,
                  }))}
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.closeMoveModal}>
              <Icon name="cross" /> Cancelar
            </Button>
            <Button
              color="green"
              disabled={
                !active.ticket ||
                active.ticket.GroupId == this.state.moveModalTo
              }
              onClick={() => {
                this.logic.moveTicket(this.state.moveModalTo);
                this.closeMoveModal();
              }}
            >
              <Icon name="folder" /> Mover
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.pdfPromise != null}
          onClose={() => {
            this.setState({ pdfPromise: null });
          }}
        >
          <Modal.Header>Descargar PDF de articulo</Modal.Header>
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => {
                this.setState({ pdfPromise: null });
              }}
            >
              <Icon name="cross" /> Cancelar
            </Button>
            <Button
              color="green"
              loading={
                !this.state.pdfPromise || this.state.pdfPromise == "loading"
              }
              onClick={() => {
                this.setState({ pdfPromise: null });
              }}
              disabled={
                !this.state.pdfPromise ||
                this.state.pdfPromise == "loading" ||
                this.state.pdfPromise == "error"
              }
              as={"a"}
              href={this.state.pdfPromise}
              target="_blank"
            >
              <Icon name="pdf" />{" "}
              {this.state.pdfPromise == "loading"
                ? "Preparando Descarga"
                : !this.state.pdfPromise || this.state.pdfPromise == "error"
                ? "No se pudo generar descarga"
                : "Descargar PDF"}
            </Button>
          </Modal.Actions>
        </Modal>
        {this.props.match.path.indexOf("wiki") != -1 &&
        (this.logic.user.value.roles.find((a) => a == "wikiedit") ==
          undefined ||
          !active.group) ? null : (
          <NewConversationsWindow
            className={active.ticket ? "noShowMobile" : "showMobile"}
            groups={
              this.props.match.path.indexOf("wiki") != -1
                ? [active.group]
                : groups.all
            }
            createTicket={this.logic.createTicket}
            isMaximized={(isMaximized) =>
              this.setState({ newConversationMaximized: isMaximized })
            }
            config={this.state.createTicket}
          />
        )}
      </Container>
    );
  }
}

const StyledView = Radium(ChatView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  ChatView.mapStateToProps,
  ChatView.mapDispatchToProps
)(i18nEnhancement);
