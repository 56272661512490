import React from 'react';
import { Form } from 'semantic-ui-react';

const roles = [
  {
    key: 0,
    text: 'sysadmin',
    value: 'sysadmin'
  },
  {
    key: 1,
    text: 'agent',
    value: 'agent'
  },
  {
    key: 2,
    text: 'client',
    value: 'client'
  }
];

const UsersForm = ({ inputHandler, loading, message, submit, values }) => (
  <Form onSubmit={submit} loading={loading}>
    <Form.Group>
      <Form.Input
        name="email" label="Email"
        value={values.email} onChange={inputHandler}
        width={5} />
      <Form.Input
        name="username" label="Username"
        value={values.username} onChange={inputHandler}
        width={5} />
      <Form.Input
        name="fullName" label="Nombre"
        value={values.fullName} onChange={inputHandler}
        width={6} />
    </Form.Group>
    <Form.Group>
      <Form.Input
        name="password" label="Clave"
        value={values.password} onChange={inputHandler}
        type="password"
        width={8} />
      <Form.Select
        name="roles" label="Roles"
        value={values.roles} onChange={inputHandler}
        options={roles} search multiple
        width={8} />
    </Form.Group>
  </Form>
);

export default UsersForm;