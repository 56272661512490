import Color from 'color';

const base = Color('#F87518');
export default {
  base: base,
  background: '#1B1C1D',
  primaryBg: base.darken(0.87).hex(),
  primary: base.hex(),
  secondary: base.darken(0.87).hex(),
  highlight: base.lighten(0.2).hex(),
  bright: base.lighten(0.9).hex(),
  warningBg: Color('#191200').hex(),
  warning: Color('#FEAC00').hex(),
  dangerBg: Color('red').darken(0.8).hex(),
  danger: Color('red').hex(),
  error: Color('red').hex(),
  importantBg: Color('#459DFF').darken(0.7).hex(),
  important: Color('#459DFF').lighten(0.2).hex()
};