import update from "immutability-helper";
import Radium, { Style } from "radium";
import React, { Component } from "react";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import ReactTable from "react-table";
import {
  Breadcrumb,
  Button,
  Container,
  Divider,
  Form,
  Header,
  Icon,
  Label,
  Message,
  Modal,
  Segment
} from "semantic-ui-react";
import * as Routes from "../../../../../routes";

const NOTIFICATION_EVENTS = {
  ANY_EVENT: "anyTicketEvent",
  OWN_EVENT: "ownTicketEvent",
  PRIORITY_EVENT: "priorityTicketEvent"
};

function cloneObject(obj) {
  var clone = {};
  for (var i in obj) {
    if (obj[i] != null && typeof obj[i] == "object") {
      clone[i] = cloneObject(obj[i]);
    } else {
      clone[i] = obj[i];
    }
  }
  return clone;
}

const INITIAL_STATE = {
  users: {
    loading: false,
    data: [],
    total: 0,
    error: null,
    $skip: 0,
    $limit: 9999
  },
  groups: {
    loading: false,
    data: [],
    total: 0,
    error: null,
    $skip: 0,
    $limit: 9999
  },
  collections: {
    loading: false,
    data: [],
    total: 0,
    error: null,
    $skip: 0,
    $limit: 9999
  },
  subscriptions: {
    loading: false,
    data: [],
    total: 0,
    error: null,
    $skip: 0,
    $limit: 9999
  },
  upsertModal: {
    open: false,
    loading: false,
    error: null,
    newRuleName: "",
    values: {
      id: null,
      GroupId: "",
      UserId: "",
      SubscriptionCollectionId: 16,
      permissions: [],
      notifications: {
        email: [],
        internal: [],
        telegram: [],
        sms: [],
        push: []
      },
      metadata: { requireAttachment: false, rules: {} }
    }
  },
  removeModal: {
    open: false,
    loading: false,
    error: null,
    id: null
  }
};

class AdminSubscriptionsView extends Component {
  constructor(props) {
    super(props);

    const { feathers } = this.props.services;

    this.state = INITIAL_STATE;
    this.UserService = feathers.service("api/users");
    this.GroupService = feathers.service("api/groups");
    this.SubscriptionService = feathers.service("api/subscriptions");
    this.SubscriptionCollectionsService = feathers.service(
      "api/subscription-collections"
    );
  }

  findUsers = () => {
    let query = {
      $skip: 0,
      $limit: 9999
    };

    this.setState(
      update(this.state, {
        users: {
          $merge: {
            loading: true,
            data: [],
            total: 0,
            error: null
          }
        }
      })
    );

    this.UserService.find({ query })
      .then(response => {
        this.setState(
          update(this.state, {
            users: {
              $merge: {
                loading: false,
                data: response.data,
                total: response.total,
                $limit: response.limit,
                $skip: response.skip
              }
            }
          })
        );
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            users: {
              $merge: {
                loading: false,
                error: error
              }
            }
          })
        );
      });
  };

  findCollections = () => {
    let query = {
      $skip: 0,
      $limit: 9999
    };

    this.setState(
      update(this.state, {
        collections: {
          $merge: {
            loading: true,
            data: [],
            total: 0,
            error: null
          }
        }
      })
    );

    this.SubscriptionCollectionsService.find({ query })
      .then(response => {
        this.setState(
          update(this.state, {
            collections: {
              $merge: {
                loading: false,
                data: response.data,
                total: response.total,
                $limit: response.limit,
                $skip: response.skip
              }
            }
          })
        );
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            collections: {
              $merge: {
                loading: false,
                error: error
              }
            }
          })
        );
      });
  };

  findGroups = () => {
    let query = {
      $skip: 0,
      $limit: 9999
    };

    this.setState(
      update(this.state, {
        groups: {
          $merge: {
            loading: true,
            data: [],
            total: 0,
            error: null
          }
        }
      })
    );

    this.GroupService.find({ query })
      .then(response => {
        this.setState(
          update(this.state, {
            groups: {
              $merge: {
                loading: false,
                data: response.data,
                total: response.total,
                $limit: response.limit,
                $skip: response.skip
              }
            }
          })
        );
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            groups: {
              $merge: {
                loading: false,
                error: error
              }
            }
          })
        );
      });
  };

  findSubscriptions = () => {
    let { params } = this.props.match;
    let query = {
      $skip: 0,
      $limit: 9999,
      $or: [{ exclusiveType: null }, { exclusiveType: "ticket" }],
      GroupId:
        params.source == "group" ? parseInt(params.id) || undefined : undefined,
      UserId:
        params.source == "user" ? parseInt(params.id) || undefined : undefined
    };

    this.setState(
      update(this.state, {
        subscriptions: {
          $merge: {
            loading: true,
            data: [],
            total: 0,
            error: null
          }
        }
      })
    );

    this.SubscriptionService.find({ query })
      .then(response => {
        this.setState(
          update(this.state, {
            subscriptions: {
              $merge: {
                loading: false,
                data: response.data,
                total: response.total,
                $limit: response.limit,
                $skip: response.skip
              }
            }
          })
        );
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            subscriptions: {
              $merge: {
                loading: false,
                error: error
              }
            }
          })
        );
      });
  };

  openUpsertModal = (e, input, subscription = null) => {
    if (subscription) {
      this.setState(
        update(this.state, {
          upsertModal: {
            $merge: {
              open: true,
              values: {
                id: subscription.id,
                GroupId: subscription.GroupId,
                UserId: subscription.UserId,
                permissions: subscription.permissions,
                notifications: subscription.notifications,
                metadata: subscription.metadata,
                SubscriptionCollectionId: subscription.SubscriptionCollectionId
              }
            }
          }
        })
      );
    } else {
      this.setState(
        update(this.state, {
          upsertModal: {
            $merge: {
              open: true
            }
          }
        })
      );
    }
  };

  dissmissUpsertModal = e => {
    let go = e == true;
    if (!go) {
      go = window.confirm(
        "Todavia no has confirmado los cambios. Estás seguro que quieres salir?"
      );
    }
    if (!go) {
      return;
    }
    this.setState(
      update(this.state, {
        upsertModal: {
          $set: INITIAL_STATE.upsertModal
        }
      })
    );
  };

  inputHandler = (e, input) => {
    this.setState(
      update(this.state, {
        upsertModal: {
          values: {
            $merge: {
              [input.name]: input.value
            }
          }
        }
      })
    );
  };

  openRemoveModal = (e, input, id) => {
    if (id) {
      this.setState(
        update(this.state, {
          removeModal: {
            $merge: {
              id,
              open: true
            }
          }
        })
      );
    }
  };

  dismissRemoveModal = e => {
    this.setState(
      update(this.state, {
        removeModal: {
          $set: INITIAL_STATE.removeModal
        }
      })
    );
  };

  destroySubscription = e => {
    let { id } = this.state.removeModal;

    this.setState(
      update(this.state, {
        removeModal: {
          $merge: {
            loading: true,
            error: null
          }
        }
      })
    );

    this.SubscriptionService.remove(id)
      .then(response => {
        this.dismissRemoveModal();
        this.findSubscriptions();
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            removeModal: {
              $merge: {
                loading: false,
                error
              }
            }
          })
        );
      });
  };

  submit = e => {
    e.preventDefault();

    let {
      upsertModal: { values }
    } = this.state;

    this.setState(
      update(this.state, {
        upsertModal: {
          $merge: {
            loading: true
          }
        }
      })
    );

    if (values.id) {
      this.SubscriptionService.patch(values.id, {
        GroupId: values.GroupId && values.GroupId != "" ? values.GroupId : null,
        UserId: values.UserId,
        permissions: values.permissions,
        notifications: values.notifications,
        metadata: values.metadata,
        SubscriptionCollectionId:
          values.SubscriptionCollectionId &&
          values.SubscriptionCollectionId != ""
            ? values.SubscriptionCollectionId
            : null
      })
        .then(response => {
          this.dissmissUpsertModal(true);
          this.findSubscriptions();
        })
        .catch(error => {
          this.setState(
            update(this.state, {
              upsertModal: {
                $merge: {
                  loading: false,
                  error: error
                }
              }
            })
          );
        });
    } else {
      this.SubscriptionService.create({
        GroupId: values.GroupId && values.GroupId != "" ? values.GroupId : null,
        UserId: values.UserId,
        permissions: values.permissions,
        notifications: values.notifications,
        metadata: values.metadata,
        SubscriptionCollectionId:
          values.SubscriptionCollectionId &&
          values.SubscriptionCollectionId != ""
            ? values.SubscriptionCollectionId
            : null
      })
        .then(response => {
          this.dissmissUpsertModal(true);
          this.findSubscriptions();
        })
        .catch(error => {
          this.setState(
            update(this.state, {
              upsertModal: {
                $merge: {
                  loading: false,
                  error: error
                }
              }
            })
          );
        });
    }
  };

  componentDidMount() {
    this.findUsers();
    this.findGroups();
    this.findCollections();
    this.findSubscriptions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id != this.props.match.params.id) {
      this.findSubscriptions();
    }
  }

  static mapStateToProps(state) {
    return {
      authentication: state.authentication
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path))
    };
  }

  render() {
    const { t, authentication } = this.props;
    const {
      users,
      groups,
      subscriptions,
      collections,
      upsertModal,
      removeModal
    } = this.state;

    const hasNotification = (notificationChannel, notificationType) => {
      let notificationsForType =
        upsertModal.values.notifications[notificationChannel];
      if (!notificationsForType || !Array.isArray(notificationsForType)) {
        return false;
      }
      if (notificationsForType.length <= 0) {
        return false;
      }
      return notificationsForType.indexOf(notificationType) != -1;
    };

    const hasPermission = permissionName => {
      return upsertModal.values.permissions.indexOf(permissionName) != -1;
    };

    const hasRoleDefinition = (roleDefinition, exactMatch = false) => {
      if (!!exactMatch) {
        if (roleDefinition.length !== upsertModal.values.permissions.length) {
          return false;
        }
      }
      let response = true;
      roleDefinition.forEach(element => {
        if (upsertModal.values.permissions.indexOf(element) === -1) {
          response = false;
        }
      });
      return response;
    };

    const flipNotification = (channel, notificationType) => () => {
      let notificationsForChannel = upsertModal.values.notifications[channel];
      if (!notificationsForChannel || !Array.isArray(notificationsForChannel)) {
        return;
      }
      if (notificationsForChannel.indexOf(notificationType) != -1) {
        notificationsForChannel = notificationsForChannel.filter(
          e => e != notificationType
        );
      } else {
        notificationsForChannel = [
          ...notificationsForChannel,
          notificationType
        ];
      }
      this.setState(
        update(this.state, {
          upsertModal: {
            values: {
              notifications: {
                $merge: {
                  [channel]: notificationsForChannel
                }
              }
            }
          }
        })
      );
    };

    const changePermissions = (
      set,
      unset,
      setExact = false,
      notifications = undefined
    ) => {
      let newPermissions = [...upsertModal.values.permissions];
      if (!!setExact) {
        newPermissions = [];
      }
      if (!!set) {
        if (set.constructor === Array) {
          newPermissions = [...newPermissions, ...set];
        } else {
          newPermissions = [...newPermissions, set];
        }
      }
      if (!!unset) {
        if (unset.constructor === Array) {
          newPermissions = newPermissions.filter(
            aPermission => unset.indexOf(aPermission) === -1
          );
        } else {
          newPermissions = newPermissions.filter(
            aPermission => aPermission != unset
          );
        }
      }
      this.setState(
        update(this.state, {
          upsertModal: {
            values: {
              $merge: {
                permissions: newPermissions,
                notifications: notifications
                  ? notifications
                  : { ...this.state.upsertModal.values.notifications }
              }
            }
          }
        })
      );
    };

    const setNotifications = set => {
      //console.log("set", set);
      this.setState(
        update(this.state, {
          upsertModal: {
            values: {
              $merge: {
                notifications: { ...set }
              }
            }
          }
        })
      );
    };

    const switchPermissions = aPermission => {
      if (upsertModal.values.permissions.indexOf(aPermission) != -1) {
        changePermissions(undefined, aPermission);
      } else {
        changePermissions(aPermission, undefined);
      }
    };

    const changedRuleName = (e, data) => {
      this.setState(
        update(this.state, {
          upsertModal: {
            $merge: {
              newRuleName: data.value
            }
          }
        })
      );
    };

    const flipAttachmentRequirement = (e, data) => {
      this.setState(
        update(this.state, {
          upsertModal: {
            values: {
              metadata: {
                $merge: {
                  requireAttachment:
                    data.value != undefined ? data.value : data.checked
                }
              }
            }
          }
        })
      );
    };

    const addRule = e => {
      e.preventDefault();
      let ruleName = this.state.upsertModal.newRuleName;
      let aRule = { label: "New Rule", options: [], required: false };
      let newState = update(this.state, {
        upsertModal: {
          values: {
            metadata: {
              rules: {
                $merge: {
                  [ruleName]: { ...aRule }
                }
              }
            }
          }
        }
      });

      newState = update(newState, {
        upsertModal: {
          $merge: {
            newRuleName: ""
          }
        }
      });

      this.setState(newState);
    };

    const user = authentication.get("user").toJS();
    const { roles } = user || { roles: [] };
    const isAdmin = roles.indexOf("sysadmin") != -1;
    //console.log("USERS", users);
    const userOptions = users.data.map(u => ({
      key: u.id,
      text: `${u.fullName} (${u.username})`,
      value: u.id
    }));
    return (
      <Container style={{ padding: 12, overflowY: "scroll" }}>
        <Style
          rules={{
            ".edit-icon-hover": {
              cursor: "pointer"
            },
            ".edit-icon-hover:hover": {
              color: "blue"
            },
            ".trash-icon-hover": {
              cursor: "pointer",
              color: "red"
            },
            ".trash-icon-hover:hover": {
              color: "orange"
            }
          }}
        />

        <Breadcrumb>
          <Breadcrumb.Section as={Link} to={Routes.DASHBOARD}>
            Principal
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section as={Link} link to={Routes.ADMIN}>
            Administracion
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section as={Link} link to={Routes.ADMIN_RESOURCES}>
            Recursos
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Suscripciones</Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Header dividing size="large" style={{ marginTop: 0 }}>
          <Icon name="feed" />
          <Header.Content>
            ADMINISTRACION DE SUSCRIPCIONES
            <Header.Subheader>
              Alta, Baja y Modificacion de Suscripciones
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Segment clearing>
          <Button
            disabled={subscriptions.loading}
            content="Refrescar"
            icon="refresh"
            positive
            onClick={this.findSubscriptions}
          />
          {!this.props.match.params.id ? null : (
            <Label as={Link} to={Routes.ADMIN_RESOURCES_SUBSCRIPTIONS}>
              {this.props.match.params.source == "group" ? "Grupo" : "Usuario"}{" "}
              {!!subscriptions.loading || subscriptions.data.length <= 0
                ? `#${this.props.match.params.id}`
                : this.props.match.params.source == "group"
                ? subscriptions.data[0].Group.name
                : subscriptions.data[0].User.name}
              <Icon name="delete" />
            </Label>
          )}

          <Button
            disabled={subscriptions.loading}
            content="Nuevo"
            icon="add"
            floated="right"
            primary
            onClick={this.openUpsertModal}
          />
        </Segment>
        {subscriptions.error && (
          <Message negative>
            <Message.Header>{subscriptions.error.name}</Message.Header>
            <p>{subscriptions.error.message}</p>
          </Message>
        )}
        <ReactTable
          className="-striped -highlight"
          loading={subscriptions.loading}
          data={subscriptions.data}
          defaultFilterMethod={(filter, row) => {
            return (
              `${row[filter.id]}`
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) != -1
            );
          }}
          filterable
          defaultSorted={[
            {
              id: "group",
              desc: false
            },
            {
              id: "user",
              desc: false
            }
          ]}
          defaultPageSize={10}
          showPageSizeOptions={false}
          columns={[
            {
              Header: "",
              filterable: false,
              width: 32,
              Cell: row => (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "center"
                  }}
                >
                  <Icon
                    className="edit-icon-hover"
                    name="edit"
                    onClick={e => this.openUpsertModal(e, null, row.original)}
                  />
                </div>
              ),
              sortable: false,
              resizable: false
            },
            {
              Header: "ID",
              accessor: "id",
              width: 32
            },
            {
              Header: "Grupo",
              id: "group",
              accessor: d => d.Group.name
            },
            {
              Header: "Usuario",
              id: "user",
              accessor: d => d.User.fullName
            },
            {
              Header: "Colección",
              id: "SubscriptionCollectionId",
              accessor: d =>
                !d.SubscriptionCollectionId
                  ? ""
                  : (
                      collections.data.find(
                        a => a.id == d.SubscriptionCollectionId
                      ) || {
                        name: `#${d.SubscriptionCollectionId} - CARGANDO`
                      }
                    ).name
            },
            {
              Header: "",
              width: 32,
              Cell: row => (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "center"
                  }}
                >
                  <Icon
                    color="red"
                    className="trash-icon-hover"
                    name="trash"
                    onClick={e =>
                      this.openRemoveModal(e, null, row.original.id)
                    }
                  />
                </div>
              ),
              sortable: false,
              resizable: false
            }
          ]}
          previousText={"<<"}
          nextText={">>"}
          loadingText={"Cargando..."}
          noDataText={"Sin registros"}
          pageText={"Pagina"}
          ofText={"de"}
          rowsText={"filas"}
        />
        <Modal open={upsertModal.open} onClose={this.dissmissUpsertModal}>
          <Modal.Header>Suscripcion</Modal.Header>
          <Modal.Content>
            <Form loading={upsertModal.loading} onSubmit={this.submit}>
              <Form.Group widths="equal">
                <Form.Select
                  label="Usuario"
                  name="UserId"
                  loading={users.loading}
                  options={userOptions}
                  search
                  onChange={this.inputHandler}
                  value={upsertModal.values.UserId}
                />
                <Form.Select
                  label="Grupo"
                  name="GroupId"
                  search
                  loading={groups.loading}
                  options={groups.data.map(u => ({
                    key: u.id,
                    text: u.name,
                    value: u.id
                  }))}
                  onChange={this.inputHandler}
                  value={upsertModal.values.GroupId}
                />
                <Form.Select
                  label="Colección de Subscripciones"
                  name={"SubscriptionCollectionId"}
                  search
                  loading={collections.loading}
                  options={collections.data.map(u => ({
                    key: u.id,
                    text: u.name,
                    value: u.id
                  }))}
                  onChange={this.inputHandler}
                  value={upsertModal.values.SubscriptionCollectionId}
                />
              </Form.Group>
              <Form.Group>
                <Label>Permisos predefinidos</Label>
                <Form.Radio
                  disabled={!isAdmin}
                  toggle
                  label="Agente"
                  checked={hasRoleDefinition(
                    ["writeAll", "readAll", "agentRole", "create"],
                    true
                  )}
                  onChange={() =>
                    changePermissions(
                      ["writeAll", "readAll", "agentRole", "create"],
                      null,
                      true,
                      {
                        sms: [],
                        push: [],
                        email: [],
                        internal: [
                          "anyTicketEvent",
                          "ownTicketEvent",
                          "priorityTicketEvent"
                        ],
                        telegram: []
                      }
                    )
                  }
                />
                <Form.Radio
                  toggle
                  label="Supervisor"
                  checked={hasRoleDefinition(
                    ["readAll", "clientRole", "writeAll", "create"],
                    true
                  )}
                  onChange={() =>
                    changePermissions(
                      ["readAll", "writeAll", "clientRole", "create"],
                      null,
                      true,
                      {
                        sms: [],
                        push: [
                          "anyTicketEvent",
                          "ownTicketEvent",
                          "priorityTicketEvent"
                        ],
                        email: [
                          "anyTicketEvent",
                          "ownTicketEvent",
                          "priorityTicketEvent"
                        ],
                        internal: [
                          "anyTicketEvent",
                          "ownTicketEvent",
                          "priorityTicketEvent"
                        ],
                        telegram: []
                      }
                    )
                  }
                />
                <Form.Radio
                  toggle
                  label="Cliente"
                  checked={hasRoleDefinition(
                    ["create", "readOwn", "writeOwn", "clientRole"],
                    true
                  )}
                  onChange={() => {
                    changePermissions(
                      ["create", "readOwn", "writeOwn", "clientRole"],
                      null,
                      true,
                      {
                        sms: [],
                        push: ["ownTicketEvent"],
                        email: ["ownTicketEvent"],
                        internal: ["ownTicketEvent"],
                        telegram: []
                      }
                    );
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Label>Tipo de Usuario</Label>
                <Form.Radio
                  label="Usuario Agente"
                  checked={hasPermission("agentRole")}
                  onChange={() => changePermissions("agentRole", "clientRole")}
                />
                <Form.Radio
                  label="Usuario Cliente"
                  checked={hasPermission("clientRole")}
                  onChange={() => changePermissions("clientRole", "agentRole")}
                />
              </Form.Group>
              <Form.Group>
                <Label>Permisos de creación</Label>
                <Form.Checkbox
                  label="Crear tickets"
                  checked={hasPermission("create")}
                  onChange={() => switchPermissions("create")}
                />
              </Form.Group>
              <Form.Group>
                <Label>Permisos de escritura</Label>
                <Form.Radio
                  label="Escribir en tickets propios"
                  checked={hasPermission("writeOwn")}
                  onChange={() => changePermissions("writeOwn", "writeAll")}
                />
                <Form.Radio
                  disabled={!isAdmin}
                  label="Escribir en tickets del grupo"
                  checked={hasPermission("writeAll")}
                  onChange={() => changePermissions("writeAll", "writeOwn")}
                />
                <Form.Radio
                  label="No puede escribir"
                  checked={
                    !hasPermission("writeOwn") && !hasPermission("writeAll")
                  }
                  onChange={() =>
                    changePermissions(null, ["writeOwn", "writeAll"])
                  }
                />
              </Form.Group>
              <Form.Group>
                <Label>Permisos de lectura</Label>
                <Form.Radio
                  label="Ver todos los tickets del grupo"
                  checked={hasPermission("readAll")}
                  onChange={() => changePermissions("readAll", "readOwn")}
                />
                <Form.Radio
                  label="Ver los tickets propios"
                  checked={hasPermission("readOwn")}
                  onChange={() => changePermissions("readOwn", "readAll")}
                />
                <Form.Radio
                  label="No ve tickets"
                  checked={
                    !hasPermission("readAll") && !hasPermission("readOwn")
                  }
                  onChange={() =>
                    changePermissions(null, ["readAll", "readOwn"])
                  }
                />
              </Form.Group>
              <Form.Group>
                <Label>Notificaciones por actividad en cualquier ticket</Label>
                {Object.keys(upsertModal.values.notifications).map(aChannel => {
                  return (
                    <Form.Checkbox
                      label={aChannel.toUpperCase()}
                      onChange={flipNotification(
                        aChannel,
                        NOTIFICATION_EVENTS.ANY_EVENT
                      )}
                      key={`${aChannel}anyEvent`}
                      checked={hasNotification(
                        aChannel,
                        NOTIFICATION_EVENTS.ANY_EVENT
                      )}
                    />
                  );
                })}
              </Form.Group>
              <Form.Group>
                <Label>Notificaciones por actividad en tickets propios</Label>
                {Object.keys(upsertModal.values.notifications).map(aChannel => {
                  return (
                    <Form.Checkbox
                      label={aChannel.toUpperCase()}
                      key={`${aChannel}ownEvent`}
                      onChange={flipNotification(
                        aChannel,
                        NOTIFICATION_EVENTS.OWN_EVENT
                      )}
                      checked={hasNotification(
                        aChannel,
                        NOTIFICATION_EVENTS.OWN_EVENT
                      )}
                    />
                  );
                })}
              </Form.Group>
              <Form.Group>
                <Label>
                  Notificaciones por actividad en tickets prioritarios
                </Label>
                {Object.keys(upsertModal.values.notifications).map(aChannel => {
                  return (
                    <Form.Checkbox
                      label={aChannel.toUpperCase()}
                      onChange={flipNotification(
                        aChannel,
                        NOTIFICATION_EVENTS.PRIORITY_EVENT
                      )}
                      key={`${aChannel}priorityEvent`}
                      checked={hasNotification(
                        aChannel,
                        NOTIFICATION_EVENTS.PRIORITY_EVENT
                      )}
                    />
                  );
                })}
              </Form.Group>
              <Form.Group>
                <Form.Checkbox
                  label={`Requerir Adjuntos`}
                  checked={upsertModal.values.metadata.requireAttachment}
                  onChange={flipAttachmentRequirement}
                />
              </Form.Group>
              <Form.Group inline>
                <Label>Reglas Adicionales</Label>
                <Form.Input
                  label={`Nueva Regla`}
                  value={upsertModal.newRuleName}
                  onChange={changedRuleName}
                />
                <Button
                  icon="add"
                  onClick={addRule}
                  disabled={
                    !upsertModal.newRuleName ||
                    upsertModal.newRuleName.length <= 0 ||
                    upsertModal.values.metadata.rules[
                      upsertModal.newRuleName
                    ] != undefined
                  }
                />
              </Form.Group>
              {Object.keys(
                !!upsertModal.values.metadata
                  ? upsertModal.values.metadata.rules
                  : {}
              ).map(aRuleName => {
                let aRule = upsertModal.values.metadata.rules[aRuleName];
                let { options, label, required } = aRule;
                if (options.length > 0) {
                }

                const updateField = fieldName => (e, data) => {
                  this.setState(
                    update(this.state, {
                      upsertModal: {
                        values: {
                          metadata: {
                            rules: {
                              [aRuleName]: {
                                $merge: {
                                  [fieldName]:
                                    data.value != undefined
                                      ? data.value
                                      : data.checked
                                }
                              }
                            }
                          }
                        }
                      }
                    })
                  );
                };

                const removeRule = e => {
                  e.preventDefault();
                  let newRules = { ...upsertModal.values.metadata.rules };
                  delete newRules[aRuleName];
                  this.setState(
                    update(this.state, {
                      upsertModal: {
                        values: {
                          metadata: {
                            $merge: {
                              rules: newRules
                            }
                          }
                        }
                      }
                    })
                  );
                };

                return (
                  <Form.Group inline key={aRuleName}>
                    <Label style={{ marginRight: 10 }}>{aRuleName}</Label>
                    <Form.Input
                      label={`Etiqueta`}
                      value={label}
                      onChange={updateField("label")}
                    />
                    <Form.Checkbox
                      label={`${aRuleName} Required`}
                      checked={required}
                      onChange={updateField("required")}
                    />
                    <Form.Button icon="remove" onClick={removeRule} />
                  </Form.Group>
                );
              })}
            </Form>
            {upsertModal.error && (
              <Message negative>
                <Message.Header>{upsertModal.error.name}</Message.Header>
                <p>{upsertModal.error.message}</p>
              </Message>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={upsertModal.loading}
              content="Cancelar"
              icon="remove"
              basic
              negative
              onClick={this.dissmissUpsertModal}
            />
            <Button
              disabled={upsertModal.loading}
              content="Aceptar"
              icon="checkmark"
              positive
              onClick={this.submit}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          basic
          size="small"
          open={removeModal.open}
          onClose={this.dismissRemoveModal}
        >
          <Header icon="trash" content="Eliminar Suscripcion" />
          <Modal.Content>
            <p>Seguro desea eliminar la suscripcion?</p>
            {removeModal.error && (
              <Message negative>
                <Message.Header>{removeModal.error.name}</Message.Header>
                <p>{removeModal.error.message}</p>
              </Message>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={removeModal.loading}
              basic
              color="red"
              inverted
              onClick={this.dismissRemoveModal}
            >
              <Icon name="remove" /> No
            </Button>
            <Button
              disabled={removeModal.loading}
              color="green"
              inverted
              onClick={this.destroySubscription}
            >
              <Icon name="checkmark" /> Si
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

const StyledView = Radium(AdminSubscriptionsView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  AdminSubscriptionsView.mapStateToProps,
  AdminSubscriptionsView.mapDispatchToProps
)(i18nEnhancement);
