import { Creators as subscriptions } from './subscription';
import { Creators as group } from './group';
import { Creators as users } from './users';
import { Creators as tickets } from './ticket';
export default {
  subscriptions,
  group,
  tickets,
  users
};
