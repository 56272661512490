import React, { Component } from "react";
import {
  Container, Grid
} from "semantic-ui-react";
import Radium from "radium";

const styles = {};

class FilteredCollection extends Component {
  constructor (props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount () {

  }

  render () {
    const {
      breadcrumb, containerProps, header, headerMobile,
      filter, collectionView, stats, menu,
      secondaryMenu, style, selfContained
    } = this.props;

    const component = (
      <Grid stackable>
        <Grid.Row only="computer tablet">
          <Grid.Column width={16}>
            {breadcrumb}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="computer tablet">
          <Grid.Column width={16}>
            {header}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="mobile">
          <Grid.Column width={16}>
            {headerMobile}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            {filter}
            {secondaryMenu || null}
          </Grid.Column>
          <Grid.Column width={12}>
            {menu || null}
            {stats || null}
            {collectionView}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    if (selfContained) {
      return (
        <Container {...containerProps} style={style}>
          {component}
        </Container>
      );
    } else {
      return component;
    }
  }
}

FilteredCollection.defaultProps = {
  selfContained: true
};

export default Radium(FilteredCollection);