import React, { Component } from "react";
import {
  Accordion,
  Breadcrumb,
  Button,
  Container,
  Form,
  Header,
  Icon,
  Grid,
  Menu,
  Item,
  Label,
  Segment,
  List,
  Loader
} from "semantic-ui-react";
import { translate } from "react-i18next";
import Radium from "radium";
import { connect } from "react-redux";
import { range } from "lodash";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import moment from "moment";
import update from "immutability-helper";

class TicketQueryView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        // open: true,
        groups: [],
        priority: null,
        tags: [],
        status: null,
        since: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        until: moment().format("YYYY-MM-DD")
      },
      groups: {
        loading: false,
        data: [],
        error: null
      },
      tags: {
        loading: false,
        data: [],
        error: null
      },
      tickets: {
        loading: false,
        data: [],
        error: null
      }
    };

    this.GroupService = this.props.services.feathers.service("api/groups");
    this.TicketService = this.props.services.feathers.service("api/tickets");
  }

  toggleFilters = () => {
    return; // disabled for now

    /*
    this.setState(update(this.state, {
      filters: {
        $merge: {
          open: !this.state.filters.open
        }
      }
    }));
    */
  };

  filterInputHandler = (e, input) => {
    let name = e.target.name;
    let value = e.target.value;

    //console.log(input);

    if (input) {
      if (input.name) {
        name = input.name;
      }

      if (input.value) {
        value = input.value;
      }
    }

    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value
      }
    });
  };

  filterTagsOnAddItem = (e, { value }) => {
    this.setState({
      tags: {
        ...this.state.tags,
        data: [{ text: value, value }, ...this.state.tags.data]
      }
    });
  };

  submitQuery = e => {
    e.preventDefault();

    let { filters } = this.state;

    //console.log(filters);

    this.findTickets();
  };

  findGroups = () => {
    this.setState(
      {
        groups: {
          ...this.state.groups,
          loading: true,
          error: null
        }
      },
      () => {
        this.GroupService.find({
          query: {
            type: "regular"
          }
        })
          .then(response => {
            //console.log(response);
            this.setState({
              groups: {
                ...this.state.groups,
                loading: false,
                data: response.data
              }
            });
          })
          .catch(error => {
            this.setState({
              groups: {
                ...this.state.groups,
                loading: false,
                error
              }
            });
          });
      }
    );
  };

  findTickets = () => {
    let { filters } = this.state;

    let query = {};

    if (filters.groups.length) {
      query.GroupId = {
        $in: filters.groups
      };
    }

    if (filters.priority) {
      query.priority = filters.priority;
    }

    if (filters.status) {
      query.status = filters.status;
    }

    if (filters.tags.length) {
      query.tags = {
        $contains: filters.tags
      };
    }

    if (filters.since && filters.until) {
      query.createdAt = {
        $between: [filters.since, filters.until]
      };
    }

    this.setState(
      {
        tickets: {
          ...this.state.tickets,
          loading: true,
          error: null
        }
      },
      () => {
        this.TicketService.find({ query })
          .then(response => {
            //console.log(response);
            this.setState({
              tickets: {
                ...this.state.tickets,
                loading: false,
                data: response.data
              }
            });
          })
          .catch(error => {
            this.setState({
              tickets: {
                ...this.state.tickets,
                loading: false,
                error
              }
            });
          });
      }
    );
  };

  mapGroupsToOptions = groups => {
    return groups.map((group, index) => ({
      key: index,
      text: group.name,
      value: group.id
    }));
  };

  componentDidMount() {
    this.findGroups();
  }

  static mapStateToProps(state) {
    return {
      routing: state.routing,
      authentication: state.authentication,
      tickets: state.tickets.toJS()
    };
  }

  render() {
    const { t } = this.props;
    const { filters, groups, tags, tickets } = this.state;

    return (
      <Container>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <Breadcrumb>
                <Breadcrumb.Section link>Principal</Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section link>Busqueda</Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section active>Tickets</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header>Busqueda de Tickets</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment clearing>
                <Form size="small">
                  <Form.Group>
                    <Form.Select
                      loading={groups.loading}
                      name="groups"
                      label="Grupo"
                      options={this.mapGroupsToOptions(groups.data)}
                      multiple
                      search
                      width={3}
                      value={filters.groups}
                      onChange={this.filterInputHandler}
                    />
                    <Form.Field width={3}>
                      <label htmlFor="">Prioridad</label>
                      <Button.Group icon>
                        <Button
                          active={!filters.priority}
                          name="priority"
                          icon="circle"
                          value={null}
                          onClick={this.filterInputHandler}
                        />
                        <Button
                          active={filters.priority == 0}
                          name="priority"
                          icon="angle up"
                          value={0}
                          onClick={this.filterInputHandler}
                        />
                        <Button
                          active={filters.priority == 1}
                          name="priority"
                          icon="angle double up"
                          value={1}
                          onClick={this.filterInputHandler}
                        />
                      </Button.Group>
                    </Form.Field>
                    <Form.Field width={3}>
                      <label htmlFor="">Estado</label>
                      <Button.Group icon>
                        <Button
                          active={!filters.status}
                          name="status"
                          value={null}
                          icon="circle"
                          onClick={this.filterInputHandler}
                        />
                        <Button
                          active={filters.status === "open"}
                          name="status"
                          value={"open"}
                          icon="lock"
                          onClick={this.filterInputHandler}
                        />
                        <Button
                          active={filters.status === "closed"}
                          name="status"
                          value={"closed"}
                          icon="unlock"
                          onClick={this.filterInputHandler}
                        />
                      </Button.Group>
                    </Form.Field>
                    <Form.Select
                      name="tags"
                      label="Tags"
                      options={tags.data}
                      width={3}
                      search
                      multiple
                      value={filters.tags}
                      onChange={this.filterInputHandler}
                      allowAdditions
                      onAddItem={this.filterTagsOnAddItem}
                    />
                    <Form.Input
                      name="since"
                      label="Desde"
                      type="date"
                      width={3}
                      value={filters.since}
                      onChange={this.filterInputHandler}
                    />
                    <Form.Input
                      name="until"
                      label="Hasta"
                      type="date"
                      width={3}
                      value={filters.until}
                      onChange={this.filterInputHandler}
                    />
                  </Form.Group>
                  <Form.Button
                    floated="right"
                    primary
                    type="submit"
                    content="Enviar"
                    onClick={this.submitQuery}
                  />
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment loading={tickets.loading} vertical>
                <List divided relaxed>
                  {tickets.data.map((ticket, index) => (
                    <List.Item key={index}>
                      <List.Icon
                        name="github"
                        size="large"
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header as="a">{ticket.subject}</List.Header>
                        <List.Description>
                          {moment(ticket.createdAt).format("DD/MM/YYYY HH:mm")}
                        </List.Description>
                        {ticket.tags && (
                          <List.Description>
                            {ticket.tags.map((tag, i) => (
                              <Label key={i}>{tag}</Label>
                            ))}
                          </List.Description>
                        )}
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const StyledView = Radium(TicketQueryView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  TicketQueryView.mapStateToProps,
  TicketQueryView.mapDispatchToProps
)(i18nEnhancement);
