import React, { Component } from "react";
import { Button, Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
class PasswordChangeForm extends Component {
  render () {
    let {
      loading,
      newPassword,
      passwordDuplicate,
      createInputHandler,
      t,
      onSubmit
    } = this.props;

    return (
      <Form loading={loading}>
        <Form.Input
          label={t("passwordChange.form.fields.newPassword")}
          type="password"
          value={newPassword}
          onChange={createInputHandler("newPassword")}
        />
        <Form.Input
          label={t("passwordChange.form.fields.passwordDuplicate")}
          type="password"
          value={passwordDuplicate}
          onChange={createInputHandler("passwordDuplicate")}
        />
        <Button
          primary
          icon="send"
          fluid
          content={t("passwordChange.form.buttons.send")}
          onClick={onSubmit}
        />
      </Form>
    );
  }
}

PasswordChangeForm.propTypes = {
  loading: PropTypes.bool,
  password: PropTypes.string,
  passwordDuplicate: PropTypes.string,
  createInputHandler: PropTypes.func,
  onSubmit: PropTypes.func
};

PasswordChangeForm.defaultProps = {
  loading: false,
  error: null
};
const i18nEnhancement = translate("translations")(PasswordChangeForm);
export default i18nEnhancement;
