import {
  fromJS
} from 'immutable';
import {
  handleActions
} from 'redux-actions';
import {Types} from '../../actions/authentication';

const INITIAL_STATE = fromJS({
  loading: false,
  token: null,
  user: null,
  error: null
});

const authenticationReducer = handleActions({
  [Types.ATTEMPT]: (state, action) => state.merge({ loading: true, error: null }),
  [Types.SUCCESS]: (state, action) => state.merge({
    loading: false,
    token: action.token,
    user: action.user
  }),
  [Types.FAILURE]: (state, action) => state.merge({ loading: false, error: action.error }),
  [Types.LOGOUT]: (state, action) => INITIAL_STATE
}, INITIAL_STATE);


export default authenticationReducer;
