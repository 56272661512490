import React, {
  Component
} from 'react';
import ErrorDisplay from '../ErrorDisplay';

class ErrorView extends Component {

  constructor (props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch (error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo });
  }

  render () {
    let {
      hasError,
      error,
      errorInfo
    } = this.state;

    if (hasError) {
      return <ErrorDisplay error={error} info={errorInfo} />;
    } else {
      return this.props.children;
    }
  }

}

export default ErrorView;
