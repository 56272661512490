import {
  combineReducers
} from 'redux';
import {
  routerReducer
} from 'react-router-redux';
import authenticationReducer from './authentication';
import visibilityReducer from './visibility';
import subscriptionReducer from './subscription';
import groupReducer from './group';
import usersReducer from './users';
import ticketReducer from './ticket';
export default combineReducers({
  routing: routerReducer,
  authentication: authenticationReducer,
  visibility: visibilityReducer,
  subscriptions: subscriptionReducer,
  users: usersReducer,
  group: groupReducer,
  tickets: ticketReducer
});
