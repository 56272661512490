import { createReducer } from 'reduxsauce';
import {Types} from '../../actions/subscription';
import {
  fromJS
} from 'immutable';

// the initial state of this reducer
export const INITIAL_STATE = fromJS({ loading: false, error: null, entries: []});

// attempting
export const findAttempt = (state = INITIAL_STATE, action) => {
  return state.merge({ loading: true, error: null, entries: []});
};

// the eagle has landed
export const findSuccess = (state = INITIAL_STATE, action) => {
  return state.merge({ loading: false, error: null, entries: action.entries });
};

// uh oh
export const findError = (state = INITIAL_STATE, action) => {
  return state.merge({loading: false, error: action.error, entries: []});
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.FIND_ATTEMPT]: findAttempt,
  [Types.FIND_SUCCESS]: findSuccess,
  [Types.FIND_ERROR]: findError
};

export default createReducer(INITIAL_STATE, HANDLERS);