import React, { Component } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";
import GroupLogo from "./GroupLogo";
import * as _ from "lodash";
import { Accordion } from "semantic-ui-react";
import { truncate, capitalize } from "lodash";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
function titleCase(str) {
  let value = _.map(str.split(" "), _.upperFirst).join(" ");
  return value.replace(
    /(en|de(l?)|a|con|se|al|el|los|la(s?)|que|un(a?))(( )|(\.\.\.))/gi,
    v => v.toLowerCase()
  );
}

function capitalizeAll(str) {
  let value = capitalize(str);
  value = str.replace(/([!?.]\s?)([a-z])/g, function(m, $1, $2) {
    return $1 + $2.toUpperCase();
  });
  return _.upperFirst(value).replace(
    /([!?.,])([a-z])/gi,
    (v, $1, $2) => `${$1} ${$2}`
  );
}

const priorities = ["grey", "red", "orange", "#ffd700", "grey"];

const ActivityRow = props => {
  let isTopic = props.type == "topic";
  let isEvent = props.activityType == "publicEvent";
  //console.log("isEvent", isEvent, props.type);
  let color = priorities[props.priority];
  let isAgent = props.role == "agent";
  let isOwn = props.user.id == props.UserId;
  let isImage = props.mediaType == "image";
  let isAttachment = props.mediaType == "attachment";
  let isSubject = props.subject;
  let deleteEnabled = !!props.onDeleteClicked;
  deleteEnabled =
    deleteEnabled &&
    !!props.createdAt &&
    moment(props.createdAt).isSame(moment(), "minute");

  const seenBy = (props.seenBy || [])
    .map(seenString => {
      let params = seenString.split("#");

      return {
        id: params[0],
        timestamp: params[1],
        displayName: params[2]
      };
    })
    .filter(s => s.id != props.user.id && s.id != props.UserId);
  return (
    <div>
      <div
        style={{
          clear: "both",
          backgroundColor: props.selected ? "#ccf2ff" : undefined
        }}
        onClick={props.flipSelected}
      >
        {!isTopic ||
        props.user.roles.find(a => a == "wikiedit") == undefined ? null : (
          <div style={{ textAlign: "center" }}>
            {!!isSubject ? null : (
              <Button icon="trash" inverted onClick={props.onDeleteClicked} />
            )}
            {!props.swapDown ? null : (
              <Button icon="arrow down" inverted onClick={props.swapDown} />
            )}
            {!props.swapUp ? null : (
              <Button icon="arrow up" inverted onClick={props.swapUp} />
            )}
            {isImage || isAttachment ? null : (
              <Button
                icon="edit"
                inverted
                onClick={e => {
                  e.stopPropagation();
                  props.onEdit();
                }}
              />
            )}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems:
              !!isTopic || !!isEvent
                ? "center"
                : isOwn
                ? "flex-end"
                : "flex-start"
          }}
        >
          {" "}
          <div
            style={{
              float: !!isTopic ? undefined : isOwn ? "right" : "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: isTopic ? "center" : undefined
            }}
            onClick={e => e.stopPropagation()}
          >
            {isOwn || isTopic || isEvent ? null : (
              <div style={{ marginBottom: "10px" }}>
                <GroupLogo
                  color={isSubject ? "white" : undefined}
                  name={props.UserShortDescription || ""}
                  size={40}
                />
              </div>
            )}
            {!isOwn || isTopic || !deleteEnabled ? null : (
              <div style={{ marginBottom: "10px" }}>
                <Button icon="trash" inverted onClick={props.onDeleteClicked} />
                {}
              </div>
            )}

            <div
              style={{
                lineHeight: isImage ? "28vh" : undefined,
                height: isImage ? "30vh" : undefined,
                overflow: isImage ? "hidden" : undefined,
                margin: "10px",
                padding: 10, //isImage ? 0 : 10,
                borderRadius: ".4em",
                maxWidth: "35vw",
                backgroundColor: isEvent
                  ? color
                  : isTopic
                  ? "white"
                  : isOwn || isAgent
                  ? props.status == "sending"
                    ? "hsl(219, 86%, 85%)"
                    : props.status == "failed"
                    ? "hsl(255, 86%, 85%)"
                    : "hsl(219, 86%, 65%)"
                  : "lightgray",
                color: (isOwn || isAgent) && !isTopic ? "white" : "black"
              }}
            >
              {isImage ? (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={e => {
                    props.previewImage(props.body);
                    e.stopPropagation();
                  }}
                >
                  <img
                    style={{ width: "28vw", verticalAlign: "middle" }}
                    src={props.body}
                  />
                </a>
              ) : isAttachment ? (
                <a href={props.body} target="_blank">
                  Adjunto{" "}
                  {`${props.body}`
                    .split(".")
                    .pop()
                    .toUpperCase()}
                </a>
              ) : (
                <div
                  style={
                    isSubject ? { fontWeight: "bold", fontSize: "20pt" } : null
                  }
                  dangerouslySetInnerHTML={{ __html: props.body }}
                />
              )}
            </div>
            {!isOwn || isTopic || isEvent ? null : (
              <div style={{ marginBottom: "10px" }}>
                <GroupLogo
                  color={isSubject ? "white" : undefined}
                  name={props.UserShortDescription || ""}
                  size={40}
                />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            whiteSpace: "pre-line",
            display: !isTopic && seenBy.length > 0 ? undefined : "none",
            textAlign: isOwn ? "right" : "left",
            fontSize: 10,
            color: "lightgrey",
            fontWeight: "bold",
            paddingBottom: "10px"
          }}
        >
          {seenBy.map(a => a.displayName).join(", ")}
          <Icon
            name="checkmark"
            style={{
              paddingLeft: "10px"
            }}
          />
          <Icon name="checkmark" fitted />
        </div>
      </div>
    </div>
  );
};

const TimeIndicatorRow = ({ date, dayChange, calendarFormat }) => {
  return (
    <div
      style={{
        clear: "both",
        textAlign: "center",
        color: "grey",
        marginLeft: "10px",
        marginRight: "10px",
        padding: "10px",
        overflow: "hidden"
      }}
    >
      <p className={dayChange ? "fancy" : undefined}>
        <span>
          {!!calendarFormat
            ? _.upperFirst(
                moment(date)
                  .locale("es")
                  .calendar(null, { sameElse: "DD/MM/YYYY - HH:mm" })
              )
            : moment(date).format(
                dayChange != undefined && dayChange ? "DD/MM/YY HH:mm" : "HH:mm"
              )}
        </span>
      </p>
    </div>
  );
};

export default class ConversationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteActivityId: null
    };
  }

  openDeleteModal(deleteActivityId) {
    this.setState({ deleteActivityId });
  }

  exitModal = () => {
    this.openDeleteModal(null);
  };

  render() {
    const props = this.props;
    let rows = props.activities.data.map((anActivity, index, arr) => {
      return anActivity.MovedToTicketId ? (
        <Accordion
          defaultActiveIndex={[]}
          panels={[
            {
              key: `${anActivity.MovedToTicketId}${anActivity.id}moved`,
              title: {
                content: (
                  <span>
                    {`Este mensaje se movió al ticket `}
                    <Link
                      to={props.buildUrl(
                        "forceall",
                        undefined,
                        anActivity.MovedToTicketId,
                        null,
                        undefined,
                        true
                      )}
                    >{`#${anActivity.MovedToTicketId}`}</Link>
                  </span>
                )
              },
              content: {
                content: (
                  <ActivityRow
                    {...anActivity}
                    type={props.type}
                    key={anActivity.id}
                    selected={props.selectedRows.some(a => a == anActivity.id)}
                    showSelectedIndicator={props.selectedRows.length > 0}
                    flipSelected={() => props.flipRowSelection(anActivity.id)}
                    user={props.user}
                    previewImage={props.previewImage}
                  />
                )
              }
            }
          ]}
          exclusive={false}
          fluid
        />
      ) : (
        <ActivityRow
          {...anActivity}
          activityType={anActivity.type}
          type={props.type}
          key={anActivity.id}
          selected={props.selectedRows.some(a => a == anActivity.id)}
          showSelectedIndicator={props.selectedRows.length > 0}
          swapUp={
            index <= 0
              ? undefined
              : () => props.swapMessages(arr[index - 1].id, anActivity.id)
          }
          swapDown={
            index >= arr.length - 1
              ? undefined
              : () => props.swapMessages(anActivity.id, arr[index + 1].id)
          }
          onEdit={() => props.setEdittingSelection(anActivity.id)}
          flipSelected={() => props.flipRowSelection(anActivity.id)}
          user={props.user}
          previewImage={props.previewImage}
          onDeleteClicked={() => this.openDeleteModal(anActivity.id)}
        />
      );
    });
    if (props.type != "topic") {
      rows = rows.reduce((p, c, i) => {
        if (i <= 0) {
          return [c];
        }
        let lastActivity = props.activities.data[i - 1];
        let currentActivity = props.activities.data[i];
        if (!moment(currentActivity.createdAt).isSame(moment(), "day")) {
          return [
            ...p,
            <TimeIndicatorRow
              date={currentActivity.createdAt}
              calendarFormat
            />,
            c
          ];
        } else if (
          moment(lastActivity.createdAt).isSame(
            moment(currentActivity.createdAt),
            "day"
          )
        ) {
          if (
            !moment(lastActivity.createdAt).isSame(
              moment(currentActivity.createdAt),
              "hour"
            )
          ) {
            return [
              ...p,
              <TimeIndicatorRow date={currentActivity.createdAt} />,
              c
            ];
          }
        } else {
          return [
            ...p,
            <TimeIndicatorRow date={currentActivity.createdAt} dayChange />,
            c
          ];
        }
        return [...p, c];
      }, []);
    }
    if (!!props.activities.loading && props.activities.data.length <= 0) {
      return <div>Loading...</div>;
    }
    return (
      <div
        id="conversation"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          flex: 1,
          padding: 10
        }}
      >
        {props.activities.data.length <= 0 ? null : (
          <TimeIndicatorRow
            date={props.activities.data[0].createdAt}
            dayChange
          />
        )}
        {props.activities.data.length > 0 ? (
          <ActivityRow
            subject
            mediaType="html"
            type={props.type}
            seenBy={props.seenBy}
            user={props.user}
            selected={false}
            showSelectedIndicator={false}
            onEdit={() => props.setEdittingSelection("subject")}
            flipSelected={() => {}}
            UserId={props.activities.data[0].TicketCreatorId}
            body={titleCase(props.subject)}
            UserShortDescription={props.activities.data[0].UserShortDescription}
          />
        ) : null}
        {rows}
        {props.ticket.status != "closed" &&
        props.activities.data.length <= 1 ? null : props.activities.data.find(
            a => a.UserId != props.ticket.UserId
          ) == undefined ? null : props.type == "topic" ? null : (
          <Modal
            trigger={
              <Button basic fluid color="blue">
                Se resolvió el problema? Cerrar el ticket
              </Button>
            }
            header="Cerrar ticket?"
            content="Solo cerrá el ticket si se resolvió tu problema."
            actions={[
              "No",
              {
                key: "yes",
                content: "Si",
                positive: true,
                onClick: props.closeTicket
              }
            ]}
          />
        )}
        <Modal
          open={this.state.deleteActivityId != null}
          header="Eliminar mensaje?"
          actions={[
            { content: "No", key: "no", onClick: this.exitModal },
            {
              key: "yes",
              content: "Si",
              negative: true,
              onClick: () => {
                props.deleteActivity(this.state.deleteActivityId);
                this.exitModal();
              }
            }
          ]}
        />
      </div>
    );
  }
}
