import React, { Component } from "react";
import { Link } from "react-router-dom";
import { List, Image, Icon, Button, Loader } from "semantic-ui-react";
import moment from "moment";
import GroupLogo from "./GroupLogo";
import * as _ from "lodash";
import he from "he";
import { truncate, capitalize } from "lodash";
function titleCase(str) {
  let value = _.map(str.split(" "), _.upperFirst).join(" ");
  return value.replace(
    /(en|de(l?)|a|con|se|al|el|los|la(s?)|que|un(a?))(( )|(\.\.\.))/gi,
    v => v.toLowerCase()
  );
}

function capitalizeAll(str) {
  let value = capitalize(str);
  value = str.replace(/([!?.]\s?)([a-z])/g, function(m, $1, $2) {
    return $1 + $2.toUpperCase();
  });
  return _.upperFirst(value).replace(
    /([!?.,])([a-z])/gi,
    (v, $1, $2) => `${$1} ${$2}`
  );
}

const ChatRow = props => {
  let statusIcon;
  let statusColor;
  if (props.status == "open") {
    if (props.tags.some(a => a == "flagged")) {
      statusIcon = "flag";
      statusColor = "orange";
    } else if (
      props.lastActivity.UserId != props.user.id &&
      !(props.lastActivity.seenBy || []).some(
        a => a.split("#")[0] == props.user.id
      )
    ) {
      statusIcon = "circle";
      statusColor = "orange";
    } else {
      statusIcon = "";
      statusColor = "white";
    }
  } else {
    statusColor = "grey";
    if (props.lastActivity.UserId != props.user.id) {
      statusIcon = "check circle outline";
    } else {
      statusIcon = "circle outline";
    }
  }

  let displayDate = moment(
    (props.lastActivity && props.lastActivity.createdAt) || props.updatedAt
  );
  return (
    <List.Item
      onClick={() => props.onSelect(props.id)}
      as={Link}
      to={props.to}
      style={{
        padding: 10,
        backgroundColor: props.active
          ? props.global
            ? "yellow"
            : "lightgrey"
          : props.global
            ? "lightyellow"
            : undefined
      }}>
      <List.Content>
        <List.Header
          style={{
            fontSize: 20,
            fontWeight: props.global ? "bold" : "normal",
            width: "100%",
            paddingBottom: 10,
            color: props.error ? "grey" : undefined
          }}>
          {props.subject.length <= 0 ? "Sin Asunto" : titleCase(props.subject)}
          <span
            style={{
              textAlign: "right",
              float: "right",
              color: "orange",
              fontSize: 10
            }}>
            {props.global ? "IMPORTANTE" : ""}
          </span>
        </List.Header>
      </List.Content>
      <List.Content>
        <List.Content
          floated="left"
          style={{ paddingRight: 10, minHeight: 70 }}>
          {!props.showLogo ? null : (
            <GroupLogo
              color={props.group.color}
              logo={props.group.logo}
              size={40}
              name={props.group.name}
            />
          )}
        </List.Content>
        <List.Content floated="right">
          <List.Description style={{ textAlign: "right" }}>
            {capitalizeAll(props.group.name)}
            <br />
            {displayDate.isSame(moment(), "year")
              ? displayDate.format("DD/MM HH:mm")
              : displayDate.format("DD/MM/YYYY")}
            <br />#{props.id}
            <br />
            <Icon name={statusIcon} color={statusColor} fitted />
          </List.Description>
        </List.Content>
        <List.Content style={{ maxWidth: "70%" }}>
          <List.Description>
            <b>{`${props.lastActivity.UserShortDescription || "Sistema"}: `}</b>
            <br />
            {props.lastActivity.mediaType == "html"
              ? truncate(
                capitalizeAll(
                  he.decode(
                    props.lastActivity.body.replace(/<\/?[^>]+(>|$)/g, "")
                  )
                ),
                { length: 80, separator: /,? +/ }
              )
              : "📷 Imagen"}
          </List.Description>
        </List.Content>
      </List.Content>
    </List.Item>
  );
};

const WikiRow = props => {
  let statusIcon;
  let statusColor;
  if (props.status == "open") {
  } else {
    statusColor = "grey";
    statusIcon = "circle outline";
  }
  return (
    <List.Item
      onClick={() => props.onSelect(props.id)}
      as={Link}
      to={props.to}
      style={{
        padding: 10,
        backgroundColor: props.active ? "lightgrey" : undefined
      }}>
      <List.Content>
        <List.Header
          style={{
            fontSize: 20,
            fontWeight: "normal",
            width: "100%",
            paddingBottom: 10,
            color: props.error ? "grey" : undefined
          }}>
          {props.subject.length <= 0 ? "Sin Asunto" : titleCase(props.subject)}
          {!props.wikiEdit ? null : (
            <div style={{ textAlign: "center", float: "right" }}>
              {!props.swapDown ? null : (
                <Button icon="arrow down" inverted onClick={props.swapDown} />
              )}
              {!props.swapUp ? null : (
                <Button icon="arrow up" inverted onClick={props.swapUp} />
              )}
            </div>
          )}
        </List.Header>
      </List.Content>
      <List.Content>
        {/* <List.Content floated="right">
          <List.Description style={{ textAlign: "right" }}>
            {moment(
              (props.lastActivity && props.lastActivity.createdAt) ||
                props.updatedAt
            ).format("DD/MM HH:mm")}
            <br />
            <Icon name={statusIcon} color={statusColor} fitted />
          </List.Description>
        </List.Content> */}
        <List.Content>
          <List.Description>
            {props.lastActivity.mediaType == "html"
              ? truncate(
                capitalizeAll(
                  he.decode(
                    props.lastActivity.body.replace(/<\/?[^>]+(>|$)/g, "")
                  )
                ),
                { length: 130, separator: /,? +/ }
              )
              : "📷 Imagen"}
          </List.Description>
          {props.user.roles.some(a => a == "wikiedit" || a == "wikipublish") ? (
            <span>{props.global ? "PUBLICADO" : "BORRADOR"}</span>
          ) : null}
        </List.Content>
      </List.Content>
    </List.Item>
  );
};

export default props => {
  return (
    <List divided relaxed>
      {(props.configuration.data || []).map((aTicket, index, arr) => {
        let group = props.groups.all.find(a => a.id == aTicket.GroupId) || {
          name: ""
        };
        if (props.type == "topic") {
          return (
            <WikiRow
              active={props.active && props.active.id == aTicket.id}
              {...aTicket}
              key={aTicket.id}
              to={props.buildUrl(
                undefined,
                undefined,
                aTicket.id,
                null,
                aTicket.GroupId
              )}
              error={props.configuration.error || null}
              onSelect={props.onSelect}
              showLogo={props.showLogos}
              group={group}
              user={props.user || {}}
              swapUp={
                index <= 0
                  ? undefined
                  : () => props.swapTickets(arr[index - 1].id, aTicket.id)
              }
              swapDown={
                index >= arr.length - 1
                  ? undefined
                  : () => props.swapTickets(aTicket.id, arr[index + 1].id)
              }
              wikiEdit={
                props.user.roles.find(a => a == "wikiedit") != undefined
              }
            />
          );
        }
        return (
          <ChatRow
            active={props.active && props.active.id == aTicket.id}
            {...aTicket}
            key={aTicket.id}
            to={props.buildUrl(
              undefined,
              undefined,
              aTicket.id,
              null,
              aTicket.GroupId
            )}
            error={props.configuration.error || null}
            onSelect={props.onSelect}
            showLogo={props.showLogos}
            group={group}
            user={props.user || {}}
          />
        );
      })}
      {(props.configuration.data || []).length == 0 || (props.configuration.data || []).length % 50 != 0 ? null : 
      <List.Item onClick={props.loadMore} style={{cursor: "pointer"}}>
        
        <List.Header style={{padding: 30, color: props.loading? "grey": "black"}}>
        <Loader inline active={props.loading} style={{float: "right"}}/>
          Buscar más...
        </List.Header>
        <List.Content>

        </List.Content>
      </List.Item>}
    </List>
  );
};
