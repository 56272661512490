import React, { Component } from "react";
import Radium, { Style } from "radium";
import update from "immutability-helper";
import { camelCase } from "lodash";
import {
  Button,
  Breadcrumb,
  Container,
  Divider,
  Header,
  Icon,
  Message,
  Modal,
  Segment,
  Statistic
} from "semantic-ui-react";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import * as Routes from "../../../../../routes";
import storeActions from "../../../../../store/actions";
import { DEFAULT_FONT_SIZE } from "./constants";
import UsersTable from "./UsersTable";
import UsersForm from "./UsersForm";
import { isArray } from "util";

const INITIAL_STATE = {
  users: {
    loading: false,
    data: [],
    limit: 9999,
    skip: 0,
    total: 0,
    error: null
  },
  upsertModal: {
    open: false,
    values: {
      id: null,
      username: "",
      email: "",
      password: "",
      fullName: "",
      roles: []
    },
    message: ""
  },
  removeModal: {
    open: false,
    id: null
  }
};

class AdminUsersView extends Component {
  constructor(props) {
    super(props);

    const { feathers } = this.props.services;

    this.state = INITIAL_STATE;
    this.UserService = feathers.service("api/users");
  }

  componentDidMount() {
    this.findUsers();
  }

  static mapStateToProps(state) {
    return {
      authentication: state.authentication,
      users: state.users.toJS({ deep: true })
    };
  }

  static mapDispatchToProps(dispatch) {
    let actions = {};

    Object.keys(storeActions.users).forEach(key => {
      actions[camelCase(`users_${key}`)] = (...params) =>
        dispatch(storeActions.users[key](...params));
    });

    return {
      ...actions,
      navigate: path => dispatch(push(path))
    };
  }

  openUpsertModal = (values = {}) => {
    let { upsertModal } = this.state;

    this.setState({
      upsertModal: {
        ...INITIAL_STATE.upsertModal,
        values: {
          ...upsertModal.values,
          ...values
        },
        open: true
      }
    });
  };

  dissmissUpsertModal = (e) => {
    let { upsertModal } = this.state;
    let go = e == true;
    if (!go) {
      go = window.confirm("Todavia no has confirmado los cambios. Estás seguro que quieres salir?");
    }
    if (!go) {
      return
    }
    this.setState({
      upsertModal: INITIAL_STATE.upsertModal
    });
  };

  openRemoveModal = id => {
    let { removeModal } = this.state;
    this.setState({
      removeModal: {
        ...removeModal,
        id,
        open: true
      }
    });
  };

  dismissRemoveModal = () => {
    let { removeModal } = this.state;
    this.setState({
      removeModal: INITIAL_STATE.removeModal
    });
  };

  findUsers = () => {
    const { users } = this.props;

    this.props.usersFindAttempt({ $limit: 9999, $skip: 0 });

    this.UserService.find({ query: { $skip: 0, $limit: 9999 } })
      .then(response => {
        this.props.usersFindSuccess(
          response.data,
          response.total,
          response.limit,
          response.skip
        );
      })
      .catch(error => {
        this.props.usersFindError(error);
      });
  };

  createUser = () => {
    const { users } = this.props;
    const {
      upsertModal: { values }
    } = this.state;

    this.props.usersCreateAttempt(values);

    this.UserService.create(values)
      .then(response => {
        this.props.usersCreateSuccess(response);
        this.setState(
          update(this.state, {
            upsertModal: {
              $merge: {
                ...INITIAL_STATE.upsertModal,
                open: true,
                message: "Usuario creado con exito!"
              }
            }
          }),
          this.findUsers
        );
      })
      .catch(error => {
        this.props.usersCreateError(error);
      });
  };

  updateUser = () => {
    const { users } = this.props;
    let {
      upsertModal: { values }
    } = this.state;
    if (!values.password || values.password.length <= 0 || values.password == "" || values.password == " ") {
      values.password = undefined;
    }
    this.props.usersUpdateAttempt(values);

    this.UserService.patch(values.id, values)
      .then(response => {
        this.props.usersUpdateSuccess(response);
        this.setState(
          update(this.state, {
            upsertModal: {
              $merge: {
                ...INITIAL_STATE.upsertModal,
                message: "Usuario actualizado con exito!"
              }
            }
          }),
          this.findUsers
        );
      })
      .catch(error => {
        this.props.usersUpdateError(error);
      });
  };

  upsertUser = () => {
    const {
      upsertModal: { values }
    } = this.state;

    if (values.id) {
      this.updateUser();
    } else {
      this.createUser();
    }
  };

  removeUser = () => {
    const { removeModal } = this.state;

    this.props.usersRemoveAttempt(removeModal.id);

    this.UserService.remove(removeModal.id)
      .then(response => {
        this.props.usersRemoveSuccess(response);
        this.dismissRemoveModal();
        this.findUsers();
      })
      .catch(error => {
        this.props.usersRemoveError(error);
      });
  };

  inputHandler = (e, input) => {
    const { upsertModal } = this.state;

    this.setState({
      upsertModal: {
        ...upsertModal,
        values: {
          ...upsertModal.values,
          [input.name]: input.value
        }
      }
    });
  };

  render() {
    const { t, users } = this.props;
    const { upsertModal, removeModal } = this.state;
    const upsertError = users.create.error || users.update.error;

    return (
      <Container style={{ padding: 12, overflowY: "auto" }}>
        <Style
          rules={{
            ".edit-icon-hover": {
              cursor: "pointer"
            },
            ".edit-icon-hover:hover": {
              color: "blue"
            },
            ".trash-icon-hover": {
              cursor: "pointer",
              color: "red"
            },
            ".trash-icon-hover:hover": {
              color: "orange"
            }
          }}
        />

        <Breadcrumb>
          <Breadcrumb.Section as={Link} to={Routes.DASHBOARD}>
            Principal
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section as={Link} link to={Routes.ADMIN}>
            Administracion
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section as={Link} link to={Routes.ADMIN_RESOURCES}>
            Recursos
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Usuarios</Breadcrumb.Section>
        </Breadcrumb>

        <Divider />

        <Header dividing size="large" style={{ margin: 0 }}>
          <Icon name="users" />
          <Header.Content>
            ADMINISTRACION DE USUARIOS
            <Header.Subheader>
              Alta, Baja y Modificacion de Usuarios
            </Header.Subheader>
          </Header.Content>
        </Header>

        <Statistic.Group style={{ margin: 12 }}>
          <Statistic
            horizontal
            label="Registros"
            value={users.find.total}
            size="mini"
            style={{ marginTop: 0, marginBottom: 0, marginRight: 0 }}
          />
          <Statistic
            horizontal
            label="Limitar"
            value={users.find.limit}
            size="mini"
            style={{ marginTop: 0, marginBottom: 0 }}
          />
          <Statistic
            horizontal
            label="Saltar"
            value={users.find.skip}
            size="mini"
            style={{ marginTop: 0, marginBottom: 0 }}
          />
        </Statistic.Group>

        <Segment clearing size="mini" vertical>
          <Button
            icon="refresh"
            color="green"
            size="mini"
            labelPosition="left"
            content="Refrescar"
            onClick={this.findUsers}
          />

          <Button
            icon="add"
            color="blue"
            size="mini"
            labelPosition="left"
            floated="right"
            content="Nuevo"
            onClick={() => this.openUpsertModal()}
          />
        </Segment>

        <Segment
          vertical
          style={{ fontFamily: "monospace", fontSize: DEFAULT_FONT_SIZE }}
        >
          <UsersTable
            data={users.find.data}
            loading={users.find.loading}
            updateUser={this.openUpsertModal}
            removeUser={this.openRemoveModal}
          />
        </Segment>

        <Modal
          open={upsertModal.open}
          onClose={this.dissmissUpsertModal}
          size="small"
        >
          <Header
            icon={upsertModal.values.id ? "edit" : "add"}
            content={upsertModal.values.id ? "Editar" : "Nuevo"}
          />
          <Modal.Content>
            <UsersForm
              loading={users.create.loading || users.update.loading}
              submit={this.upsertUser}
              values={upsertModal.values}
              inputHandler={this.inputHandler}
            />
            {upsertError && (
              <Message
                negative
                header={upsertError.name}
                content={upsertError.message}
              />
            )}
            {upsertModal.message && (
              <Message positive header={upsertModal.message} />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              color="red"
              disabled={users.create.loading || users.update.loading}
              onClick={this.dissmissUpsertModal}
            >
              <Icon name="remove" /> Cancelar
            </Button>
            <Button
              color="green"
              disabled={users.create.loading || users.update.loading}
              onClick={this.upsertUser}
            >
              <Icon name="checkmark" /> Aceptar
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={removeModal.open} basic size="small">
          <Header icon="trash" content="Eliminar Usuario" />
          <Modal.Content>
            <p>Seguro desea eliminar el usuario ''?</p>
            {users.remove.error && (
              <Message
                negative
                header={users.remove.error.message}
                list={
                  isArray(users.remove.error.errors)
                    ? users.remove.error.errors.map(e => e.message)
                    : []
                }
              />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              color="red"
              disabled={users.remove.loading}
              inverted
              onClick={this.dismissRemoveModal}
            >
              <Icon name="remove" /> No
            </Button>
            <Button
              color="green"
              disabled={users.remove.loading}
              inverted
              onClick={this.removeUser}
            >
              <Icon name="checkmark" />{" "}
              {users.remove.loading ? "Eliminando . . ." : "Si"}
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

const StyledView = Radium(AdminUsersView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  AdminUsersView.mapStateToProps,
  AdminUsersView.mapDispatchToProps
)(i18nEnhancement);
