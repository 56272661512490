import React from "react";
import { Container, Header, Icon, Segment } from "semantic-ui-react";

const ErrorView = ({ error, info }) => (
  <Container style={{ padding: 32 }}>
    <Segment attached="top" color="red" textAlign="center">
      <Header as="h2" icon>
        <Icon name="warning sign" />
        Error!
        <Header.Subheader>{error.toString()}</Header.Subheader>
      </Header>
    </Segment>
    <Segment attached="bottom">
      <pre>{info.componentStack}</pre>
    </Segment>
  </Container>
);

export default ErrorView;
