import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  findAttempt: ['query'],
  findError: ['error'],
  findSuccess: ['data', 'total', 'limit', 'skip'],
  createAttempt: ['values'],
  createSuccess: ['data'],
  createError: ['error'],
  updateAttempt: ['values'],
  updateSuccess: ['data'],
  updateError: ['error'],
  removeAttempt: ['id'],
  removeSuccess: ['data'],
  removeError: ['error']
}, { prefix: "USERS_" });
