import React, { Component } from "react";
import AuthManagement from "feathers-authentication-management/lib/client";
import {
  Button,
  Breadcrumb,
  Container,
  Divider,
  Header,
  Icon,
  Grid,
  Form,
  Segment,
  Dimmer,
  Loader,
  Message
} from "semantic-ui-react";
import { translate } from "react-i18next";
import Radium from "radium";
import ConnectServices from "../../providers/ConnectServices";
import colorPalette from "../../styles/colorPalette";
import authenticate from "../../authenticate";
import * as ROUTES from "../../routes";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";

const styles = {};

class ProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.authentication.toJS().user.username,
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      loading: false,
      error: null,
      message: null
    };
    this.FeathersService = this.props.services.feathers;
    this.authenticate = authenticate(this.FeathersService, props.dispatch);
  }

  componentDidUpdate(oldProps) {
    if (
      (!oldProps.user && !!this.props.user) ||
      (!!oldProps.user &&
        !!this.props.user &&
        oldProps.authentication.user.username !=
          this.props.authentication.user.username)
    ) {
      this.setState({
        username: oldProps.authentication.user.username
      });
    }
  }

  static mapStateToProps(state) {
    return {
      routing: state.routing,
      authentication: state.authentication
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path)),
      dispatch
    };
  }

  _onChange = (event, { name, value }) => {
    this.setState({
      [name]: value
    });
  };

  submit = () => {
    let { oldPassword, newPassword, newPasswordRepeat, username } = this.state;
    if (oldPassword.length < 1) {
      return;
    }
    if (newPassword.length < 1) {
      return;
    }
    if (newPassword != newPasswordRepeat) {
      return;
    }
    if (!username || username.length < 1) {
      return;
    }
    this.setState({
      loading: true,
      error: null,
      message: null,
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: ""
    });
    this.passwordChange(oldPassword, newPassword, newPasswordRepeat)
      .then(response => {
        this.setState({
          loading: false,
          message: "Password succesfully changed!",
          error: null
        });
      })
      .catch(e => {
        this.setState({
          error: e,
          loading: false,
          message: null
        });
      });
  };

  passwordChange = async (oldPassword, password, passwordRepeat) => {
    if (
      !oldPassword ||
      oldPassword.length < 1 ||
      !password ||
      password.length < 1 ||
      password != passwordRepeat
    ) {
      return;
    }
    const username = this.props.authentication.toJS().user.username;
    const identifyUser = { username };
    const authManagement = new AuthManagement(this.FeathersService);
    const response = await authManagement.passwordChange(
      oldPassword,
      password,
      identifyUser
    );
    return response;
  };

  render() {
    const { t } = this.props;
    const {
      username,
      oldPassword,
      loading,
      error,
      message,
      newPassword,
      newPasswordRepeat
    } = this.state;
    return (
      <Container style={{ padding: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Section as={Link} to={ROUTES.DASHBOARD}>
            {t("main")}
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section>{t("profile.title")}</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>
            {t("profile.passwordChange.title")}
          </Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Grid centered>
          <Grid.Column width="8">
            <Message
              hidden={!message}
              header={(!!message && message) || null}
              positive
            />
            <Segment attached="top" color="blue">
              <Header size="large">
                <Icon name="key" />
                {t("profile.passwordChange.title")}
                <Header.Subheader>
                  {t("profile.passwordChange.subtitle")}
                </Header.Subheader>
              </Header>
            </Segment>
            <Dimmer.Dimmable attached="bottom" as={Segment} dimmed={loading}>
              <Dimmer active={loading} inverted>
                <Loader>Loading</Loader>
              </Dimmer>
              <Form onSubmit={this.submit} loading={loading}>
                <Form.Input
                  label={t("signIn.username")}
                  disabled
                  name="username"
                  value={username}
                  placeholder={t("signIn.username")}
                  type="text"
                />
                <Form.Input
                  label={t("profile.oldPassword")}
                  onChange={this._onChange}
                  name="oldPassword"
                  value={oldPassword}
                  placeholder={t("profile.oldPassword")}
                  type="password"
                />
                <Form.Input
                  label={t("profile.newPassword")}
                  onChange={this._onChange}
                  name="newPassword"
                  value={newPassword}
                  placeholder={t("profile.newPassword")}
                  type="password"
                />
                <Form.Input
                  label={t("profile.newPasswordRepeat")}
                  onChange={this._onChange}
                  name="newPasswordRepeat"
                  value={newPasswordRepeat}
                  placeholder={t("profile.newPasswordRepeat")}
                  type="password"
                />
                <Form.Button
                  content={t("profile.submit")}
                  disabled={
                    newPassword != newPasswordRepeat ||
                    oldPassword.length < 1 ||
                    newPassword.length < 1
                  }
                />
              </Form>
            </Dimmer.Dimmable>
            <Message
              hidden={!error}
              header={(!!error && error.message) || null}
              content={!!error && JSON.stringify(error)}
              error
            />
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const i18nEnhancement = translate("translations")(ProfileView);
const StyledView = Radium(i18nEnhancement);
const withServices = ConnectServices(StyledView);

export default connect(
  ProfileView.mapStateToProps,
  ProfileView.mapDispatchToProps
)(withServices);
