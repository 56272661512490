import { createReducer } from 'reduxsauce';
import {Types} from '../../actions/group';
import {
  fromJS
} from 'immutable';

// the initial state of this reducer
export const INITIAL_STATE = fromJS({ loading: false, error: null, detail: null });

// attempting
export const getAttempt = (state = INITIAL_STATE, action) => {
  return state.merge({ loading: true, error: null, detail: [] });
};

// the eagle has landed
export const getSuccess = (state = INITIAL_STATE, action) => {
  return state.merge({ loading: false, error: null, detail: action.detail });
};

// uh oh
export const getError = (state = INITIAL_STATE, action) => {
  return state.merge({loading: false, error: action.error, detail: null});
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_ATTEMPT]: getAttempt,
  [Types.GET_SUCCESS]: getSuccess,
  [Types.GET_ERROR]: getError
};

export default createReducer(INITIAL_STATE, HANDLERS);