export const AUTHENTICATION_ATTEMPT = 'USER_AUTHENTICATION_ATTEMPT';
export const AUTHENTICATION_SUCCESS = 'USER_AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'USER_AUTHENTICATION_FAILURE';

export const AUTHENTICATION_LOGOUT = 'USER_AUTHENTICATION_LOGOUT';

export const TOGGLE_NAVIGATION_SIDEBAR = 'TOGGLE_NAVIGATION_SIDEBAR';

export const ADMIN_RESOURCES_USERS_FIND_ATTEMPT = 'ADMIN/RESOURCES/USERS_FIND_ATTEMPT';
export const ADMIN_RESOURCES_USERS_FIND_SUCCESS = 'ADMIN/RESOURCES/USERS_FIND_SUCCESS';
export const ADMIN_RESOURCES_USERS_FIND_FAILURE = 'ADMIN/RESOURCES/USERS_FIND_FAILURE';