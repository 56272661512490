import React from 'react';
import { translate } from 'react-i18next';
const loadingIndicator = ({disabled = false, message = null, localizationMessage = "loading", t}) => {
  let displayMessage = t(localizationMessage);
  if (!!disabled) {
    return null;
  }
  if (!!message) {
    displayMessage = message;
  }
  return (
    <div className="loader">
      <div className="loader__figure"></div>
      <p className="loader__label">{displayMessage.toUpperCase()}</p>
    </div>
  );
};

export default translate('translations')(loadingIndicator);
