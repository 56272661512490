import React, { Component } from "react";
import {
  Button,
  Breadcrumb,
  Container,
  Divider,
  Header,
  Icon,
  Grid,
  List,
  Segment,
  Statistic,
  Loader,
  Message,
  Tab
} from "semantic-ui-react";
import { translate } from "react-i18next";
import Radium from "radium";
import colorPalette from "../../../styles/colorPalette";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Routes from "../../../routes";
import { push } from "react-router-redux";

const styles = {};

class AdminDashboardView extends Component {
  constructor(props) {
    super(props);
    this.subscriptionActions = this.props.storeActions.subscriptions;
    this.feathers = this.FeathersService = this.props.services.feathers;
  }

  componentDidMount() {
    this.loadSubscriptions();
  }

  static mapStateToProps(state) {
    return {
      routing: state.routing,
      authentication: state.authentication,
      subscriptions: state.subscriptions.toJS()
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path)),
      dispatch
    };
  }

  loadSubscriptions = () => {
    const { dispatch, authentication } = this.props;
    const user = authentication.get("user").toJS();

    dispatch(this.subscriptionActions.findAttempt());

    this.feathers
      .service("api/subscriptions")
      .find({
        query: {
          UserId: user.id
        }
      })
      .then(result => {
        dispatch(this.subscriptionActions.findSuccess(result.data));
      })
      .catch(error => {
        dispatch(this.subscriptionActions.findError(error));
      });
  };

  render() {
    const { t, authentication, subscriptions, navigate } = this.props;
    const subscriptionState = subscriptions;
    const baseGroupSubscriptionEntries = subscriptionState.entries.filter(
      e => !e.Group.GroupId
    );
    if (!authentication.get("user")) {
      return "NO USER";
    }
    function capitalizeFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    let user = authentication.get("user").toJS();
    let isAdmin =
      user.roles.indexOf("sysadmin") != -1 || user.roles.indexOf("admin") != -1;

    if (!isAdmin && subscriptions.entries.length == 1) {
      navigate(
        Routes.GROUP_DETAIL.replace(":id", subscriptions.entries[0].GroupId)
      );
    } else if (!isAdmin) {
      navigate(Routes.GROUP_DETAIL.replace(":id", -1));
    }
    return (
      <Container style={{ padding: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Section active>
            {t("dashboard.breadcrumb")}
          </Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Grid stackable>
          <Grid.Row>
            <Grid.Column witdh={16}>
              <Header dividing size="large">
                PANEL PRINCIPAL
                <Header.Subheader>ACCESOS Y NOVEDADES</Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10}>
              <Header>
                DETALLES DE {`{GRUPO}`}
                <Header.Subheader>
                  Descripcion, detalles e indicadores de {`{grupo}`}
                </Header.Subheader>
              </Header>
              <Segment vertical>
                <Statistic.Group widths="four">
                  <Statistic>
                    <Statistic.Value>
                      <Icon name="ticket" />5
                    </Statistic.Value>
                    <Statistic.Label>Tickets</Statistic.Label>
                  </Statistic>
                  <Statistic color="red">
                    <Statistic.Value>
                      <Icon name="alarm" />2
                    </Statistic.Value>
                    <Statistic.Label>Alertas</Statistic.Label>
                  </Statistic>
                  <Statistic color="orange">
                    <Statistic.Value>
                      <Icon name="feed" />
                      12
                    </Statistic.Value>
                    <Statistic.Label>Feed</Statistic.Label>
                  </Statistic>
                  <Statistic color="blue">
                    <Statistic.Value>
                      <Icon name="mail" />1
                    </Statistic.Value>
                    <Statistic.Label>Mensajes</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Segment>
            </Grid.Column>
            <Grid.Column width={6}>
              <Header attached="top">
                <Icon name="feed" />
                <Header.Content>
                  SUSCRIPCIONES
                  <Header.Subheader>
                    Grupos a los que estoy adheridos
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Segment attached color="blue" style={{ minHeight: 150 }}>
                <Message
                  hidden={
                    !!subscriptionState.loading ||
                    (baseGroupSubscriptionEntries.length > 0 &&
                      !subscriptionState.error)
                  }
                  error={!!subscriptionState.error}
                  header={
                    (!!subscriptionState.error &&
                      subscriptionState.error.message) ||
                    t("subscriptions.messages.noItems")
                  }
                  content={
                    (!subscriptionState.error &&
                      t("subscriptions.messages.noItemsBody")) ||
                    ""
                  }
                />
                <List divided relaxed>
                  {!!subscriptionState.loading ? (
                    <Loader active={true} />
                  ) : (
                    baseGroupSubscriptionEntries
                      .slice(0, 3)
                      .map((aSubscription, index) => (
                        <List.Item key={index}>
                          <List.Icon
                            name="object group"
                            size="large"
                            verticalAlign="middle"
                          />
                          <List.Content>
                            <List.Header
                              as={Link}
                              to={Routes.GROUP_DETAIL.replace(
                                ":id",
                                aSubscription.GroupId
                              )}
                            >
                              {aSubscription.Group.name}
                            </List.Header>
                            <List.Description as="a">
                              {capitalizeFirst(
                                aSubscription.permissions
                                  .map(p =>
                                    t(`subscriptions.permissions.options.${p}`)
                                  )
                                  .join(", ")
                              )}
                              .
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      ))
                  )}
                  <List.Item>
                    <List.Content>
                      <List.Header as={Link} to={Routes.GROUPS}>
                        {t(`subscriptions.seeAll`).toUpperCase()}
                      </List.Header>
                    </List.Content>
                  </List.Item>
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {!isAdmin ? null : (
              <Grid.Column width={8}>
                <Header attached="top">
                  <Icon name="settings" />
                  <Header.Content>
                    FUNCIONES
                    <Header.Subheader>
                      Funciones administrativas
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment attached color="orange">
                  <List divided relaxed>
                    <List.Item>
                      <List.Icon
                        name="configure"
                        size="large"
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header as={Link} to={Routes.ADMIN_RESOURCES}>
                          ABM de Recursos
                        </List.Header>
                        <List.Description as="a">
                          Alta, Baja y Modificacion de Recursos (Usuarios,
                          Grupos, etc)
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  </List>
                </Segment>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const StyledView = Radium(AdminDashboardView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  AdminDashboardView.mapStateToProps,
  AdminDashboardView.mapDispatchToProps
)(i18nEnhancement);
