import React, { Component } from "react";
import {
  Button,
  Breadcrumb,
  Container,
  Divider,
  Header,
  Icon,
  Grid,
  Form,
  Segment,
  Dimmer,
  Loader,
  Message
} from "semantic-ui-react";
import { translate } from "react-i18next";
import Radium from "radium";
import ConnectServices from "../../providers/ConnectServices";
import colorPalette from "../../styles/colorPalette";
import authenticate from "../../authenticate";
import { Link, Route } from "react-router-dom";
import * as ROUTES from "../../routes";
import { connect } from "react-redux";
import { push } from "react-router-redux";

const styles = {};

class SignInView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
      error: null
    };
    this.FeathersService = this.props.services.feathers;
    this.authenticate = authenticate(this.FeathersService, props.dispatch);
  }

  static mapStateToProps(state) {
    return {
      routing: state.routing,
      authentication: state.authentication
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path)),
      dispatch
    };
  }

  submitAuthentication = e => {
    e.preventDefault();

    this.setState({
      loading: true,
      error: null
    });

    this.authenticate({
      strategy: "local",
      username: this.state.username,
      password: this.state.password
    })
      .then(response => {
        this.setState(
          {
            loading: false
          },
          () => {
            this.props.navigate(ROUTES.DASHBOARD);
          }
        );
      })
      .catch(error => {
        this.setState({
          error,
          loading: false
        });
      });
  };

  _onChange = (event, { name, value }) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const { t, navigate } = this.props;
    const { username, password, loading, error } = this.state;
    return (
      <Container style={{ padding: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Section link>{t("main")}</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link>{t("signIn.name")}</Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Grid centered>
          <Grid.Column width="8">
            <Segment attached="top" color="blue">
              <Header size="large">
                <Icon name="sign in" />
                {/* {t("signIn.title")} */}
                Sistema de Tickets de Soporte
                <Header.Subheader>{t("signIn.subtitle")}</Header.Subheader>
              </Header>
            </Segment>
            <Dimmer.Dimmable attached="bottom" as={Segment} dimmed={loading}>
              <Dimmer active={loading} inverted>
                <Loader>Loading</Loader>
              </Dimmer>
              <Form onSubmit={this.submitAuthentication}>
                <Form.Input
                  label={t("signIn.username")}
                  onChange={this._onChange}
                  name="username"
                  value={username}
                  placeholder={t("signIn.username")}
                  type="text"
                />
                <Form.Input
                  label={t("signIn.password")}
                  onChange={this._onChange}
                  name="password"
                  value={password}
                  placeholder={t("signIn.password")}
                  type="password"
                />
                <Form.Button content={t("signIn.submit")} />
                <Form.Button
                  negative
                  content={t("signIn.resetPassword")}
                  onClick={e => {
                    e.preventDefault();
                    navigate(ROUTES.FORGOT_PASSWORD);
                  }}
                />
              </Form>
            </Dimmer.Dimmable>
            <Message
              hidden={!error}
              header={(!!error && error.message) || null}
              content={!!error && JSON.stringify(error)}
              error
            />
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const i18nEnhancement = translate("translations")(SignInView);
const StyledView = Radium(i18nEnhancement);
const withServices = ConnectServices(StyledView);

export default connect(
  SignInView.mapStateToProps,
  SignInView.mapDispatchToProps
)(withServices);
