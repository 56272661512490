import React, { Component } from "react";
import FilteredCollection from "../layout/FilteredCollection";
import {
  Button,
  Breadcrumb,
  Container,
  Item,
  Accordion,
  Form,
  Menu,
  Input,
  Dropdown,
  Label,
  Divider,
  Header,
  Modal,
  Icon,
  Grid,
  List,
  Checkbox,
  Segment,
  Statistic,
  Loader,
  Message,
  Tab,
  Pagination
} from "semantic-ui-react";
import { find } from "lodash";
import queryString from "qs";
import { translate } from "react-i18next";
import Radium from "radium";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import * as Routes from "../../../routes";
import GroupTicketsContainer from "./GroupTicketsContainer";
import update from "immutability-helper";
import LoadingIndicator from "../../LoadingIndicator";
import debounce from "javascript-debounce";
import ControlHOC from "../../ControlHOC";
import CreateUserSubForm from "../common/CreateUserSubForm";

const CDropdownItem = ControlHOC(Dropdown.Item, ["sysadmin"]);

const styles = {
  container: {
    padding: 12
  }
};

const FILTERS = {
  SHOW_INBOX: "SHOW_INBOX",
  SHOW_IMPORTANT: "SHOW_IMPORTANT",
  SHOW_SNOOZED: "SHOW_SNOOZED",
  SHOW_ALL: "SHOW_ALL",
  SHOW_OPEN: "SHOW_OPEN",
  SHOW_CLOSED: "SHOW_CLOSED"
};

const GROUP_INITIAL_STATE = {
  loading: false,
  detail: null,
  error: null
};

const TICKETS_INITIAL_STATE = {
  loading: false,
  currentFilter: FILTERS.SHOW_INBOX,
  searchTerm: "",
  data: [],
  limit: 9999,
  skip: 0,
  total: 0,
  error: null,
  revertSort: false
};

// const SUBSCRIPTIONS_INITIAL_STATE = {
//   loading: false,
//   data: [],
//   limit: 9999,
//   skip: 0,
//   total: 0,
//   error: null
// };

const LAST_TICKET_ACTIVITIES_INITIAL_STATE = {
  loading: false,
  data: [],
  error: null
};

const STATS_INITIAL_STATE = {
  loading: false,
  data: [],
  error: null
};

const INITIAL_STATE = {
  group: {
    ...GROUP_INITIAL_STATE
  },
  tickets: {
    ...TICKETS_INITIAL_STATE
  },
  lastTicketActivities: {
    ...LAST_TICKET_ACTIVITIES_INITIAL_STATE
  },
  stats: {
    ...STATS_INITIAL_STATE
  },
  filtersOpen: true,
  groupsOpen: true,
  userSubCreateModal: {
    open: false
  },
  ticketsFilter: true
};

class GroupDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };

    this.feathers = this.props.services.feathers;
  }

  static mapStateToProps(state) {
    return {
      routing: state.routing,
      authentication: state.authentication
    };
  }

  panes = (t, state) => [
    {
      menuItem: t("group.tickets.label"),
      render: () => (
        <Tab.Pane basic attached={false}>
          <GroupTicketsContainer tickets={state.tickets} />
        </Tab.Pane>
      )
    }
  ];

  toggleFilters = () => {
    this.setState({
      filtersOpen: !this.state.filtersOpen
    });
  };

  toggleGroups = () => {
    this.setState({
      groupsOpen: !this.state.groupsOpen
    });
  };

  openCreateUserSubModal = () => {
    this.setState({
      userSubCreateModal: {
        ...this.state.userSubCreateModal,
        open: true
      }
    });
  };

  dismissCreateUserSubModal = () => {
    this.setState({
      userSubCreateModal: INITIAL_STATE.userSubCreateModal
    });
  };

  componentDidMount() {
    this.loadInfo();
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.match.params.id != this.props.match.params.id) {
      this.loadInfo();
    }
    if (
      previousState.tickets.currentFilter != this.state.tickets.currentFilter
    ) {
      this.loadTickets();
    }
    if (previousState.tickets.searchTerm != this.state.tickets.searchTerm) {
      this.debounceTicketReload();
    }

    if (previousState.tickets.revertSort != this.state.tickets.revertSort) {
      this.loadTickets();
    }
  }

  debounceTicketReload = debounce(() => {
    this.loadTickets();
  }, 800);

  loadInfo = () => {
    this.setState(
      update(this.state, {
        group: {
          $merge: {
            loading: true,
            error: null
          }
        },
        tickets: {
          $merge: {
            loading: true,
            error: null
          }
        },
        stats: {
          $merge: {
            loading: true,
            error: null
          }
        }
      })
    );
    this.loadGroup();
    this.loadTickets();
    this.loadStats();
  };

  loadStats = async () => {
    let id = this.props.authentication.toJS().user.id;
    if (!id) {
      this.setState(
        update(this.state, {
          stats: {
            $merge: {
              loading: false,
              data: [],
              error: null
            }
          }
        })
      );
      return;
    }

    let stats = await this.feathers.service("api/user-stats").get(id);
    //console.log("stats", stats);
    if (!!stats) {
      this.setState(
        update(this.state, {
          stats: {
            $merge: {
              loading: false,
              data: stats,
              error: null
            }
          }
        })
      );
    } else {
      this.setState(
        update(this.state, {
          stats: {
            $merge: {
              loading: false,
              data: [],
              error: new Error("noStats")
            }
          }
        })
      );
    }
  };

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path)),
      dispatch
    };
  }

  loadGroup = () => {
    let { id } = this.props.match.params;
    if (id == -1) {
      id = undefined;
      return;
    }
    this.feathers
      .service("api/groups")
      .get(id)
      .then(result => {
        this.setState(
          update(this.state, {
            group: {
              $merge: {
                detail: { ...result },
                loading: false,
                error: null
              }
            }
          })
        );
      })
      .catch(error => {
        //console.log(error);
        this.setState(
          update(this.state, {
            group: {
              $merge: {
                loading: false,
                error
              }
            }
          })
        );
      });
  };

  loadTickets = (skip = 0) => {
    let { id } = this.props.match.params;

    if (id == -1) {
      id = undefined;
    }

    const { currentFilter } = this.state.tickets;

    let desiredStatus;
    let tags;
    let { searchTerm } = this.state.tickets;
    if (!searchTerm || searchTerm.length < 3 || searchTerm === "") {
      searchTerm = undefined;
    }

    switch (currentFilter) {
      case FILTERS.SHOW_INBOX:
        desiredStatus = "open";
        break;
      case FILTERS.SHOW_IMPORTANT:
        tags = {
          $contains: ["important"]
        };
        desiredStatus = "open";
        break;
      case FILTERS.SHOW_SNOOZED:
        tags = {
          $contains: ["snoozed"]
        };
        desiredStatus = "open";
        break;
      case FILTERS.SHOW_ALL:
        desiredStatus = undefined;
        break;
      case FILTERS.SHOW_CLOSED:
        desiredStatus = "closed";
        break;
      default:
        desiredStatus = undefined;
    }

    this.setState(
      update(this.state, {
        tickets: {
          $merge: {
            loading: true,
            error: null
          }
        }
      })
    );

    let params = {
      query: {
        GroupId: id,
        $skip: skip,
        $sort: {
          status: -1,
          updatedAt: -1
        },
        tags: tags,
        status: desiredStatus,
        subject: !searchTerm
          ? undefined
          : {
            $ilike: `%${searchTerm}%`
          }
      }
    };

    const {
      user: { subscriptions }
    } = this.props.authentication.toJS();
    const sub = find(subscriptions, s => s.GroupId === parseInt(id));

    this.feathers
      .service("api/tickets")
      .find(params)
      .then(result => {
        //console.log("api tickets", result);
        this.setState(
          update(this.state, {
            tickets: {
              $merge: {
                ...result,
                loading: false,
                error: null
              }
            }
          }),
          () => {
            this.loadLastActivities();
          }
        );
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            tickets: {
              $merge: {
                loading: false,
                error
              }
            }
          })
        );
      });
  };

  getTicketsIds = () => {
    return this.state.tickets.data.map(aTicket => aTicket.id);
  };

  loadLastActivities = async (ticketIds = this.getTicketsIds()) => {
    this.setState(
      update(this.state, {
        lastTicketActivities: {
          $merge: {
            data: [],
            loading: true,
            error: null
          }
        }
      })
    );
    try {
      let lastActivities = await this.feathers
        .service("api/lastactivity")
        .find({
          query: {
            ids: ticketIds
          }
        });
      this.setState(
        update(this.state, {
          lastTicketActivities: {
            $merge: {
              data: lastActivities,
              loading: false,
              error: null
            }
          }
        })
      );
    } catch (error) {
      this.setState(
        update(this.state, {
          lastTicketActivities: {
            $merge: {
              data: [],
              loading: false,
              error
            }
          }
        })
      );
    }
  };

  ticketAddTag = (ticket, tag) => {
    this.feathers
      .service("api/tickets")
      .patch(ticket.id, {
        tags: [...ticket.tags, tag]
      })
      .then(
        response => {
          //console.log(response);
        },
        error => {
          //console.log(error);
        }
      );
  };

  render() {
    const { t } = this.props;
    const {
      group,
      tickets,
      lastTicketActivities,
      filtersOpen,
      userSubCreateModal
    } = this.state;
    const ticketsWithLastActivity = {
      ...tickets,
      data: tickets.data.map(aTicket => {
        const lastActivity =
          lastTicketActivities.data.find(
            anActivity => anActivity.TicketId == aTicket.id
          ) || null;
        return { ...aTicket, lastActivity };
      })
    };

    const isFilterActive = aFilter => {
      return tickets.currentFilter === aFilter;
    };

    const setSearchTerm = (_, { value }) => {
      this.setState({
        ...this.state,
        tickets: {
          ...this.state.tickets,
          searchTerm: value
        }
      });
    };

    const flipSort = () => {
      this.setState({
        ...this.state,
        tickets: {
          ...this.state.tickets,
          revertSort: !this.state.tickets.revertSort
        }
      });
    };

    const setFilter = aFilter => () => {
      this.setState({
        ...this.state,
        tickets: {
          ...this.state.tickets,
          currentFilter: aFilter || FILTERS.SHOW_ALL
        }
      });
    };

    let containerStyle = {
      paddingLeft: 16
    };

    if (this.props.width < 764) {
      containerStyle = {};
    }

    if (!!group.loading || !!tickets.loading || !!this.state.stats.loading) {
      return <LoadingIndicator />;
    }

    if (!!group.error) {
      return (
        <Container style={styles.container}>
          <Segment>
            <Message error header={group.error.message} />
          </Segment>
        </Container>
      );
    }

    if (
      (!group.detail || !group.detail.id) &&
      this.props.match.params.id != -1
    ) {
      return (
        <Container style={styles.container}>
          <Message warning header={t("group.messages.notExistGroup")} />
        </Container>
      );
    }
    let activeGroup = group;
    if (this.props.match.params.id == -1) {
      activeGroup = { detail: { id: -1, name: "Todos los grupos" } };
    }
    const {
      detail: { id, name, metadata, priority, ticketCount, GroupId, subgroups }
    } = activeGroup;

    const user = (
      this.props.authentication.get("user") || {
        toJS: () => ({ roles: [], subscriptions: [] })
      }
    ).toJS();
    const isAdmin =
      user.roles.indexOf("sysadmin") != -1 || user.roles.indexOf("admin") != -1;

    let { subscriptions } = user;
    subscriptions = subscriptions.map(aSub => {
      return {
        ...aSub,
        stats: this.state.stats.data.find(a => a.id == aSub.id) || {
          ticketcount: 0,
          openticketcount: 0
        }
      };
    });
    if (!!this.state.ticketsFilter) {
      //console.log("stats", this.state.stats.data);
      subscriptions = subscriptions.filter(a => a.stats.openticketcount != 0);
    }

    return (
      <Container fluid style={{ padding: 8 }}>
        <FilteredCollection
          selfContained={false}
          breadcrumb={
            <Breadcrumb style={{ paddingTop: 16 }}>
              <Breadcrumb.Section as={Link} to={Routes.DASHBOARD}>
                Principal
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section>Suscripciones</Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section as={Link} to={Routes.GROUPS}>
                Grupos
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>{name}</Breadcrumb.Section>
            </Breadcrumb>
          }
          header={
            <Header size="large">
              <Header.Content>
                {name.toUpperCase()}
                {!GroupId ? null : (
                  <Link to={Routes.GROUP_DETAIL.replace(":id", GroupId)}>
                    <Icon name="long arrow alternate up" />
                    <Icon name="folder open outline" />
                  </Link>
                )}
                <Header.Subheader>Suscripciones a grupos</Header.Subheader>
              </Header.Content>
            </Header>
          }
          stats={
            <Statistic.Group widths="one">
              <Statistic>
                <Statistic.Value>
                  <Icon name="ticket" />
                  {tickets.total}
                </Statistic.Value>
                <Statistic.Label>{t("group.tickets.label")}</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          }
          headerMobile={
            <Header size="large" textAlign="center">
              <Header.Content>
                {name.toUpperCase()}
                <Header.Subheader>Suscripciones a grupos</Header.Subheader>
              </Header.Content>
            </Header>
          }
          filter={
            <Accordion>
              <Accordion.Title
                active={filtersOpen}
                index={0}
                onClick={this.toggleFilters}
              >
                <Icon name="filter" />
                Filtros
                {/*<Button floated="right" content="Aplicar" size="tiny" />*/}
              </Accordion.Title>
              <Accordion.Content active={filtersOpen}>
                <Form onSubmit={this.applyFilters}>
                  <Menu vertical size="tiny" fluid>
                    <Menu.Item>
                      <Input
                        icon="search"
                        transparent
                        value={tickets.searchTerm}
                        placeholder="Buscar..."
                        onChange={setSearchTerm}
                      />
                    </Menu.Item>
                    <Menu.Item
                      name="inbox"
                      icon="inbox"
                      content="Inbox"
                      active={isFilterActive(FILTERS.SHOW_INBOX)}
                      onClick={setFilter(FILTERS.SHOW_INBOX)}
                    />
                    <Menu.Item
                      name="important"
                      icon="star"
                      content="Importantes"
                      active={isFilterActive(FILTERS.SHOW_IMPORTANT)}
                      onClick={setFilter(FILTERS.SHOW_IMPORTANT)}
                    />
                    <Menu.Item
                      name="snoozed"
                      icon="clock"
                      content="Pospuestos"
                      active={isFilterActive(FILTERS.SHOW_SNOOZED)}
                      onClick={setFilter(FILTERS.SHOW_SNOOZED)}
                    />
                    <Menu.Item
                      name="closed"
                      icon="circle outline"
                      content="Cerrados"
                      active={isFilterActive(FILTERS.SHOW_CLOSED)}
                      onClick={setFilter(FILTERS.SHOW_CLOSED)}
                    />
                    <Menu.Item
                      name="all"
                      name="Todos"
                      active={isFilterActive(FILTERS.SHOW_ALL)}
                      onClick={setFilter(FILTERS.SHOW_ALL)}
                    />
                  </Menu>
                </Form>
              </Accordion.Content>
              <Accordion.Title
                active={this.state.groupsOpen}
                index={1}
                onClick={this.toggleGroups}
              >
                <Icon name="object group" />
                Suscripciones
                {/*<Button floated="right" content="Aplicar" size="tiny" />*/}
              </Accordion.Title>
              <Accordion.Content active={this.state.groupsOpen}>
                <Checkbox
                  toggle
                  style={{ padding: 10 }}
                  label="Solo Grupos con Tickets"
                  checked={this.state.ticketsFilter}
                  onChange={() =>
                    this.setState({ ticketsFilter: !this.state.ticketsFilter })
                  }
                />
                <Form onSubmit={this.toggleGroups}>
                  <Menu vertical size="tiny" fluid>
                    <Menu.Item
                      name="all"
                      name="Todos los grupos"
                      active={this.props.match.params.id == -1}
                      onClick={() =>
                        this.props.navigate(
                          Routes.GROUP_DETAIL.replace(":id", -1)
                        )
                      }
                    />
                    {subscriptions.map(aSubscription => {
                      return (
                        <Menu.Item
                          key={aSubscription.id}
                          name={aSubscription.Group.name}
                          active={
                            this.props.match.params.id == aSubscription.GroupId
                          }
                          onClick={() =>
                            this.props.navigate(
                              Routes.GROUP_DETAIL.replace(
                                ":id",
                                aSubscription.GroupId
                              )
                            )
                          }
                        >
                          {aSubscription.stats.openticketcount <= 0 ? null : (
                            <Label
                              color={
                                this.props.match.params.id ==
                                aSubscription.GroupId
                                  ? "orange"
                                  : "grey"
                              }
                            >
                              {aSubscription.stats.openticketcount}
                            </Label>
                          )}

                          {aSubscription.Group.name}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                </Form>
              </Accordion.Content>
            </Accordion>
          }
          menu={
            <Menu key={0}>
              <Dropdown
                item
                icon="list"
                simple
                disabled={activeGroup.detail.id == -1}
              >
                <Dropdown.Menu>
                  <CDropdownItem
                    icon="add user"
                    content="Nuevo Usuario"
                    onClick={this.openCreateUserSubModal}
                  />
                </Dropdown.Menu>
              </Dropdown>
              <Menu.Item icon="refresh" onClick={this.loadTickets} />

              <Menu.Item
                disabled={activeGroup.detail.id == -1}
                icon="add"
                onClick={() =>
                  this.props.navigate(
                    Routes.TICKET_CREATE.replace(
                      ":GroupId",
                      this.props.match.params.id
                    )
                  )
                }
              />
              <Pagination
                pointing
                secondary
                firstItem={null}
                lastItem={null}
                activePage={1 + tickets.skip / 10}
                onPageChange={(_, { activePage }) => {
                  let skip = (activePage - 1) * 10;
                  this.loadTickets((activePage - 1) * 10);
                }}
                totalPages={Math.ceil(tickets.total / 10)}
              />
              <Menu.Item
                icon={
                  !tickets.revertSort
                    ? "sort alphabet ascending"
                    : "sort alphabet descending"
                }
                position="right"
                onClick={flipSort}
              />
            </Menu>
          }
          secondaryMenu={[
            <Menu
              text
              vertical
              size="large"
              fluid
              id="adminOptionsMenu"
              key="adminOptionsMenu"
              style={{ display: isAdmin ? undefined : "none" }}
            >
              <Menu.Item
                as={Link}
                to={Routes.ADMIN_RESOURCES_SUBSCRIPTIONS_FOR_GROUP.replace(
                  ":groupId",
                  id
                )}
              >
                Ir a Suscripciones
              </Menu.Item>
            </Menu>,
            <Accordion
              id="subgroupsMenu"
              key="subgroupsMenu"
              style={{
                marginTop: 16,
                display:
                  !!subgroups && subgroups.length > 0 ? undefined : "none"
              }}
            >
              <Accordion.Title
                active={true}
                index={0}
                onClick={this.toggleFilters}
              >
                <Icon name="group" />
                Subgrupos
                {/*<Button floated="right" content="Aplicar" size="tiny" />*/}
              </Accordion.Title>
              <Accordion.Content active={true}>
                <Menu text vertical size="large" fluid>
                  {(subgroups || []).map((aGroup, index) => {
                    return (
                      <Menu.Item
                        as={Link}
                        key={index}
                        to={Routes.GROUP_DETAIL.replace(":id", aGroup.id)}
                      >
                        {aGroup.name}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </Accordion.Content>
            </Accordion>
          ]}
          collectionView={
            <Segment key={1} loading={false} vertical>
              <GroupTicketsContainer
                tickets={ticketsWithLastActivity}
                ticketAddTag={this.ticketAddTag}
                revertSort={tickets.revertSort}
              />
            </Segment>
          }
        />
        <Modal
          size="small"
          open={userSubCreateModal.open}
          onClose={this.dismissCreateUserSubModal}
        >
          <Modal.Header>Alta de Usuario : {name}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <CreateUserSubForm
                services={this.props.services}
                group={{
                  id: activeGroup.detail.id,
                  name: activeGroup.detail.name
                }}
                dismiss={this.dismissCreateUserSubModal}
              />
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Container>
    );
  }
}

const StyledView = Radium(GroupDetailView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  GroupDetailView.mapStateToProps,
  GroupDetailView.mapDispatchToProps
)(i18nEnhancement);
