import { createReducer } from 'reduxsauce';
import {Types} from '../../actions/ticket';
import {
  fromJS
} from 'immutable';

// the initial state of this reducer
export const INITIAL_STATE = fromJS({ loading: false, error: null, entries: [], detail: null, total: 0 });

// attempting
export const findAttempt = (state = INITIAL_STATE, action) => {
  return state.merge({ loading: true, error: null, entries: [], total: 0, detail: null });
};

// the eagle has landed
export const findSuccess = (state = INITIAL_STATE, action) => {
  return state.merge({ loading: false, error: null, entries: action.entries, total: action.total, detail: null });
};

// uh oh
export const findError = (state = INITIAL_STATE, action) => {
  return state.merge({loading: false, error: action.error, entries: [], total: 0, detail: null});
};

export const getAttempt = (state = INITIAL_STATE, action) => {
  return state.merge({ loading: true, error: null, entries: [], total: 0, detail: null });
};

// the eagle has landed
export const getSuccess = (state = INITIAL_STATE, action) => {
  return state.merge({ loading: false, error: null, entries: action.entries, total: 0, detail: action.detail });
};

// uh oh
export const getError = (state = INITIAL_STATE, action) => {
  return state.merge({loading: false, error: action.error, entries: [], total: 0, detail: null});
};

export const createAttempt = (state = INITIAL_STATE, action) => {
  return state.merge({ loading: true, error: null});
};

export const createError = (state = INITIAL_STATE, action) => {
  return state.merge({ loading: false, error: action.error});
};

export const createSuccess = (state = INITIAL_STATE, action) => {
  return state.merge({ loading: false, error: null});
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.FIND_ATTEMPT]: findAttempt,
  [Types.FIND_SUCCESS]: findSuccess,
  [Types.FIND_ERROR]: findError,
  [Types.GET_ATTEMPT]: getAttempt,
  [Types.GET_SUCCESS]: getSuccess,
  [Types.GET_ERROR]: getError,
  [Types.CREATE_ATTEMPT]: createAttempt,
  [Types.CREATE_ERROR]: createError,
  [Types.CREATE_SUCCESS]: createSuccess
};

export default createReducer(INITIAL_STATE, HANDLERS);