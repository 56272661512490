import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';
import {
  createLogger
} from 'redux-logger';
import createHistory from 'history/createBrowserHistory';
import {
  routerMiddleware
} from 'react-router-redux';
import rootReducer from './reducers';

let middleware = [];

export const history = createHistory();

const logger = createLogger({
  collapsed: true,
  stateTransformer: (state) => { // transform Immutable Structures to common plain JS Objects
    let tState = {};

    Object.keys(state).forEach(key => {
      if (typeof state[key].toJS === 'function') {
        tState[key] = state[key].toJS({ deep: true });
      } else {
        tState[key] = state[key];
      }
    });

    return tState;
  }
});

middleware.push(logger);
middleware.push(routerMiddleware(history));

export default () => {
  const enhancers = compose(
    applyMiddleware(...middleware)
  );

  let store = createStore(
    rootReducer,
    enhancers
  );

  return store;
};

