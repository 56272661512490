import {
  fromJS
} from 'immutable';
import {
  handleActions
} from 'redux-actions';
import * as Types from '../../actions/types';

const INITIAL_STATE = fromJS({
  navigationSidebar: false
});

const visibilityReducer = handleActions({
  [Types.TOGGLE_NAVIGATION_SIDEBAR]: (state, action) => state.set('navigationSidebar', !state.get('navigationSidebar'))
}, INITIAL_STATE);


export default visibilityReducer;
