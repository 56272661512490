import React, { Component } from "react";
import {
  Button,
  Breadcrumb,
  Container,
  Divider,
  Header,
  Icon,
  Grid,
  Form,
  Segment,
  Dimmer,
  Loader,
  Message
} from "semantic-ui-react";
import { translate } from "react-i18next";
import Radium from "radium";
import ConnectServices from "../../providers/ConnectServices";
import colorPalette from "../../styles/colorPalette";
import authenticate from "../../authenticate";
import * as ROUTES from "../../routes";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import AuthManagement from "feathers-authentication-management/lib/client";
const styles = {};

class ForgotView extends Component {
  constructor (props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      loading: false,
      error: null
    };
    this.FeathersService = this.props.services.feathers;
    this.authenticate = authenticate(this.FeathersService, props.dispatch);
  }

  static mapStateToProps (state) {
    return {
      routing: state.routing,
      authentication: state.authentication
    };
  }

  static mapDispatchToProps (dispatch) {
    return {
      navigate: path => dispatch(push(path)),
      dispatch
    };
  }

  passwordReset = async (username, email) => {
    let identifyUser;
    if (!!username && username.length > 0) {
      identifyUser = { username };
    } else if (!!email && email.length > 0 && email.indexOf("@") != -1) {
      identifyUser = { email };
    } else {
      return;
    }
    const authManagement = new AuthManagement(this.FeathersService);
    const response = await authManagement.sendResetPwd(identifyUser, {
      preferredComm: "email"
    });
    return response;
  };

  _onChange = (event, { name, value }) => {
    this.setState({
      [name]: value
    });
  };

  submit = () => {
    let { email, username } = this.state;
    if (email.length < 1 && username.length < 1) {
      return;
    }
    this.setState({
      loading: true,
      error: null,
      message: null,
      email: "",
      username: ""
    });
    this.passwordReset(username, email)
      .then(response => {
        this.setState({
          loading: false,
          message: `We sent you an email to ${response.email}`,
          error: null
        });
      })
      .catch(e => {
        this.setState({
          error: e,
          loading: false,
          message: null
        });
      });
  };

  render () {
    const { t } = this.props;
    const { username, email, loading, error, message } = this.state;
    return (
      <Container style={{ padding: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Section link>{t("main")}</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link>{t("signIn.name")}</Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Grid centered>
          <Grid.Column width="8">
            <Segment attached="top" color="blue">
              <Header size="large">
                <Icon name="key" />
                {t("forget.title")}
                <Header.Subheader>{t("forget.subtitle")}</Header.Subheader>
              </Header>
            </Segment>
            <Form
              onSubmit={this.submit}
              error={!!error && !loading}
              loading={loading}
              success={!loading && !error && !!message}
            >
              <Message
                success
                icon="mail"
                header="Success!"
                content={message || null}
              />
              <Form.Input
                label={t("forget.username")}
                onChange={this._onChange}
                name="username"
                value={username}
                placeholder={t("forget.username")}
                type="text"
              />
              <Divider horizontal>O</Divider>
              <Form.Input
                label={t("forget.email")}
                onChange={this._onChange}
                name="email"
                value={email}
                placeholder={t("forget.email")}
                type="text"
              />
              <Form.Button
                content={t("forget.submit")}
                disabled={email.length < 1 && username.length < 1}
              />
            </Form>
            <Message
              hidden={!error}
              header={(!!error && error.message) || null}
              content={!!error && JSON.stringify(error)}
              error
            />
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const i18nEnhancement = translate("translations")(ForgotView);
const StyledView = Radium(i18nEnhancement);
const withServices = ConnectServices(StyledView);

export default connect(
  ForgotView.mapStateToProps,
  ForgotView.mapDispatchToProps
)(withServices);
