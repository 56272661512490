import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import createStore, { history } from './store';
import './i18n';
import * as ROUTES from './routes';
import ServiceProvider from './providers/ServiceProvider';
import FeathersService from './services/FeathersService';
import * as Views from './components/views';
import storeActions from './store/actions';

const store = createStore();
const services = {
  feathers: FeathersService
};

class App extends Component {
  render () {
    return (
      <Views.ErrorView>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ServiceProvider services={services} storeActions={storeActions} >
              <Route path={ROUTES.ROOT} component={Views.RootView} />
            </ServiceProvider>
          </ConnectedRouter>
        </Provider>
      </Views.ErrorView>
    );
  }
}

export default App;
