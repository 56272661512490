import React, { Component } from "react";
import "./style.css";
import {
  Button,
  Breadcrumb,
  Progress,
  Container,
  Divider,
  Header,
  Modal,
  Image,
  Icon,
  List,
  Grid,
  Segment,
  Loader,
  Message,
  Label,
  Popup
} from "semantic-ui-react";
import { translate } from "react-i18next";
import Radium from "radium";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Routes from "../../../routes";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import update from "immutability-helper";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const styles = {};
const INITIAL_STATE = {
  text: "",
  images: [],
  loadingImage: false,
  progress: 0,
  subscriptionModal: {
    open: false,
    loading: false
  },
  agents: {
    loading: false,
    error: null,
    data: []
  },
  watchlistModal: {
    open: false,
    loading: false
  },
  watchlists: {
    loading: false,
    error: null,
    data: []
  }
};

class TicketView extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
    this.ticketActions = this.props.storeActions.tickets;
    this.feathers = this.FeathersService = this.props.services.feathers;
    this.UserService = props.services.feathers.service("api/users");
    this.SubscriptionService = props.services.feathers.service(
      "api/subscriptions"
    );
    this.WatchListService = props.services.feathers.service("api/watchlists");
    this.WatchListTicketsService = props.services.feathers.service(
      "api/watchlist-tickets"
    );
  }

  findWatchLists = () => {
    this.setState({
      watchlists: {
        ...this.state.watchlists,
        loading: true
      }
    });

    return this.WatchListService.find({
      query: {
        ownerFilter: "owned"
      }
    })
      .then(response => {
        //console.log(response);
        this.setState({
          watchlists: {
            ...this.state.watchlists,
            loading: false,
            data: response
          }
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          watchlists: {
            loading: false,
            error
          }
        });
      });
  };

  removeImage = imageItem => {
    this.setState({
      images: this.state.images.filter(i => i.uri != imageItem.uri)
    });
  };

  componentDidMount() {
    moment.locale(window.navigator.language);
    this.loadTicket();
    document.title = `Ticket #${this.props.match.params.id} - Beehive`;
    window.onfocus = () => {
      document.title = `Ticket #${this.props.match.params.id} - Beehive`;
    };
    this.channelSubscription = this.feathers
      .service("api/ticket-activities")
      .on("created", this._activityListener);
  }

  componentWillUnmount() {
    this.feathers
      .service("api/ticket-activities")
      .removeListener("created", this._onActivityCreatedListener);
    window.onfocus = null;
  }

  _activityListener = activity => {
    if (activity.TicketId == (this.props.tickets.detail || {}).id) {
      this.loadTicket();
      if (!document.hasFocus()) {
        document.title = `(*)Ticket #${this.props.match.params.id} - Beehive`;
      }
    }
  };

  markSeen = ticketId => {
    this.feathers.service("api/seen-ticket").get(ticketId);
  };

  static mapStateToProps(state) {
    return {
      routing: state.routing,
      authentication: state.authentication,
      tickets: state.tickets.toJS()
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !!prevProps.tickets.loading &&
      !this.props.tickets.loading &&
      !!this.props.tickets &&
      !!this.props.tickets.detail
    ) {
      var element = document.getElementById("lastActivity");
      if (!!element) {
        element.scrollIntoView(false);
      }
    }
  }

  writePermissionsCalculate = () => {
    const { authentication, tickets } = this.props;
    const { detail } = tickets;
    const user = authentication.get("user").toJS();
    let subscription = user.subscriptions.find(
      s => s.GroupId == detail.GroupId
    );

    if (!subscription) {
      return { canWrite: false, message: "noSubscription", role: null };
    }

    const { permissions } = subscription;
    const role =
      permissions.indexOf("clientRole") != -1
        ? "clientRole"
        : permissions.indexOf("agentRole") != -1
          ? "agentRole"
          : null;

    if (!role) {
      return { canWrite: false, role: null, message: "noRole" };
    }

    if (permissions.indexOf("writeAll") != -1) {
      return { canWrite: true, message: "", role };
    }

    if (permissions.indexOf("writeOwn") != -1 && user.id == detail.UserId) {
      return { canWrite: true, message: "", role };
    }

    return { canWrite: false, message: "notOwn", role };
  };

  createMessage = (body, images = []) => {
    const writePermissions = this.writePermissionsCalculate();

    if (!writePermissions.canWrite) {
      return Promise.reject(false);
    }

    if (!writePermissions.role) {
      return Promise.reject(false);
    }

    let type =
      writePermissions.role == "clientRole"
        ? "message"
        : writePermissions.role == "agentRole"
          ? "messageReply"
          : null;

    if (!type) {
      return Promise.reject(false);
    }

    const { detail } = this.props.tickets;
    const additionalActivities = images.map(uri => {
      let mediaType = "attachment";

      if (
        !!`${uri}`
          .split(".")
          .pop()
          .match(/^(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i)
      ) {
        mediaType = "image";
      }

      return { body: uri, type, mediaType };
    });

    if (detail.status == "closed") {
      return this.openTicket().then(r => {
        if (r.status == "open") {
          return this.createActivity({ body, type }, additionalActivities);
        }
      });
    }

    return this.createActivity({ body, type }, additionalActivities);
  };

  createActivity = (activity, additionalActivities = []) => {
    const { dispatch, authentication, tickets } = this.props;
    const { detail } = tickets;
    const user = authentication.get("user").toJS();
    const { id } = this.props.match.params;
    const completeActivity = {
      ...activity,
      id: undefined,
      UserId: user.id,
      mediaType: "html",
      UserShortDescription: user.fullName,
      TicketId: id,
      TicketCreatorId: detail.UserId
    };

    const completeAdditionalActivities = additionalActivities.map(a => {
      return {
        ...a,
        id: undefined,
        UserId: user.id,
        TicketId: id,
        UserShortDescription: user.fullName,
        TicketCreatorId: detail.userId
      };
    });

    dispatch(this.ticketActions.createAttempt(completeActivity));

    return this.feathers
      .service("api/ticket-activities")
      .create(
        completeActivity.mediaType == "html" &&
        completeActivity.body.length <= 0 &&
        completeAdditionalActivities.length > 0
          ? [...completeAdditionalActivities]
          : [completeActivity, ...completeAdditionalActivities]
      )
      .then(result => {
        dispatch(this.ticketActions.createSuccess(result));
        this.loadTicket();
        this.setState({
          text: "",
          images: []
        });
        return result;
      })
      .catch(error => {
        dispatch(this.ticketActions.createError(error));
      });
  };

  loadTicket = () => {
    const { dispatch } = this.props;
    const { id } = this.props.match.params;

    this.markSeen(id);

    dispatch(this.ticketActions.getAttempt());

    this.feathers
      .service("api/tickets")
      .get(id)
      .then(result => {
        dispatch(this.ticketActions.getSuccess(result));
      })
      .catch(error => {
        dispatch(this.ticketActions.getError(error));
      });
  };

  stateTicket = (status = "closed") => {
    const { dispatch } = this.props;
    const { id } = this.props.match.params;

    dispatch(this.ticketActions.createAttempt());

    return this.feathers
      .service("api/tickets")
      .patch(id, { status })
      .then(result => {
        dispatch(this.ticketActions.createSuccess(result));

        this.loadTicket();
        this.setState({
          text: ""
        });

        return result;
      })
      .catch(error => {
        dispatch(this.ticketActions.createError(error));
      });
  };

  closeTicket = () => {
    return this.stateTicket();
  };

  openTicket = () => {
    return this.stateTicket("open");
  };

  handleChange = value => {
    this.setState({ text: value });
  };

  modules = {
    /*,
      handleUri: (base64, type) => {
        this.setState({
          loadingImage: true
        });
        this.feathers.service('api/upload').create({uri: base64})
          .then(response => {
            //console.log('upload response', response);
            this.setState({
              images: [...this.state.images, {uri: `https://s3.us-east-2.amazonaws.com/beehive-store/${response.id}`, type}],
              loadingImage: false
            });
          }).catch(e => {
            //console.log('upload error', e);
            this.setState({
              loadingImage: false
            })
          })
      }*/
  };

  renderMessageContent = anActivity => {
    const seenBy = anActivity.seenBy
      .map(seenString => {
        let params = seenString.split("#");

        return {
          id: params[0],
          timestamp: params[1],
          displayName: params[2]
        };
      })
      .filter(s => s.id != anActivity.UserId);

    return [
      <div key={0}>
        {seenBy.length > 0
          ? `Seen by ${seenBy.map(s => s.displayName).join(", ")}`
          : null}
      </div>,
      <Message.Header key={1}>{anActivity.User.fullName}</Message.Header>,
      anActivity.mediaType == "html" ? (
        <div
          key={2}
          dangerouslySetInnerHTML={{ __html: anActivity.body }}
          style={{ height: "auto", wordWrap: "break-word", maxWidth: "60vw" }}
        />
      ) : null,
      anActivity.mediaType == "image" ? (
        <Modal
          key={3}
          trigger={
            <Image
              as="a"
              href="#"
              size="medium"
              style={{
                height: 150,
                overflow: "hidden",
                objectFit: "cover",
                margin: 5
              }}
              src={anActivity.body}
            />
          }
          header={anActivity.User.fullName}
          content={
            <center>
              <Image large src={anActivity.body} />
            </center>
          }
          actions={["Salir"]}
        />
      ) : null
    ];
  };

  findAgents = () => {
    this.setState(
      {
        agents: {
          ...this.state.agents,
          loading: true,
          error: null
        }
      },
      () => {
        this.UserService.find({
          query: {
            $or: [
              {
                roles: {
                  $contains: ["agent"]
                }
              },
              {
                roles: {
                  $contains: ["sysadmin"]
                }
              }
            ]
          }
        }).then(
          response => {
            //console.log(response);
            this.setState({
              agents: {
                ...this.state.agents,
                loading: false,
                data: response.data
              }
            });
          },
          error => {
            this.setState({
              agents: {
                ...this.state.agents,
                loading: false,
                error
              }
            });
          }
        );
      }
    );
  };

  openSubscriptionModal = () => {
    this.setState(
      {
        subscriptionModal: {
          ...this.state.subscriptionModal,
          open: true
        }
      },
      this.findAgents
    );
  };

  dismissSubscriptionModal = () => {
    this.setState({
      subscriptionModal: INITIAL_STATE.subscriptionModal
    });
  };

  assignOrRemoveTicketSubscription = (
    { agent, ticket, subscription },
    subType
  ) => {
    this.setState(
      {
        subscriptionModal: {
          ...this.state.subscriptionModal,
          loading: true,
          error: null
        }
      },
      () => {
        const successCallback = () => {
          this.setState(
            {
              subscriptionModal: {
                ...this.state.subscriptionModal,
                loading: false
              }
            },
            this.findAgents
          );
        };

        const errorCallback = error => {
          this.setState({
            subscriptionModal: {
              ...this.state.subscriptionModal,
              loading: false,
              error
            }
          });
        };

        //console.log("subType", subType);

        if (subType) {
          if (subType === "has-ticket") {
            const watchedTickets = subscription.watchedTickets.filter(
              t => t !== ticket.id
            );

            if (watchedTickets.length === 0) {
              this.SubscriptionService.remove(subscription.id).then(
                successCallback,
                errorCallback
              );
            } else {
              this.SubscriptionService.patch(subscription.id, {
                watchedTickets
              }).then(successCallback, errorCallback);
            }
          } else if (subType === "with-ticket") {
            const watchedTickets = subscription.watchedTickets.slice();
            watchedTickets.push(ticket.id);

            //console.log("derp!", watchedTickets);

            this.SubscriptionService.patch(subscription.id, {
              watchedTickets
            }).then(successCallback, errorCallback);
          } else {
            successCallback();
          }
        } else {
          this.SubscriptionService.create({
            GroupId: ticket.GroupId,
            UserId: agent.id,
            watchedTickets: [ticket.id],
            permissions: ["writeAll", "readSpecific", "agentRole"],
            notifications: {},
            metadata: {}
          }).then(successCallback, errorCallback);
        }
      }
    );
  };

  openWatchlistModal = () => {
    this.setState(
      {
        watchlistModal: {
          ...this.state.watchlistModal,
          open: true
        }
      },
      this.findWatchLists
    );
  };

  dismissWatchlistModal = () => {
    this.setState({
      watchlistModal: INITIAL_STATE.watchlistModal
    });
  };

  assignOrRemoveTicketToWatchlist = (watchlist, includesTicket) => {
    const { tickets } = this.props;

    this.setState({
      watchlistModal: {
        ...this.state.watchlistModal,
        loading: true
      }
    });

    if (includesTicket) {
      this.WatchListTicketsService.remove({
        TicketId: tickets.detail.id,
        GroupId: watchlist.id
      }).then(
        response => {
          this.setState(
            {
              watchlistModal: {
                ...this.state.watchlistModal,
                loading: false
              }
            },
            this.findWatchLists
          );
        },
        error => {
          this.setState({
            watchlistModal: {
              ...this.state.watchlistModal,
              loading: false,
              error
            }
          });
        }
      );
    } else {
      this.WatchListTicketsService.create({
        TicketId: tickets.detail.id,
        GroupId: watchlist.id
      }).then(
        response => {
          this.setState(
            {
              watchlistModal: {
                ...this.state.watchlistModal,
                loading: false
              }
            },
            this.findWatchLists
          );
        },
        error => {
          this.setState({
            watchlistModal: {
              ...this.state.watchlistModal,
              loading: false,
              error
            }
          });
        }
      );
    }
  };

  handleInit = () => {
    this.pond.allowPaste = true;
    this.pond.dropOnPage = true;
  };

  render() {
    const { t, tickets, authentication } = this.props;
    const {
      watchlistModal,
      subscriptionModal,
      agents,
      watchlists
    } = this.state;

    if (!!tickets.loading) {
      return (
        <Container style={styles.container}>
          <Loader active />
        </Container>
      );
    }

    if (!authentication.get("user")) {
      return (
        <Container style={styles.container}>
          <Loader active />
        </Container>
      );
    }

    if (!!tickets.error) {
      return (
        <Container style={styles.container}>
          <Segment>
            <Message error header={tickets.error.message} />
          </Segment>
        </Container>
      );
    }

    if (!tickets.detail || !tickets.detail.id) {
      return (
        <Container style={styles.container}>
          <Message warning header={t("ticket.messages.notExistGroup")} />
        </Container>
      );
    }

    const { detail } = tickets;
    const user = authentication.get("user").toJS();
    const writePermissions = this.writePermissionsCalculate();

    const goRight = anActivity => {
      let goRight = anActivity.UserId == user.id;

      return goRight;
    };

    const onProcessFile = (error, file) => {
      alert(file);
    };

    return (
      <Container style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Section as={Link} to={Routes.DASHBOARD}>
            Principal
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section
            as={Link}
            to={Routes.GROUP_DETAIL.replace(":id", detail.Group.id)}
          >
            {detail.Group.name}
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section>Tickets</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>
            #{this.props.match.params.id}
          </Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column witdh={16}>
              <Header dividing size="large">
                <Header.Content>
                  {detail.subject}
                  <Header.Subheader>{detail.User.fullName}</Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={16} stretched>
              <Header size={"small"}>
                <Icon name="tag" />
                <Header.Content style={{ width: "100%" }}>
                  <Header.Subheader>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div>
                        {!!detail.tags &&
                          detail.tags.map(aTag => (
                            <Label key={aTag}>{aTag}</Label>
                          ))}
                      </div>
                      {detail.status == "open" ? (
                        <Button.Group>
                          <Button
                            icon="star outline"
                            color="orange"
                            size="tiny"
                          />
                          <Button icon="clock outline" size="tiny" />
                          <Button
                            icon="calendar plus"
                            color="blue"
                            size="tiny"
                            onClick={this.openWatchlistModal}
                          />
                          <Button
                            content="Asignar"
                            size="tiny"
                            secondary
                            compact
                            onClick={this.openSubscriptionModal}
                          />
                          <Button.Or text="" />
                          <Button
                            content="Cerrar Ticket"
                            size="tiny"
                            negative
                            compact
                            onClick={this.closeTicket}
                          />
                        </Button.Group>
                      ) : (
                        <Button
                          content="Abrir Ticket"
                          size="tiny"
                          positive
                          compact
                          onClick={this.openTicket}
                        />
                      )}
                    </div>
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Segment
                attached="top"
                id="someid"
                color="blue"
                style={{ height: "50vh", overflowY: "scroll" }}
              >
                {detail.activities.map((anActivity, idx) => {
                  let tagId =
                    idx == detail.activities.length - 1
                      ? "lastActivity"
                      : undefined;

                  if (anActivity.type == "publicEvent") {
                    return (
                      <div
                        id={tagId}
                        style={{
                          clear: "both",
                          marginTop: "20px",
                          float: "left",
                          width: "100%"
                        }}
                        key={`activity-${anActivity.id}`}
                      >
                        <Message
                          floating={true}
                          style={{ textAlign: "center" }}
                          color={"yellow"}
                        >
                          <Message.Header>
                            {anActivity.User.fullName}
                          </Message.Header>
                          {t(`ticket.events.${anActivity.body}`)}{" "}
                          {moment(anActivity.createdAt).format(
                            "HH:mm DD/MM/YYYYY"
                          )}
                          .
                        </Message>
                      </div>
                    );
                  }

                  return (
                    <div
                      id={tagId}
                      style={{
                        float: goRight(anActivity) ? "right" : "left",
                        clear: "both"
                      }}
                      key={`activity-${anActivity.id}`}
                    >
                      <Popup
                        trigger={
                          <div
                            className={[
                              goRight(anActivity)
                                ? "speech-bubble-right"
                                : "speech-bubble-left"
                            ]}
                          >
                            <Message
                              compact={true}
                              color={
                                anActivity.UserId == user.id ? "blue" : "grey"
                              }
                            >
                              {this.renderMessageContent(anActivity)}
                            </Message>
                          </div>
                        }
                        content={moment(anActivity.createdAt).fromNow()}
                        inverted
                        position={
                          goRight(anActivity) ? "left center" : "right center"
                        }
                      />
                    </div>
                  );

                  return [
                    <div key={`activity-${anActivity.id}`} id={tagId}>
                      <Segment basic>
                        <Popup
                          trigger={
                            <Message
                              style={
                                goRight(anActivity)
                                  ? { float: "right", textAlign: "right" }
                                  : anActivity.type == "message" ||
                                    anActivity.type == "messageReply"
                                    ? { textAlign: "left", float: "left" }
                                    : { textAlign: "center" }
                              }
                              key={anActivity.id}
                              compact={
                                anActivity.type == "message" ||
                                anActivity.type == "messageReply" ||
                                undefined
                              }
                              floating={
                                !anActivity.type == "message" &&
                                !anActivity.type == "messageReply"
                              }
                              color={
                                anActivity.type == "message" ||
                                anActivity.type == "messageReply"
                                  ? anActivity.UserId == user.id
                                    ? "blue"
                                    : "grey"
                                  : "yellow"
                              }
                            >
                              {this.renderMessageContent(anActivity)}
                            </Message>
                          }
                          content={moment(anActivity.createdAt).fromNow()}
                          inverted
                          position={
                            goRight(anActivity) ? "left center" : "right center"
                          }
                        />
                      </Segment>
                    </div>,
                    <br key={"br"} />
                  ];
                })}
              </Segment>
              <Segment attached>
                <Segment basic loading={!!this.state.loadingImage}>
                  {!writePermissions.canWrite ? (
                    <Message
                      negative
                      header={t("ticket.messages.cantWrite")}
                      content={t(`ticket.messages.${writePermissions.message}`)}
                      icon="sad face"
                    />
                  ) : (
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={16} stretched>
                          <ReactQuill
                            value={this.state.text}
                            onChange={this.handleChange}
                            modules={this.modules}
                            style={{ marginBottom: 10 }}
                          />
                          <FilePond
                            ref={ref => {
                              this.pond = ref;
                            }}
                            allowMultiple={true}
                            maxFiles={5}
                            dropOnPage={true}
                            name="uri"
                            server="/api/file"
                            oninit={this.handleInit}
                            onprocessfile={e => {
                              // Set current file objects to this.state
                              //console.log("e", e);
                              this.setState({
                                images: this.pond.getFiles()
                              });
                            }}
                            onupdatefiles={fileItems => {
                              // Set current file objects to this.state
                              //console.log("fileItems", fileItems);
                              this.setState({
                                images: fileItems
                              });
                            }}
                          />
                          {/* <Image.Group size="small">
                            {this.state.images.map((anImage, x) => {
                              return (
                                <Image
                                  style={{
                                    height: 100,
                                    overflow: "hidden",
                                    objectFit: "cover",
                                    margin: 5,
                                    marginBottom: 0,
                                    marginTop: 10,
                                    backgroundColor: "grey"
                                  }}
                                  key={`image-${x}attachment`}
                                  src={anImage}
                                  label={{
                                    as: "a",
                                    href: "#",
                                    style: { marginRight: -3.5 },
                                    onClick: () => {
                                      this.removeImage(anImage);
                                    },
                                    color: "red",
                                    corner: "right",
                                    icon: "trash"
                                  }}
                                />
                              );
                            })}
                          </Image.Group> */}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Button
                            icon="send"
                            color="blue"
                            size="big"
                            content="Enviar"
                            disabled={
                              this.state.text == "" &&
                              (this.state.images.length <= 0 ||
                                this.state.images.reduce((p, a) => {
                                  //console.log("p", p, "a", a.serverId);
                                  return p || !a.serverId;
                                }, false))
                            }
                            onClick={() =>
                              this.createMessage(
                                this.state.text,
                                this.state.images.map(a =>
                                  a.serverId.replace(/"/g, "")
                                )
                              )
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                </Segment>
                {!this.state.loadingImage ? null : (
                  <Progress
                    percent={this.state.progress}
                    indicating
                    autoSuccess
                  />
                )}
              </Segment>
              <Segment
                attached="bottom"
                secondary
                style={{ marginBottom: 128 }}
              >
                Estado: {detail.status}
                <br />
                {Object.keys(detail.extraFields)
                  .map(aKey => `${aKey}: ${detail.extraFields[aKey]}`)
                  .join(", ")}
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column />
          </Grid.Row>
        </Grid>
        <Modal
          open={subscriptionModal.open}
          onClose={this.dismissSubscriptionModal}
        >
          <Modal.Header>Asignar a Agente</Modal.Header>
          <Modal.Content
            as={Segment}
            loading={subscriptionModal.loading || agents.loading}
            vertical
          >
            <List divided relaxed>
              {agents.data.map((agent, index) => {
                let subType = null;
                let sub = null;
                const includesSubscription = agent.subscriptions.some(
                  subscription => {
                    if (subscription.GroupId === tickets.detail.GroupId) {
                      sub = subscription;
                      if (subscription.watchedTickets) {
                        if (
                          subscription.watchedTickets.includes(
                            tickets.detail.id
                          )
                        ) {
                          subType = "has-ticket";
                        } else {
                          subType = "with-ticket";
                        }
                      } else {
                        subType = "regular";
                      }

                      return true;
                    }

                    return false;
                  }
                );

                //console.log(agent.fullName, subType);

                let textColor;
                let textContent;

                if (includesSubscription) {
                  switch (subType) {
                    case "has-ticket":
                      textColor = "red";
                      textContent = "Remover de ";
                      break;
                    case "with-ticket":
                      textColor = "blue";
                      textContent = "Agregar a ";
                      break;
                    case "regular":
                      textColor = "grey";
                      textContent = "(Subscripto) ";
                      break;
                  }
                } else {
                  textColor = "grey";
                  textContent = "Asignar a ";
                }

                return (
                  <List.Item key={index}>
                    <List.Content>
                      <List.Header
                        as="a"
                        onClick={this.assignOrRemoveTicketSubscription.bind(
                          null,
                          { agent, ticket: tickets.detail, subscription: sub },
                          subType
                        )}
                      >
                        <span
                          style={{
                            color: textColor,
                            fontWeight: "normal"
                          }}
                        >
                          {textContent}
                        </span>
                        {agent.fullName}
                      </List.Header>
                      <List.Description>{agent.email}</List.Description>
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
          </Modal.Content>
        </Modal>
        <Modal open={watchlistModal.open} onClose={this.dismissWatchlistModal}>
          <Modal.Header>Asignar a Watchlist</Modal.Header>
          <Modal.Content
            as={Segment}
            loading={watchlistModal.loading || watchlists.loading}
            vertical
          >
            <List divided relaxed>
              {watchlists.data.map((watchlist, index) => {
                const includesTicket = watchlist.watchedTickets.find(
                  t => t.id === tickets.detail.id
                );

                return (
                  <List.Item key={index}>
                    <List.Content>
                      <List.Header
                        as="a"
                        onClick={this.assignOrRemoveTicketToWatchlist.bind(
                          null,
                          watchlist,
                          includesTicket
                        )}
                      >
                        <span
                          style={{
                            color: includesTicket ? "red" : "blue",
                            fontWeight: "normal"
                          }}
                        >
                          {includesTicket ? "Remover de " : "Asignar a "}
                        </span>
                        {watchlist.name}
                      </List.Header>
                      <List.Description>
                        {watchlist.description}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
          </Modal.Content>
        </Modal>
      </Container>
    );
  }
}

const StyledView = Radium(TicketView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  TicketView.mapStateToProps,
  TicketView.mapDispatchToProps
)(i18nEnhancement);
