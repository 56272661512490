import React, { Component } from 'react';
import { Container, Segment, Header, Icon } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import Authorize from '../Authorize';

class WelcomeView extends Component {

  constructor (props) {
    super(props);
  }

  render () {
    const { t } = this.props;
    return (
      <Container>
        <Segment color="blue" size="massive">
          <Header as="h2" icon textAlign="center">
            <Icon name="users" circular />
            <Header.Content>
              Welcome
            </Header.Content>
          </Header>
        </Segment>
      </Container>
    );
  }

}

const i18nEnhancement = translate('translations')(WelcomeView);

export default i18nEnhancement;