import React from 'react';
import { Icon, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../routes';

const SectionList = ({ loading, sections, openUpsertSectionModal }) => {
  if (loading) {
    return (
      <div>
        Cargando ...
      </div>
    );
  }

  return (
    <List>
      {
        sections.map((section, index) => (
          <List.Item key={index}>
            <List.Content>
              <List.Description>{section.name} <Icon name="edit" onClick={openUpsertSectionModal.bind(null, section)} /></List.Description>
              <List.List>
                {
                  section.subsections.map((subsection, index) => (
                    <List.Item as={Link} to={`/reload?path=${ROUTES.DOCUMENTATION}/${subsection.id}`} active key={index}>
                      <List.Icon name="caret right" />
                      <List.Content>
                        <List.Description style={{ color: '#ef6612' }}><strong>{subsection.name}</strong></List.Description>
                      </List.Content>
                    </List.Item>
                  ))
                }
              </List.List>
            </List.Content>
          </List.Item>
        ))
      }
    </List>
  );
};

export default SectionList;