import React, { Component } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Segment,
  Message,
  Loader
} from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { translate } from "react-i18next";
import * as ROUTES from "../../routes";
import ConnectServices from "../../providers/ConnectServices";
import PasswordChangeForm from "../PasswordChangeForm";
import AuthManagement from "feathers-authentication-management/lib/client";

const styles = {
  container: {
    paddingTop: 32,
    paddingBottom: 32
  }
};

class VerifyView extends Component {
  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      newPassword: "",
      passwordDuplicate: ""
    };

    this.FeathersService = this.props.services.feathers;
    this.AuthManagement = AuthManagement(this.FeathersService);
    //console.log(this.AuthManagement);
  }

  createInputHandler (name) {
    return (e, input) => {
      this.setState({
        [name]: e.target.value
      });
    };
  }

  componentWillReceiveProps (nextProps) {
    //console.log("componentWillReceiveProps");
    if (nextProps.match.type != this.props.match.type) {
      this.send();
    }
  }

  componentDidMount () {
    //console.log("componentDidMountWithProps", this.props);
    this.send();
  }

  send () {
    const {
      routing,
      match: {
        params: { type, slug }
      }
    } = this.props;
    //console.log("componentDidMountWithProps", this.props);
    this.submitRequest(type, slug);
  }

  submitRequest (
    type = this.props.match.params.type,
    slug = this.props.match.params.slug
  ) {
    this.setState({
      loading: true,
      error: null
    });
    const { newPassword, passwordDuplicate } = this.state;
    //console.log("TYPE", type, "SLUG", slug);
    if (type == "verify") {
      this.AuthManagement.verifySignupLong(slug)
        .then(result => {
          this.setState({
            loading: false,
            error: null
          });
          this.props.navigate(ROUTES.LOGIN);
        })
        .catch(error => {
          this.setState({
            loading: false,
            error
          });
          console.error("error verifying!", error);
        });
    }

    if (type == "verifyChanges") {
    }

    if (type == "reset") {
      this.setState({
        error: null
      });
      if (!newPassword && !passwordDuplicate) {
        this.setState({
          error: null,
          loading: false
        });
        return;
      }
      if (newPassword != passwordDuplicate) {
        this.setState({
          loading: false,
          error: {
            name: "InvalidFields",
            message: "Passwords not equal"
          }
        });
        return;
      }
      this.AuthManagement.resetPwdLong(slug, newPassword)
        .then(result => {
          this.setState(
            {
              loading: false,
              error: null
            },
            () => {
              this.props.navigate(ROUTES.LOGIN);
            }
          );
        })
        .catch(error => {
          this.setState({
            loading: false,
            error
          });
          console.error("error sending reset!", error);
        });
    }

    if (type == "changePassword") {
    }

    if (type == "changeEmail") {
    }
    // TODO: Write logic of password recovery
    // this.FeathersService.authenticate({
    //   strategy: 'local',
    //   username: this.state.username,
    //   password: this.state.password
    // })
    //   .then(response => {
    //     this.setState({
    //       loading: false
    //     }, () => {
    //       this.props.navigate(ROUTES.DASHBOARD);
    //     });
    //   })
    //   .catch(error => {
    //     this.setState({
    //       error,
    //       loading: false
    //     });
    //   });
  }

  static mapStateToProps (state) {
    return {
      routing: state.routing,
      authentication: state.authentication
    };
  }

  static mapDispatchToProps (dispatch) {
    return {
      navigate: path => dispatch(push(path))
    };
  }

  createInputHandler (name) {
    return (e, input) => {
      this.setState({
        [name]: e.target.value
      });
    };
  }

  render () {
    let { loading, error } = this.state;
    let { t, routing, match } = this.props;

    //console.log("match", match);
    const { type, slug } = match.params;
    const renderForm = (aType = type, aSlug = slug) => {
      if (aType == "verify") {
        return (
          <Segment basic size="massive" textAlign="center">
            <Header align="centered">{t("verification.actions.verify")}</Header>
            {!loading ? (
              ""
            ) : (
              <Segment basic padded="very">
                <Loader active={loading} size="massive" />
              </Segment>
            )}
          </Segment>
        );
      }
      if (aType == "verifyChanges") {
        return <div>{aType}</div>;
      }

      if (aType == "reset") {
        return (
          <Segment basic size="massive" textAlign="center">
            <Header align="centered">{t("verification.actions.reset")}</Header>
            <PasswordChangeForm
              {...this.state}
              createInputHandler={this.createInputHandler.bind(this)}
              onSubmit={this.send.bind(this)}
            />
          </Segment>
        );
      }
      if (aType == "changePassword") {
        return <div>{aType}</div>;
      }
      if (aType == "changeEmail") {
        return <div>{aType}</div>;
      }
    };

    let aForm = renderForm();
    //console.log("aForm", aForm);

    const eMessage = message => {
      return (
        t(
          `verification.errors.${message
            .toLowerCase()
            .substring(0, 20)
            .replace(/\./g, "")}`
        ) || message
      );
    };

    return (
      <Container style={styles.container}>
        <Grid columns={1} centered doubling>
          <Grid.Row>
            <Grid.Column widescreen={6} mobile={16} tablet={10} computer={8}>
              <Segment>
                {aForm}
                {error && (
                  <Segment basic>
                    <Message
                      negative
                      icon="warning sign"
                      header={eMessage(error.name)}
                      content={eMessage(error.message)}
                    />
                  </Segment>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
const i18nEnhancement = translate("translations")(VerifyView);
const withServices = ConnectServices(i18nEnhancement);

export default connect(
  VerifyView.mapStateToProps,
  VerifyView.mapDispatchToProps
)(withServices);
