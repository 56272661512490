import React, { Component } from "react";

function hashCode (str) {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
}

function pickColor (str) {
  return `hsl(${((hashCode(str) % 20) * 10)}, 43%, 65%)`;
}

export default (props) => {
  let {logo, size, color, name} = props;

  if (!color && name) {
    color = pickColor(props.name);
  }

  return <div style={{overflow: "hidden", fontWeight: "bold", verticalAlign: "middle", textAlign: "center", color: "white", textTransform: "uppercase", borderRadius: props.size, backgroundColor: color || "grey", width: props.size, height: props.size}}>{props.logo ? <img src={props.logo} width={props.size} height={props.size} />: <div style={{lineHeight: `${props.size}px`}}>{props.name.split(" ").map(a => a[0]).join("").slice(0,3)}</div>}</div>;
};
