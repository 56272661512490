import React, { Component } from "react";
import {
  Breadcrumb,
  Container,
  Divider,
  Header,
  Icon,
  List
} from "semantic-ui-react";
import { translate } from "react-i18next";
import Radium from "radium";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import * as Routes from "../../../../routes";

const styles = {};

class AdminResourcesView extends Component {
  constructor(props) {
    super(props);
  }

  static mapStateToProps(state) {
    return {
      authentication: state.authentication
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path))
    };
  }

  render() {
    const { t } = this.props;

    return (
      <Container style={{ padding: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Section as={Link} to={Routes.DASHBOARD}>
            Principal
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section as={Link} to={Routes.ADMIN}>
            Administracion
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Recursos</Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Header dividing size="large" style={{ margin: 0 }}>
          <Header.Content>
            ADMINISTRACION DE RECURSOS
            <Header.Subheader>
              Alta, Baja y Modificacion de Usuarios, Grupos, etc ...
            </Header.Subheader>
          </Header.Content>
        </Header>
        <List>
          <List.Item as={Link} to={Routes.ADMIN_RESOURCES_USERS}>
            Administrar Usuarios
          </List.Item>
          <List.Item as={Link} to={Routes.ADMIN_RESOURCES_GROUPS}>
            Administrar Grupos
          </List.Item>
          <List.Item as={Link} to={Routes.ADMIN_RESOURCES_SUBSCRIPTIONS}>
            Administrar Suscripciones
          </List.Item>
          <List.Item as={Link} to={Routes.ADMIN_RESOURCES_WIKI}>
            Administrar Documentación
          </List.Item>
        </List>
      </Container>
    );
  }
}

const StyledView = Radium(AdminResourcesView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  AdminResourcesView.mapStateToProps,
  AdminResourcesView.mapDispatchToProps
)(i18nEnhancement);
