export const ROOT = "/";
export const WELCOME = "/";
export const LOGIN = "/sign-in";

//export const PASSWORD_RESET = "/log-in/reset";
export const SIGNIN = "/sign-in";
export const FORGOT_PASSWORD = "/forgot-password";
export const DASHBOARD = "/dashboard/:filter?/:group?/:ticket?/:activity?";
export const DASHBOARD_FILTER = "/dashboard/:filter";
export const DASHBOARD_GROUP = "/dashboard/:filter/:group";
export const DASHBOARD_TICKET = "/dashboard/:filter/:group/:ticket";
export const DASHBOARD_ACTIVITY = "/dashboard/:filter/:group/:ticket/:activity";
export const WIKI = "/wiki/:filter?/:group?/:ticket?/:activity?";
export const WIKI_GROUP = "/wiki/group/:groupId";
export const WIKI_TOPIC = "/wiki/topic/:topicId";
export const NOTIFICATIONS = "/notifications";

export const RECOVERY = "/log-in/recovery";
export const CREATE = "/sign-up";
export const VERIFY = "/log-in/:type/:slug";
export const USERSADMIN = "/admin/users";
export const LOGS = "/admin/logs";
export const PROFILE = "/profile";

// groups
export const GROUPS = "/groups";
export const GROUP_DETAIL = "/groups/:id";
export const GROUP_TICKETS = "/groups/:id/tickets";

// watchlists
export const WATCHLISTS = "/watchlists";

// tickets
export const TICKET = "/groups/:GroupId/tickets/:id";
export const TICKET_ALT = "/tickets/:id";
export const TICKET_CREATE = "/groups/:GroupId/create/tickets/";

// admin
export const ADMIN = "/admin";
export const ADMIN_RESOURCES = "/admin/resources";
// users
export const ADMIN_RESOURCES_USERS = "/admin/resources/users"; //(/:source/:id)?";
// groups
export const ADMIN_RESOURCES_GROUPS = "/admin/resources/groups"; //(/:source/:id)?";
// subscriptions
export const ADMIN_RESOURCES_SUBSCRIPTIONS = "/admin/resources/subscriptions";
export const ADMIN_RESOURCES_WIKI = "/admin/resources/wiki/:section";
export const ADMIN_RESOURCES_SUBSCRIPTIONS_FOR_GROUP =
  "/admin/resources/subscriptions/:source/:id";
// query
export const QUERY_TICKET = "/query/tickets";
// docs
export const DOCUMENTATION = "/documentation";
export const DOCUMENTATION_SECTION = "/documentation/:sectionid";
