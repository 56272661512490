import React, { Component, useRef } from "react";
import { Icon, Menu, Dropdown, Input, Button } from "semantic-ui-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

var formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  // 'image'
  // 'video'
];
export const NewConversationTab = (props) => {
  const { active } = props;
  const pond = useRef(null);
  const handleInit = (a) => {
    pond.current._pond.setOptions({
      acceptedFileTypes: [
        "image/*",
        "application/x-rar-compressed",
        "application/zip",
        "application/xml",
        "text/xml",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/plain",
        "application/rtf",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/csv",
        "application/octet-stream",
      ],
      labelIdle:
        'Arrastra tus archivos e imagenes adjuntos aquí o <span class="filepond--label-action"> seleccionar de mi computadora </span>',
      allowPaste: true,
      dropOnPage: true,
      timeout: 10000000,
    });
  };

  const Editor = {
    modules: {
      keyboard: {
        bindings: {
          go: {
            key: "Enter",
            ctrlKey: true,
            handler: () => {
              if (
                props.loading ||
                !props.groupId ||
                !props.subject ||
                !props.body ||
                props.body.length < 1 ||
                props.body == "<p><br/></p>" ||
                (props.attachments.length > 0 &&
                  props.attachments.some((a) => !a.serverId))
              ) {
                return;
              }
              props.createTicket(
                props.subject,
                props.groupId,
                props.body,
                props.attachments.map((a) => a.serverId.replace(/"/g, ""))
              );
            },
          },
        },
      },
    },
  };

  return (
    <div
      key={props.id}
      className={active ? "activeNewTicket" : undefined}
      style={{
        width: active ? "40vw" : 300,
        marginRight: 5,
        marginLeft: 5,
        pointerEvents: "auto",
      }}
    >
      <div
        onClick={() => props.maximizeConversation(!active ? props.id : null)}
        style={{
          cursor: "pointer",
          backgroundColor: props.loading ? "#717272" : "#494949",
          color: "white",
          padding: 10,
          display: "flex",
          justifyContent: "space-between",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        <div>
          {props.subject && props.subject.length > 0
            ? props.subject
            : "Nuevo Ticket"}
        </div>
        <div>
          <Icon
            link
            name={`window ${active ? "minimize" : "maximize"}`}
            color="grey"
          />
          <Icon
            link
            name="close"
            color="grey"
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              props.closeConversation(props.id);
            }}
          />
        </div>
      </div>
      {!active ? null : (
        <div style={{ backgroundColor: "white" }}>
          <div style={{ display: "flex", width: "100%" }}>
            {/* <div style={{display: "flex", flexDirection: "column", justifyContent: "space-around", paddingLeft: 5, paddingRight: 5, textAlign: "right", fontWeight: 600, color: "grey"}}>
                        <div>Grupo</div>
                        <div>Asunto</div>
                    </div> */}
            <div style={{ flex: 1 }}>
              <Dropdown
                value={props.groupId}
                onChange={(_, { value }) =>
                  props.updateConversation({ groupId: value })
                }
                upward
                allowAdditions={false}
                className="square"
                placeholder="Seleccionar Grupo"
                fluid
                search
                selection
                options={props.groups}
              />
              <Input
                value={props.subject}
                onChange={(_, { value }) =>
                  props.updateConversation({ subject: value })
                }
                attached
                fluid
                placeholder="Asunto"
                className="square"
              />
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <Button
              onClick={() =>
                props.createTicket(
                  props.subject,
                  props.groupId,
                  props.body,
                  props.attachments.map((a) => a.serverId.replace(/"/g, ""))
                )
              }
              disabled={
                props.loading ||
                !props.groupId ||
                !props.subject ||
                !props.body ||
                props.body.length < 1 ||
                props.body == "<p><br/></p>" ||
                (props.attachments.length > 0 &&
                  props.attachments.some((a) => !a.serverId))
              }
              style={{ position: "absolute", top: "10px", right: "10px" }}
              color="blue"
            >
              {props.loading ? "Creando..." : "Crear"}
            </Button>
            <ReactQuill
              defaultValue={props.body}
              formats={formats}
              onChange={(v) => props.updateConversation({ body: v })}
              id="neweditor"
              key={`conversationEditorId${props.id}`}
            />
          </div>
          <div
            style={{
              minHeight: "70px",
              overflow: "hidden",
              backgroundColor: "#F1F0EF",
            }}
          >
            <FilePond
              ref={pond}
              allowMultiple={false}
              maxFiles={1}
              dropOnPage={true}
              name="uri"
              server="/api/file"
              oninit={handleInit}
              onprocessfile={(e) => {
                // Set current file objects to this.state

                props.updateConversation({
                  attachments: pond.current.getFiles(),
                });
              }}
              onupdatefiles={(fileItems) => {
                // Set current file objects to this.state
                console.log(fileItems);
                props.updateConversation({
                  attachments: fileItems,
                });
              }}
            >
              {props.attachments.map((a) => (
                <File key={a.file} src={a.file} origin="local" />
              ))}
            </FilePond>
          </div>
        </div>
      )}
    </div>
  );
};

const CONVERSATION_STATE = {
  groupId: null,
  subject: "",
  body: "",
  attachments: [],
};

export default class NewConversationWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maximized: null,
      conversations: [],
    };
  }

  addConversation = () => {
    let id = Math.random();
    let maximized = !this.state.maximized ? id : this.state.maximized;
    this.setState({
      conversations: [
        { ...CONVERSATION_STATE, key: id, id },
        ...this.state.conversations,
      ],
      maximized,
    });
    this.props.isMaximized(true);
  };

  maximizeConversation = (conversationId = null) => {
    if (this.props.config.loading) {
      return;
    }
    this.setState({
      maximized: conversationId,
    });
    if (this.props.isMaximized) {
      this.props.isMaximized(conversationId !== null);
    }
  };

  closeConversation = (conversationId) => {
    if (!conversationId || this.props.config.loading) {
      return;
    }
    if (conversationId == this.state.maximized) {
      this.props.isMaximized(false);
    }

    this.setState({
      maximized:
        conversationId == this.state.maximized ? null : this.state.maximized,
      conversations: [
        ...this.state.conversations.filter((a) => a.id != conversationId),
      ],
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.config.loading &&
      !this.props.config.loading &&
      !this.props.config.error
    ) {
      this.closeConversation(this.state.maximized);
    }
  };

  updateConversation = (conversation) => {
    this.setState({
      conversations: this.state.conversations.map((a) =>
        a.id == this.state.maximized ? { ...a, ...conversation } : a
      ),
    });
  };

  render() {
    const { conversations, maximized } = this.state;
    return (
      <div
        className={this.props.className}
        style={{
          height: maximized != null ? "100vh" : 0,
          backgroundColor: maximized != null ? "rgba(0, 0, 0, 0.3)" : undefined,
          pointerEvents: "none",
          width: "100%",
          zIndex: 100,
          position: "absolute",
          bottom: 0,
          right: 0,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {conversations.map((aConversation) => (
          <NewConversationTab
            loading={this.props.config.loading}
            createTicket={this.props.createTicket}
            groups={(this.props.groups || []).map((a) => ({
              key: a.id,
              text: a.name,
              value: a.id,
            }))}
            {...aConversation}
            maximizeConversation={this.maximizeConversation}
            active={aConversation.id == maximized}
            key={aConversation.id}
            closeConversation={this.closeConversation}
            updateConversation={this.updateConversation}
          />
        ))}
        <div style={{ padding: 10, pointerEvents: "auto" }}>
          <Icon
            onClick={this.addConversation}
            name="plus"
            link
            circular
            color="orange"
            size="big"
            inverted
          />
        </div>
      </div>
    );
  }
}
