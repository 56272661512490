import React, { Component } from 'react';
import {
  List, Header, Segment,
  Message, Button, Modal,
  Icon
} from 'semantic-ui-react';
import shortid from 'shortid';
import { Link } from 'react-router-dom';
import { find, orderBy } from 'lodash';
import queryString from 'qs';
import * as ROUTES from '../../../routes';
import ArticleForm from './ArticleForm';

const INITIAL_STATE = {
  loading: false,
  data: null,
  error: null,
  upsertArticleModal: {
    loading: false,
    values: {
      subject: '',
      body: '',
      sort: 1,
      SectionId: null
    },
    error: null
  },
  removeArticleModal: {
    open: false,
    loading: false,
    values: {},
    error: null
  },
  removeSubSectionModal: {
    open: false,
    loading: false,
    values: {},
    error: null
  },
  SuperSectionId: null
};

class SectionView extends Component {

  constructor (props) {
    super(props);

    this.state = INITIAL_STATE;
    this.SectionService = this.props.feathers.service('/api/sections');
    this.ArticleService = this.props.feathers.service('/api/articles');
  }

  getSection = () => {
    const { match: { params: { sectionid }}} = this.props;

    this.setState({
      loading: true,
      error: null
    });

    this.SectionService.get(sectionid)
      .then(response => {
        //console.log(response);
        this.setState({
          loading: false,
          data: response,
          SuperSectionId: response.SectionId
        });
      }, error => {
        console.error(error);
        this.setState({
          loading: false,
          error
        });
      });
  }

  openArticleModal = (article = null) => {
    if (article) {
      this.setState({
        upsertArticleModal: {
          ...this.state.upsertArticleModal,
          open: true,
          values: {
            ...article
          }
        }
      });
    } else {
      this.setState({
        upsertArticleModal: {
          ...this.state.upsertArticleModal,
          open: true,
          values: {
            ...this.state.upsertArticleModal.values,
            SectionId: this.state.data.id
          }
        }
      });
    }
  }

  dismissArticleModal = () => {
    if (this.state.upsertArticleModal.loading) {
      return;
    }

    this.setState({
      upsertArticleModal: INITIAL_STATE.upsertArticleModal
    });
  }

  openRemoveSubSectionModal = (section) => {
    this.setState({
      removeSubSectionModal: {
        ...this.state.removeSubSectionModal,
        open: true,
        values: {
          ...section
        }
      }
    });
  }

  dismissRemoveSubSectionModal = () => {
    if (this.state.removeSubSectionModal.loading) {
      return;
    }

    this.setState({
      removeSubSectionModal: INITIAL_STATE.removeSubSectionModal
    });
  }

  removeSubSecton = () => {
    this.setState({
      removeSubSectionModal: {
        ...this.state.removeSubSectionModal,
        loading: true
      }
    }, () => {
      this.SectionService.remove(this.state.removeSubSectionModal.values.id)
        .then(response => {
          this.setState({
            removeSubSectionModal: INITIAL_STATE.removeSubSectionModal
          }, () => {
            this.props.navigate(`/reload?path=${ROUTES.DOCUMENTATION}`);
          });
        }, error => {
          this.setState({
            removeSubSectionModal: {
              ...this.state.removeSubSectionModal,
              loading: true,
              error
            }
          });
        });
    });
  }

  openRemoveArticleModal = (article) => {
    this.setState({
      removeArticleModal: {
        ...this.state.removeArticleModal,
        open: true,
        values: {
          ...article
        }
      }
    });
  }

  dismissRemoveArticleModal = () => {
    if (this.state.removeArticleModal.loading) {
      return;
    }

    this.setState({
      removeArticleModal: INITIAL_STATE.removeArticleModal
    });
  }

  removeArticle = () => {
    this.setState({
      removeArticleModal: {
        ...this.state.removeArticleModal,
        loading: true
      }
    }, () => {
      this.ArticleService.remove(this.state.removeArticleModal.values.id)
        .then(response => {
          this.setState({
            removeArticleModal: INITIAL_STATE.removeArticleModal
          }, this.getSection);
        }, error => {
          this.setState({
            removeArticleModal: {
              ...this.state.removeArticleModal,
              loading: true,
              error
            }
          });
        });
    });
  }

  articleFormInputHandler = (e, input) => {
    const { upsertArticleModal } = this.state;

    let name;
    let value;

    if (input) {
      name = input.id;
      value = input.value;
    } else {
      name = e.target.id;
      value = e.target.value;
    }

    this.setState({
      upsertArticleModal: {
        ...upsertArticleModal,
        values: {
          ...upsertArticleModal.values,
          [name]: value
        }
      }
    });
  }

  superSectionHandler = (e, input) => {
    this.setState({
      SuperSectionId: input.value
    });
  }

  articleFormQuillHandler = (text) => {
    const { upsertArticleModal } = this.state;

    this.setState({
      upsertArticleModal: {
        ...upsertArticleModal,
        values: {
          ...upsertArticleModal.values,
          body: text
        }
      }
    });
  }

  upsertArticle = () => {
    const {
      upsertArticleModal: { loading, values }
    } = this.state;

    if (loading) {
      return;
    }

    this.setState({
      upsertArticleModal: {
        ...this.state.upsertArticleModal,
        loading: true
      }
    });

    const shorturl = shortid.generate();

    if (values.id) {
      this.ArticleService.patch(values.id, {
        ...values
      })
        .then(response => {
          this.setState({
            upsertArticleModal: INITIAL_STATE.upsertArticleModal
          }, this.getSection);
        }, error => {
          this.setState({
            upsertArticleModal: {
              ...this.state.upsertArticleModal,
              loading: false,
              error
            }
          });
        });
    } else {
      this.ArticleService.create({
        ...values,
        shorturl,
        mask: shorturl
      })
        .then(response => {
          this.setState({
            upsertArticleModal: INITIAL_STATE.upsertArticleModal
          }, this.getSection);
        }, error => {
          this.setState({
            upsertArticleModal: {
              ...this.state.upsertArticleModal,
              loading: false,
              error
            }
          });
        });
    }
  }

  mapSectionsToOptions = () => this.props.sections.map((section, index) => ({
    key: index,
    text: section.name,
    value: section.id
  }))

  mapSubSectionsToOptions = () => {
    const section = find(this.props.sections, (s) => s.id === this.state.SuperSectionId);

    if (section) {
      return section.subsections.map((section, index) => ({
        key: index,
        text: section.name,
        value: section.id
      }));
    }

    return [];
  }

  componentDidMount () {
    this.getSection();
  }

  render () {
    const { loading, data: section, error, upsertArticleModal, removeSubSectionModal, removeArticleModal, SuperSectionId } = this.state;
    const { article: articleId } = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });

    let article;

    if (articleId && section) {
      article = find(section.articles, (a) => a.id === parseInt(articleId));
    }

    if (loading) {
      return (
        <div>
          Cargando ...
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <Message negative>
            <Message.Header>{error.name}</Message.Header>
            <p>{error.message}</p>
          </Message>
        </div>
      );
    }

    if (!section) {
      return null;
    }

    return (
      <div>
        <Segment vertical>
          <Header>
            <Button size="mini" primary content="Nuevo Articulo" icon="add" floated="right" onClick={this.openArticleModal.bind(null, null)} />
            {section.supersection.name}
            <Header.Subheader>{section.name}</Header.Subheader>
          </Header>
        </Segment>
        <Segment vertical>
          <p>
            {section.description}
          </p>
        </Segment>
        <Segment clearing vertical>
          <Button
            disabled={section.articles.length !== 0}
            size="mini" negative content="Eliminar" icon="remove" floated="right" onClick={this.openRemoveSubSectionModal.bind(null, section)} />
          <Button size="mini" secondary content="Editar" icon="edit" floated="right" onClick={this.props.openUpsertSectionModal.bind(null, section)} />
        </Segment>
        <Segment secondary>
          <List>
            {
              orderBy(section.articles, ['sort'], ['asc']).map((article, index) => (
                <List.Item as={Link} to={`${this.props.match.url}?article=${article.id}`} key={index}>
                  <List.Icon name="angle right" />
                  <List.Content>
                    {
                      parseInt(articleId) === article.id ? <strong>{article.subject}</strong> : article.subject
                    }
                  </List.Content>
                </List.Item>
              ))
            }
          </List>
        </Segment>
        {
          article && [
            <Segment vertical key={0}>
              <Header>
                <Button
                  size="mini" negative content="Eliminar" icon="remove" floated="right" onClick={this.openRemoveArticleModal.bind(null, article)} />
                <Button size="mini" secondary content="Editar" icon="edit" floated="right" onClick={this.openArticleModal.bind(null, article)} />
                {article.subject}
              </Header>
            </Segment>,
            <Segment vertical key={1}>
              <div
                dangerouslySetInnerHTML={{ __html: article.body }}
                style={{ height: "auto", wordWrap: "break-word" }} />
            </Segment>,
            <Segment vertical key={2}>
              <Header size="small">Articulos Relacionados</Header>
            </Segment>
          ]
        }
        <Modal open={upsertArticleModal.open} onClose={this.dismissArticleModal}>
          <Modal.Header>Alta / Edicion Articulo</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <ArticleForm
                loading={upsertArticleModal.loading} values={upsertArticleModal.values}
                inputHandler={this.articleFormInputHandler} superSectionHandler={this.superSectionHandler}
                quillHandler={this.articleFormQuillHandler} upsert={this.upsertArticle}
                SuperSectionId={SuperSectionId} sections={this.mapSectionsToOptions()} subsections={this.mapSubSectionsToOptions()} />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button basic negative content="Cancelar" icon="remove" onClick={this.dismissArticleModal} />
            <Button positive content="Aceptar" icon="checkmark" onClick={this.upsertArticle} />
          </Modal.Actions>
        </Modal>
        <Modal open={removeSubSectionModal.open} basic size="small" onClose={this.dismissRemoveSubSectionModal}>
          <Header icon="trash" content={`Eliminar Subseccion ${removeSubSectionModal.values.id}`} />
          <Modal.Content>
            <p>
              Seguro desea eliminar la subseccion {removeSubSectionModal.values.subject}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={this.dismissRemoveSubSectionModal} disabled={removeSubSectionModal.loading}>
              <Icon name="remove" /> No
            </Button>
            <Button color="green" inverted onClick={this.removeSubSecton} disabled={removeSubSectionModal.loading}>
              <Icon name="checkmark" /> {removeSubSectionModal.loading ? 'Cargando...' : 'Si'}
            </Button>
          </Modal.Actions>
          {
            removeSubSectionModal.error &&
              <Message negative>
                <Message.Header>{removeSubSectionModal.error.name}</Message.Header>
                <p>{removeSubSectionModal.error.message}</p>
              </Message>
          }
        </Modal>
        <Modal open={removeArticleModal.open} basic size="small" onClose={this.dismissRemoveArticleModal}>
          <Header icon="trash" content={`Eliminar Articulo ${removeArticleModal.values.id}`} />
          <Modal.Content>
            <p>
              Seguro desea eliminar el articulo {removeArticleModal.values.subject}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={this.dismissRemoveArticleModal} disabled={removeArticleModal.loading}>
              <Icon name="remove" /> No
            </Button>
            <Button color="green" inverted onClick={this.removeArticle} disabled={removeArticleModal.loading}>
              <Icon name="checkmark" /> {removeArticleModal.loading ? 'Cargando...' : 'Si'}
            </Button>
          </Modal.Actions>
          {
            removeArticleModal.error &&
              <Message negative>
                <Message.Header>{removeArticleModal.error.name}</Message.Header>
                <p>{removeArticleModal.error.message}</p>
              </Message>
          }
        </Modal>
      </div>
    );
  }
};

export default SectionView;