import React, { Component } from 'react';
import { Container, Segment, Header, Icon } from 'semantic-ui-react';
import { translate } from 'react-i18next';

class NotFoundView extends Component {

  componentDidMount () {

  }

  render () {
    const {t} = this.props;

    return (
      <Container>
        <Segment color="red" size="massive">
          <Header as="h2" icon textAlign="center">
            <Icon name="question" circular />
            <Header.Content>
              {t('notFound.title')}
            </Header.Content>
          </Header>
        </Segment>
      </Container>
    );
  }

}

const i18nEnhancement = translate('translations')(NotFoundView);

export default i18nEnhancement;
