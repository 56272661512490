import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intersection } from 'lodash';
import ConnectServices from '../providers/ConnectServices';
import authenticate from '../authenticate';
import {
  Accordion,
  Container,
  Icon,
  Message,
  Segment
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';
import * as ROUTES from '../routes';

const INITIA_STATE = {
  error: null,
  stage: "LANDING"
};

const defaultOptions = {
  public: false
};

const Authorize = (ComponentToWrap, roles = [], options = defaultOptions) => {
  class AuthorizedComponent extends Component {
    constructor (props) {
      super(props);

      this.state = INITIA_STATE;

      this.FeathersService = this.props.services.feathers;
      this.authenticate = authenticate(this.FeathersService, props.dispatch);
    }

    toggleViewError = (e, titleProps) => {
      const { index } = titleProps;
      const { activeIndex } = this.state;
      const newIndex = activeIndex === index ? -1 : index;

      this.setState({ activeIndex: newIndex });
    };

    componentDidMount () {
      this.setState(
        {
          stage: "AUTHENTICATING"
        },
        () => {
          this.authenticate()
            .then(user => {
              if (user) {
                if (roles && roles.length !== 0) {
                  if (user.roles && user.roles.length !== 0) {
                    const isAuthorized =
                      intersection(roles, user.roles).length !== 0;

                    if (isAuthorized) {
                      this.setState({
                        stage: "READY"
                      });
                    } else {
                      this.setState({
                        stage: "UNAUTHORIZED"
                      });
                    }
                  } else {
                    this.setState({
                      stage: "UNAUTHORIZED"
                    });
                  }
                } else {
                  this.setState({
                    stage: "READY"
                  });
                }
              } else {
                this.setState({
                  stage: "ERROR",
                  error: {
                    name: "NoUser",
                    message: "No user found on response"
                  }
                });
              }
            })
            .catch(error => {
              if (options.public) {
                this.setState({
                  stage: "READY"
                });
              } else {
                this.setState(
                  {
                    stage: "ERROR",
                    error
                  },
                  () => {
                    let { location } = this.props.routing;

                    if (error.name === "NotAuthenticated") {
                      this.props.navigate(
                        `${ROUTES.SIGNIN}?errorCode=${error.code}&errorName=${
                          error.name
                        }&intented=${location.pathname}`
                      );
                    }
                  }
                );
              }
            });
        }
      );
    }

    static mapDispatchToProps (dispatch) {
      return {
        navigate: path => dispatch(push(path)),
        dispatch
      };
    }

    static mapStateToProps (state) {
      return {
        authentication: state.authentication.toJS(),
        routing: state.routing
      };
    }

    render () {
      let { activeIndex, stage, error } = this.state;

      if (stage === "LANDING") {
        return (
          <div className="loader">
            <div className="loader__figure" />
            <p className="loader__label">PREPARANDO</p>
          </div>
        );
      } else if (stage === "AUTHENTICATING") {
        return (
          <div className="loader">
            <div className="loader__figure" />
            <p className="loader__label">AUTORIZANDO</p>
          </div>
        );
      } else if (stage === "UNAUTHORIZED") {
        return (
          <Container>
            <Segment raised style={{ marginTop: 16 }}>
              <Message negative>
                <Message.Header>Error! Permisos insuficientes</Message.Header>
                <p>No esta autorizado para acceder a esta seccion</p>
              </Message>
            </Segment>
          </Container>
        );
      } else if (stage === "ERROR") {
        return (
          <Container>
            <Segment raised style={{ marginTop: 16 }}>
              <Message negative>
                <Message.Header>
                  Error! Hubo algun problema al acceder
                </Message.Header>
                <p>
                  Debe estar logeado para ver este pagina{" "}
                  <Link to={ROUTES.SIGNIN}>Ingresar Aqui</Link>
                </p>
              </Message>
              <Accordion>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.toggleViewError}
                >
                  <Icon name="dropdown" />
                  Ver Error
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <h3>{error.name}</h3>
                  <pre>{error.message}</pre>
                </Accordion.Content>
              </Accordion>
            </Segment>
          </Container>
        );
      } else if (stage === "READY") {
        return (
          <ComponentToWrap {...this.props} />
        );
      } else {
        return <div>Unregistered stage {stage}</div>;
      }
    }
  }

  return connect(
    AuthorizedComponent.mapStateToProps,
    AuthorizedComponent.mapDispatchToProps
  )(ConnectServices(AuthorizedComponent));
};

export default Authorize;
