import React, { Component } from 'react';
import PropTypes from 'prop-types';

const ConnectServices = (ComponentToWrap) => {
  return class ConnectServicesComponent extends Component {
    // let’s define what’s needed from the `context`
    static contextTypes = {
      services: PropTypes.object.isRequired,
      storeActions: PropTypes.object.isRequired
    }

    render () {
      const { services, storeActions } = this.context;
      // what we do is basically rendering `ComponentToWrap`
      // with an added `services` prop, like a hook
      return (
        <ComponentToWrap {...this.props} services={services} storeActions={storeActions} />
      );
    }
  };
};

export default ConnectServices;