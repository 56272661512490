import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { visibilityActions } from '../../store/actions/visibility';
import { push } from 'react-router-redux';
import { translate } from 'react-i18next';
import { Segment } from 'semantic-ui-react';

/*
import { Link } from 'react-router-dom';
import * as Routes from '../../routes';
import Assets from '../../assets';
*/

class Footer extends Component {

  static mapStateToProps (state) {
    return {
      routing: state.routing,
      authentication: state.authentication
    };
  }

  static mapDispatchToProps (dispatch) {
    return {
      navigate: (path) => dispatch(push(path)),
      toggleNavigationSidebar: () => dispatch(visibilityActions.toggleNavigationSidebar())
    };
  }

  render () {
    return (
      <Segment style={{ margin: 0, backgroundColor: '#1B1C1D', borderRadius: 0 }} textAlign="center" tertiary padded>
        FOOTER
      </Segment>
    );
  }
}

Footer.propTypes = {
  mobile: PropTypes.bool
};

Footer.defaultProps = {
  mobile: false
};

const i18nEnhancement = translate('translations')(Footer);

export default connect(Footer.mapStateToProps, Footer.mapDispatchToProps)(i18nEnhancement);
