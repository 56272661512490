import { Component, Children } from 'react';
import PropTypes from 'prop-types';

class ServiceProvider extends Component {

  static propTypes = {
    services: PropTypes.object.isRequired,
    storeActions: PropTypes.object.isRequired
  }
  // you must specify what you’re adding to the context
  static childContextTypes = {
    services: PropTypes.object.isRequired,
    storeActions: PropTypes.object.isRequired
  }

  getChildContext () {
    const { services, storeActions } = this.props;
    return { services, storeActions };
  }

  render () {
    // `Children.only` enables us not to add a <div /> for nothing
    return Children.only(this.props.children);
  }
}

export default ServiceProvider;