import React, { Component } from "react";
import FilteredCollection from "../layout/FilteredCollection";
import {
  Breadcrumb,
  Input,
  Accordion,
  Dropdown,
  Form,
  Menu,
  Header,
  Icon,
  Label,
  Segment,
  Card
} from "semantic-ui-react";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import Radium from "radium";
import * as Routes from "../../../routes";
import LoadingIndicator from "../../LoadingIndicator";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";

const FILTERS = {
  SHOW_ALL: "SHOW_ALL",
  SHOW_OPEN_TICKETS_GROUPS: "SHOW_OPEN_TICKETS_GROUPS"
};

class GroupsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullSubscriptions: null,
      loadingSubscriptions: false,
      currentFilter: FILTERS.SHOW_OPEN_TICKETS_GROUPS,
      searchTerm: "",
      filtersOpen: true
    };
    this.feathers = this.FeathersService = this.props.services.feathers;
  }

  static mapStateToProps(state) {
    //console.log(state);
    return {
      user: !state.authentication ? null : state.authentication.toJS().user
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path)),
      dispatch
    };
  }

  componentDidMount() {
    if (!!this.props.user && !!this.props.user.subscriptions) {
      this.updateSubscriptions(this.props.user.subscriptions);
    }
  }

  componentDidUpdate(previousProps) {
    if (
      !!this.props.user &&
      !!this.props.user.subscriptions &&
      (!previousProps.user ||
        !previousProps.user.subscriptions ||
        previousProps.user.subscriptions != this.props.user.subscriptions)
    ) {
      this.updateSubscriptions(this.props.user.subscriptions);
    }
  }

  toggleFilters = () => {
    this.setState({
      filtersOpen: !this.state.filtersOpen
    });
  };

  updateSubscriptions = subscriptions => {
    this.setState(
      {
        loadingSubscriptions: true,
        fullSubscriptions: null
      },
      () => {
        this.loadGroupStats(subscriptions).then(fullSubscriptions => {
          this.setState({
            loadingSubscriptions: false,
            fullSubscriptions
          });
        });
      }
    );
  };

  loadGroupStats = async subscriptions => {
    const UserStatsService = this.feathers.service("api/user-stats");
    let globalStats = await UserStatsService.get(this.props.user.id);
    //console.log("stats", globalStats);
    if (!globalStats) {
      globalStats = [];
    }
    let response = subscriptions.map(aSubscription => {
      let row = globalStats.find(a => a.id == aSubscription.id);
      //console.log("row", row);
      let stats = {
        open: 0,
        total: 0,
        closed: 0
      };

      if (!!row) {
        stats = {
          open: row.openticketcount,
          total: row.totalcount,
          closed: row.totalcount - row.openticketcount
        };
      }
      return {
        ...aSubscription,
        Group: {
          ...aSubscription.Group,
          stats
        }
      };
    });

    return response;
  };

  render() {
    const { t, user, navigate } = this.props;
    const {
      fullSubscriptions,
      loadingSubscriptions,
      filtersOpen,
      currentFilter,
      searchTerm
    } = this.state;
    if (!user || !fullSubscriptions || !!loadingSubscriptions) {
      return <LoadingIndicator localizationMessage="preparing" />;
    }

    let subscriptionsWithOpenTickets = fullSubscriptions.filter(
      aSub => aSub.Group.stats.open > 0
    );
    let subscriptions;

    switch (currentFilter) {
      case FILTERS.SHOW_ALL:
        subscriptions = fullSubscriptions;
        break;
      case FILTERS.SHOW_OPEN_TICKETS_GROUPS:
        subscriptions = subscriptionsWithOpenTickets;
        break;
      default:
        subscriptions = fullSubscriptions;
    }

    const isFilterActive = aFilter => {
      return currentFilter === aFilter;
    };

    const setSearchTerm = (_, { value }) => {
      this.setState({
        searchTerm: value
      });
    };

    const setFilter = aFilter => () => {
      this.setState({
        currentFilter: aFilter || FILTERS.SHOW_ALL
      });
    };

    if (!!searchTerm && searchTerm != "") {
      subscriptions = subscriptions.filter(s =>
        s.Group.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      subscriptionsWithOpenTickets = subscriptionsWithOpenTickets.filter(s =>
        s.Group.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    const openTicketsCount = subscriptionsWithOpenTickets.reduce(
      (previous, current) => {
        return previous + current.Group.stats.open;
      },
      0
    );

    const totalTicketsCount = subscriptions.reduce((previous, current) => {
      return previous + current.Group.stats.total;
    }, 0);

    const navigateToGroup = groupId => () => {
      navigate(Routes.GROUP_DETAIL.replace(":id", groupId));
    };

    return (
      <FilteredCollection
        breadcrumb={
          <Breadcrumb style={{ paddingTop: 16 }}>
            <Breadcrumb.Section as={Link} to={Routes.DASHBOARD}>
              Principal
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>Suscripciones</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Grupos</Breadcrumb.Section>
          </Breadcrumb>
        }
        header={
          <Header dividing size="large">
            <Header.Content>
              Grupos
              <Header.Subheader>Suscripciones a grupos</Header.Subheader>
            </Header.Content>
          </Header>
        }
        headerMobile={
          <Header dividing size="large" textAlign="center">
            <Header.Content>
              Grupos
              <Header.Subheader>Suscripciones a grupos</Header.Subheader>
            </Header.Content>
          </Header>
        }
        filter={
          <Accordion>
            <Accordion.Title
              active={filtersOpen}
              index={0}
              onClick={this.toggleFilters}
            >
              <Icon name="filter" />
              Filtros
              {/*<Button floated="right" content="Aplicar" size="tiny" />*/}
            </Accordion.Title>
            <Accordion.Content active={filtersOpen}>
              <Form onSubmit={this.applyFilters}>
                <Menu vertical size="large" fluid>
                  <Menu.Item>
                    <Input
                      icon="search"
                      transparent
                      placeholder="Buscar Grupo..."
                      onChange={setSearchTerm}
                    />
                  </Menu.Item>
                  <Menu.Item
                    name="withTickets"
                    active={isFilterActive(FILTERS.SHOW_OPEN_TICKETS_GROUPS)}
                    onClick={setFilter(FILTERS.SHOW_OPEN_TICKETS_GROUPS)}
                  >
                    <Label
                      color={
                        isFilterActive(FILTERS.SHOW_OPEN_TICKETS_GROUPS)
                          ? "orange"
                          : "grey"
                      }
                    >
                      {subscriptionsWithOpenTickets.length}
                    </Label>
                    Con Tickets Abiertos
                  </Menu.Item>
                  <Menu.Item
                    name="withoutTickets"
                    onClick={setFilter(FILTERS.SHOW_ALL)}
                    active={isFilterActive(FILTERS.SHOW_ALL)}
                  >
                    <Label
                      color={
                        isFilterActive(FILTERS.SHOW_ALL) ? "orange" : "grey"
                      }
                    >
                      {fullSubscriptions.length}
                    </Label>
                    Todos
                  </Menu.Item>
                </Menu>
              </Form>
            </Accordion.Content>
          </Accordion>
        }
        collectionView={[
          <Menu key={0}>
            <Dropdown item icon="list" simple>
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="add"
                  content="Nuevo"
                  onClick={this.openUpsertModal}
                />
                <Dropdown.Divider />
                <Dropdown.Item icon="download" content="Export" />
                <Dropdown.Item icon="share" content="Share" />
              </Dropdown.Menu>
            </Dropdown>
            <Menu.Item
              icon="refresh"
              onClick={() =>
                this.updateSubscriptions(this.props.user.subscriptions)
              }
            />
            <Menu.Item icon="add" onClick={this.openUpsertModal} />
            <Menu.Item
              icon="sort alphabet descending"
              position="right"
              onClick={this.openUpsertModal}
            />
          </Menu>,
          <Segment key={1} loading={loadingSubscriptions} vertical>
            <SubscriptionsGrid
              t={t}
              subscriptions={subscriptions}
              navigateToGroup={navigateToGroup}
            />
          </Segment>
        ]}
      />
    );
  }
}

const StyledView = Radium(GroupsView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  GroupsView.mapStateToProps,
  GroupsView.mapDispatchToProps
)(i18nEnhancement);

// SUBSCRIPTION COMPONENTS

/**
 * @typedef Group
 * @type {object}
 * @property {number} [GroupId] - parent ID
 * @property {string} createdAt - Creation date string ISO8601
 * @property {string} updatedAt - Update date string ISO8601
 * @property {string} description
 * @property {number} id
 * @property {object|array} [metadata]
 * @property {string} name
 * @property {number} priority
 */

/**
 * @typedef User
 * @type {object}
 * @property {string} email
 * @property {string} fullName
 * @property {number} id
 * @property {string[]} roles
 * @property {string} createdAt - Creation date string ISO8601
 * @property {string} updatedAt - Update date string ISO8601
 * @property {string} username
 */

/**
 * @typedef Subscription
 * @type {object}
 * @property {number} id - An Id
 * @property {number} UserId- The involved user's ID
 * @property {string} createdAt - Creation date string ISO8601
 * @property {string} updatedAt - Update date string ISO8601
 * @property {number} GroupId - The ID of the group involved
 * @property {Group} Group - The group involved
 * @property {User} User - The user involved
 * @property {string[]} permissions - The permissions granted
 * @property {object} notifications - The notification subscription configuration
 * @property {string[]} notifications.email
 * @property {string[]} notifications.internal
 * @property {string[]} notifications.sms
 * @property {string[]} notifications.telegram
 */

/**
 * This callback is displayed as a global member.
 * @callback navigateToGroup
 * @param {number} groupId
 */

/**
 * @param {object} props - The component props
 * @param {function} props.t - Translation function
 * @param {Subscription} props.subscription - The subscription to be displayed
 * @param {navigateToGroup} navigateToGroup - To navigate to Group
 * @returns {Component} - Subscription Component
 */
const SubscriptionComponent = ({ subscription, t, navigateToGroup }) => {
  let localizedPermissions = subscription.permissions
    .map(aPermission => t(`subscriptions.permissions.options.${aPermission}`))
    .join(", ");
  localizedPermissions =
    localizedPermissions[0].toUpperCase() + localizedPermissions.slice(1);
  return (
    <Card onClick={navigateToGroup(subscription.GroupId)}>
      <Card.Content>
        <Card.Header>{subscription.Group.name}</Card.Header>
        <Card.Description>{subscription.Group.description}</Card.Description>
      </Card.Content>
      <Card.Content extra>{localizedPermissions}.</Card.Content>
      <Card.Content extra>
        <Label color="orange">
          {subscription.Group.stats.open} &nbsp;TICKETS ABIERTOS
        </Label>
      </Card.Content>
      <Card.Content extra>
        <Label color="grey">
          {subscription.Group.stats.total} &nbsp;TICKETS TOTALES
        </Label>
      </Card.Content>
    </Card>
  );
};

/**
 * @param {Object} props - The props
 * @param {Object[]} props.subscriptions - Subscription Array
 * @param {function} props.t - Translation function
 * @param {navigateToGroup} navigateToGroup - To navigate to the group
 * @returns {Component} - Subscription Grid Component
 */
const SubscriptionsGrid = ({ subscriptions, t, navigateToGroup }) => {
  return (
    <Card.Group>
      {subscriptions.map(aSubscription => (
        <SubscriptionComponent
          key={`subId${aSubscription.id}`}
          subscription={aSubscription}
          navigateToGroup={navigateToGroup}
          t={t}
        />
      ))}
    </Card.Group>
  );
};
