import React, { Component, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { List, Image, Icon, Menu, Label, Input } from "semantic-ui-react";
const GroupRow = props => {
  let indent = props.indent || 0;
  return (
    <Menu.Item
      style={{
        paddingLeft: indent > 0 ? 16 + 20 * indent : undefined
      }}
      active={props.active.id == props.id}
      as={Link}
      to={props.to}
      name={props.name}
      onClick={() => props.onSelect(props.id)}
    >
      {props.hideStats ? null : <Label>{props.stats}</Label>}
      {props.active.id == props.id || props.indent == -1
        ? props.downloadButton
        : null}
      <Icon
        style={!props.icon ? { color: "transparent" } : null}
        name={props.icon || "chevron down"}
      />

      {props.bold ? <b>{props.name}</b> : props.name}
    </Menu.Item>
  );
};

export default props => {
  let [searchQuery, setSearchQuery] = useState("");
  // const inputElement = useRef(null);
  // //console.log("effect", props);
  useEffect(() => {
    // //console.log("effect", inputElement.current);
    //console.log("patching");
    setSearchQuery("");
  }, [props.filter]);
  // let searchQuery = "";
  // let setSearchQuery = () => {};
  const getParentsOfGroup = (group, data) => {
    if (!group || group.GroupId === null) {
      return [];
    }
    return [
      group.GroupId,
      ...getParentsOfGroup(
        data.find(a => a.id === group.GroupId),
        data
      )
    ];
  };

  const getChildrenOfGroup = (groupId, data) => {
    return data
      .filter(a => a.GroupId === groupId)
      .reduce((p, c) => [...p, c.id, ...getChildrenOfGroup(c.id, data)], []);
  };
  const activeGroupIds =
    !props.active || !props.active.id
      ? []
      : [
          props.active.id,
          ...getChildrenOfGroup(props.active.id, props.configuration.data),
          ...getParentsOfGroup(props.active, props.configuration.data)
        ];
  // console.log("activeGroupIds", activeGroupIds);
  const searchedGroups =
    !searchQuery || searchQuery === ""
      ? []
      : props.configuration.data.filter(
          a => a.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1
        );
  const searchIds = searchedGroups.reduce((p, c) => {
    return [...p, c.id, ...getParentsOfGroup(c, props.configuration.data)];
  }, []);
  const renderGroupsWithSubgroups = (
    data,
    parentId = null,
    indent = 0,
    search = ""
  ) => {
    const activeGroups = [
      ...data.filter(
        a =>
          a.GroupId === parentId &&
          (!search || search === "" || searchIds.some(b => b === a.id))
      )
    ];

    return activeGroups
      .sort((a, b) => {
        if (
          !a.metadata ||
          a.metadata.sort === undefined ||
          !b.metadata ||
          b.metadata.sort === undefined
        ) {
          // //console.log("a", a, "b", b);
          return a.name < b.name ? -1 : 1;
        }
        return a.metadata.sort - b.metadata.sort;
      })
      .map(aGroup => {
        let isInActiveChain = activeGroupIds.some(anId => anId === aGroup.id);
        let hasChildren = data.some(a => a.GroupId === aGroup.id);
        return [
          <GroupRow
            {...aGroup}
            key={aGroup.id}
            onSelect={props.onSelect}
            bold
            icon={
              !hasChildren
                ? null
                : isInActiveChain
                ? "chevron down"
                : "chevron right"
            }
            to={props.buildUrl(undefined, aGroup.id, null, null)}
            downloadButton={
              props.type == "topic"
                ? props.downloadSectionButtonFn(aGroup.id)
                : null
            }
            hideStats={props.type == "topic" || props.hideStats}
            indent={indent}
            active={props.active || { id: null }}
            stats={aGroup.stats}
          />,
          !isInActiveChain
            ? null
            : renderGroupsWithSubgroups(data, aGroup.id, indent + 1, search)
        ];
      });
  };
  return (
    <Menu
      vertical
      fluid
      inverted={props.type == "topic" ? false : true}
      color={props.type == "topic" ? "orange" : "grey"}
      style={{ marginTop: 0 }}
      attached
    >
      {props.type == "topic" ? null : (
        <Input
          icon="search"
          fluid
          transparent
          placeholder={"Buscar Grupo..."}
          style={{ padding: 10 }}
          inverted
          value={searchQuery}
          // ref={inputElement}
          onChange={(e, c) => setSearchQuery(c.value)}
        />
      )}
      {props.type == "topic" ? null : (
        <GroupRow
          {...{ name: "Todos", id: null }}
          key={"allGroups"}
          active={props.active || { id: null }}
          onSelect={props.onSelect}
          to={props.buildUrl(undefined, null)}
          hideStats={props.hideStats}
          stats={props.configuration.data.reduce(
            (p, c) => p + (c.stats || 0),
            0
          )}
        />
      )}
      {renderGroupsWithSubgroups(
        props.configuration.data,
        null,
        0,
        searchQuery
      )}
      {/* {props.configuration.data
        .filter(
          a =>
            !a.GroupId &&
            (searchQuery == "" ||
              a.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1)
        )
        .map(aGroup => {
          let subGroups = props.configuration.data.filter(
            a => a.GroupId == aGroup.id
          );
          return [
            <GroupRow
              {...aGroup}
              key={aGroup.id}
              onSelect={props.onSelect}
              bold
              to={props.buildUrl(undefined, aGroup.id, null, null)}
              downloadButton={
                props.type == "topic"
                  ? props.downloadSectionButtonFn(aGroup.id)
                  : null
              }
              hideStats={props.type == "topic" || props.hideStats}
              indent={
                props.active &&
                (props.active.id == aGroup.id ||
                  props.active.GroupId == aGroup.id) &&
                subGroups.length > 0
                  ? -1
                  : subGroups.length > 0
                    ? -2
                    : undefined
              }
              active={props.active || { id: null }}
              stats={aGroup.stats}
            />,
            !props.active ||
            props.active.id == null ||
            (props.active.id != aGroup.id && props.active.GroupId != aGroup.id)
              ? null
              : subGroups
                .sort((a, b) => {
                  if (
                    !a.metadata ||
                      a.metadata.sort === undefined ||
                      !b.metadata ||
                      b.metadata.sort === undefined
                  ) {
                    // //console.log("a", a, "b", b);
                    return a.name < b.name ? -1 : 1;
                  }
                  return a.metadata.sort - b.metadata.sort;
                })
                .map(internalGroup => (
                  <GroupRow
                    {...internalGroup}
                    key={internalGroup.id}
                    indent={1}
                    to={props.buildUrl(
                      undefined,
                      internalGroup.id,
                      null,
                      null
                    )}
                    onSelect={props.onSelect}
                    hideStats={props.type == "topic" || props.hideStats}
                    active={props.active || { id: null }}
                    downloadButton={
                      props.type == "topic"
                        ? props.downloadSectionButtonFn(internalGroup.id)
                        : null
                    }
                    stats={internalGroup.stats}
                  />
                ))
          ];
        })} */}
      <div style={{ flex: 1 }} />
    </Menu>
  );
};
