import React from "react";
import { Form, Grid, Image } from "semantic-ui-react";
import { SketchPicker } from "react-color";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

const GroupsForm = ({ inputHandler, loading, groups, values, submit }) => {
  const handleInit = () => {
    pond._pond.setOptions({
      allowFileTypeValidation: true,
      acceptedFileTypes: ["image/*"],
      imageCropAspectRatio: "1:1",
      allowImageCrop: true,
      imageCropAspectRatio: "1:1",
      imageResizeTargetWidth: 200,
      imageResizeTargetHeight: 200
    });
  };

  let pond = null;

  return (
    <div>
      <Form onSubmit={submit} loading={loading}>
        <Form.Group>
          <Form.Input
            name="name"
            label="Nombre"
            value={values.name}
            onChange={inputHandler}
            width={6}
          />
          <Form.Input
            name="description"
            label="Descripcion"
            value={values.description}
            onChange={inputHandler}
            width={10}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            name="priority"
            label="Prioridad"
            type="number"
            value={values.priority}
            onChange={inputHandler}
            width={3}
          />
          <Form.Select
            name="GroupId"
            label="Supergrupo"
            value={values.GroupId}
            onChange={inputHandler}
            options={groups}
            search
            width={13}
          />
        </Form.Group>
      </Form>
      <Grid columns={2}>
        <Grid.Column>
          <SketchPicker color={values.color} onChangeComplete={inputHandler} />
        </Grid.Column>
        <Grid.Column>
          <h3>Cambiar Logo</h3>
          <FilePond
            ref={ref => (pond = ref)}
            allowMultiple={false}
            maxFiles={1}
            name="uri"
            server="/api/file"
            oninit={handleInit}
            onprocessfile={e => {
              // Set current file objects to this.state
              //console.log("e", e);
              inputHandler(
                {},
                {
                  name: "logo",
                  value: pond.getFiles().pop()
                }
              );
            }}
            onupdatefiles={fileItems => {
              // Set current file objects to this.state
              inputHandler(
                {},
                {
                  name: "logo",
                  value: fileItems.map(fileItem => fileItem.file).pop()
                }
              );
            }}
          >
            {/* Update current files  */}
          </FilePond>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default GroupsForm;
