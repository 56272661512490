import { Creators } from './store/actions/authentication';

/**
 * Authentication function
 * @param  {Object} options  Options for authentication with feathersjs
 * @param  {Object} client  API Client to authenticate
 * @param  {function} dispatch Method to dispatch events
 * @return {promise}          Resolution of authentication promise
 */
const authenticate = (options, client, dispatch) => {
  return new Promise((resolve, reject) => {

    dispatch(Creators.attempt());

    client
      .authenticate(options)
      .then(response => {
        return client.passport.verifyJWT(response.accessToken);
      })
      .then(payload => {
        return client.service('api/users').get(payload.userId);
      })
      .then(user => {
        dispatch(Creators.success(null, user));
        client.set('user', user);
        client.set('error', null);
        resolve(user);
      })
      .catch(error => {
        dispatch(Creators.failure(error));
        client.set('user', null);
        client.set('error', error);
        reject(error);
      });
  });
};

export default (client, dispatch) => {
  return (options) => authenticate(options, client, dispatch);
};
