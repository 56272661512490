import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import update from "immutability-helper";
import moment from "moment";
import {
  Breadcrumb,
  Container,
  Divider,
  Feed,
  Header,
  Responsive
} from "semantic-ui-react";
import * as Routes from "../../../routes";

const INITIAL_STATE = {
  notifications: {
    loading: false,
    data: [],
    error: null
  }
};

class NotificationsView extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
    this.NotificationService = props.services.feathers.service(
      "/notifications"
    );
  }

  mapNotificationIcon = notification => {
    const selector = {
      tickets: () => "mail"
    };
    const func = selector[notification.resourceType];

    if (func) {
      return func();
    } else {
      return "remove";
    }
  };

  componentDidMount() {
    this.setState(
      update(this.state, {
        notifications: {
          $merge: {
            loading: true,
            data: [],
            error: null
          }
        }
      })
    );

    this.NotificationService.find({
      query: {
        UserId: this.props.authentication.user.id,
        channel: "internal",
        $sort: {
          createdAt: -1
        }
      }
    })
      .then(response => {
        this.setState(
          update(this.state, {
            notifications: {
              $merge: {
                loading: false,
                data: response.data,
                error: null
              }
            }
          }),
          () => {
            if (response.data.some(notif => !notif.notified)) {
              this.updateNotificationStatus(response.data);
            }
          }
        );
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            notifications: {
              $merge: {
                loading: false,
                error
              }
            }
          })
        );
      });
  }

  updateNotificationStatus = data => {
    this.NotificationService.patch(
      null,
      { notified: true },
      {
        query: {
          id: {
            $in: data.map(n => n.id)
          }
        }
      }
    )
      .then(response => {
        //console.log("@NotificationView", response);
      })
      .catch(error => {
        console.error("@NotificationView", error);
      });
  };

  static mapStateToProps(state) {
    return {
      authentication: state.authentication.toJS()
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path))
    };
  }

  render() {
    let { notifications } = this.state;

    return (
      <Container style={{ padding: 12 }}>
        <Responsive minWidth={769}>
          <Breadcrumb>
            <Breadcrumb.Section as={Link} to={Routes.DASHBOARD} link>
              Principal
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Notificaciones</Breadcrumb.Section>
          </Breadcrumb>
          <Divider />
        </Responsive>

        <Header dividing size="large" style={{ margin: 0 }}>
          <Header.Content>Notificaciones</Header.Content>
        </Header>

        {notifications.loading && "Cargando..."}

        <Feed>
          {notifications.data.map((notification, index) => {
            let now = moment();
            let notificationTime = moment(notification.createdAt);
            let diff = now.diff(notificationTime, "minutes");
            let timeElapsedSinceNotif = "";

            if (diff < 60) {
              // less than an hour
              timeElapsedSinceNotif = `Hace ${diff} minutos`;
            } else if (diff >= 60 && diff < 60 * 24) {
              // less than a day
              timeElapsedSinceNotif = `Hace ${Math.floor(diff / 60)} horas`;
            } else {
              // more than a day
              timeElapsedSinceNotif = notificationTime.format("DD/MM HH:mm");
            }

            return (
              <Feed.Event key={index}>
                <Feed.Label icon={this.mapNotificationIcon(notification)} />
                <Feed.Content>
                  <Feed.Summary>
                    <Link to={`/tickets/${notification.resourceId}`}>
                      Ticket#
                      {notification.resourceId}
                    </Link>{" "}
                    {notification.title}
                    <Feed.Date>{timeElapsedSinceNotif}</Feed.Date>
                  </Feed.Summary>
                  <Feed.Meta>{notification.content.body}</Feed.Meta>
                </Feed.Content>
              </Feed.Event>
            );
          })}
        </Feed>
      </Container>
    );
  }
}

NotificationsView.propTypes = {
  mobile: PropTypes.bool
};

NotificationsView.defaultProps = {
  mobile: false
};

const i18nEnhancement = translate("translations")(NotificationsView);

export default connect(
  NotificationsView.mapStateToProps,
  NotificationsView.mapDispatchToProps
)(i18nEnhancement);
