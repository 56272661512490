import React from 'react';
import { Header, Form, Message } from 'semantic-ui-react';
import ReactQuill, { Quill } from 'react-quill';

const ArticleForm = ({ loading, values, error, inputHandler, superSectionHandler, quillHandler, upsert, sections, subsections, SuperSectionId }) => (
  <Form loading={loading} onSubmit={upsert}>
    <Form.Group widths="equal">
      <Form.Select
        id="SuperSectionId" label="Seccion"
        options={sections} search
        value={SuperSectionId} onChange={superSectionHandler} />
      <Form.Select
        id="SectionId" label="Subsection"
        options={subsections} search
        value={values.SectionId} onChange={inputHandler} />
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Input
        id="subject" label="Asunto"
        value={values.subject} onChange={inputHandler} />
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field>
        <Header>Cuerpo</Header>
        <ReactQuill
          id="body"
          value={values.body} style={{ height: 200, marginBottom: 50 }}
          onChange={quillHandler} />
      </Form.Field>
    </Form.Group>
    <Form.Group>
      <Form.Input
        id="sort" type="number" label="Orden"
        value={values.sort} onChange={inputHandler} />
    </Form.Group>
    {
      error &&
        <Message negative>
          <Message.Header>{error.name}</Message.Header>
          <p>{error.message}</p>
        </Message>
    }
  </Form>
);

export default ArticleForm;