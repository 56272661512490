import React, { Component, useState, useEffect } from "react";
import ConnectServices from "../../../providers/ConnectServices";
import { Popup } from "semantic-ui-react";
function SemaphoreView(props) {
  // //console.log("props", props);
  const feathers = props.services.feathers;
  const [ticketCount, setTicketCount] = useState(-1);
  const [averageTickets, setAverageTickets] = useState(-1);
  useEffect(() => {
    feathers
      .service("api/stats")
      .find({
        query: { $limit: 1, key: "openTicketsCount", $sort: { createdAt: -1 } }
      })
      .then(({ data }) => {
        setTicketCount(data[0].intValue);
      });
  }, []);

  useEffect(() => {
    feathers
      .service("api/stats")
      .find({
        query: { $limit: 1, tag: "edutrack", key: "averageOpenTickets" }
      })
      .then(({ data }) => {
        //console.log("data", data);
        setAverageTickets(data[0].floatValue);
      })
      .catch(error => {
        //console.log("semaphoreerror", error);
      });
  }, []);

  feathers.service("api/stats").on("patched", patchedStat => {
    if (
      patchedStat.tag == "edutrack" &&
      patchedStat.key == "averageOpenTickets"
    ) {
      setAverageTickets(patchedStat.floatValue);
    }
  });

  feathers.service("api/stats").on("created", createdStat => {
    if (createdStat.key == "openTicketsCount") {
      setTicketCount(createdStat.intValue);
    }
  });

  const difference = Math.abs(averageTickets - ticketCount);
  //console.log("difference", difference);
  const color =
    averageTickets == -1 || ticketCount == -1
      ? "grey"
      : difference < 5
        ? "#ebd534"
        : averageTickets > ticketCount
          ? "#BADA55"
          : "#fc5a0f";

  return averageTickets != -1 && ticketCount != -1 ? (
    <Popup
      trigger={
        <div>
          <div
            className="circle"
            style={{
              backgroundColor: color,
              display: "table",
              marginLeft: "5px"
            }}>
            <div
              style={{
                color: "white",
                display: "table-cell",
                verticalAlign: "middle",
                fontSize: "11pt",
                textAlign: "center"
              }}>
              {averageTickets != -1 && ticketCount != -1
                ? Math.floor(difference)
                : ""}
            </div>
          </div>
        </div>
      }
      content={`Hay ${ticketCount} tickets abiertos. La desviación es de ${Math.floor(
        difference
      )} tickets, y el promedio es de ${averageTickets.toFixed(2)}.`}
      inverted
    />
  ) : null;
}
const withServices = ConnectServices(SemaphoreView);
export default withServices;
