import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import _ from "lodash";
// import HTML5Backend from 'react-dnd-html5-backend'
// import { DndProvider } from 'react-dnd'
// This only needs to be done once; probably during your application's bootstrapping process.
import "react-sortable-tree/style.css";
// You can import the default tree with dnd context
import SortableTree, {
  getTreeFromFlatData,
  getFlatDataFromTree,
  changeNodeAtPath,
  toggleExpandedForAll
} from "react-sortable-tree";
import {
  Container,
  Header,
  Menu,
  Segment,
  Message,
  Button,
  Form,
  Dropdown,
  Checkbox,
  List,
  Input
} from "semantic-ui-react";
function AdminWikiView(props) {
  // handleItemClick = (e, { name }) => this.setState({ activeItem: name })
  const {
    match: { params }
  } = props;
  const section = params.section;
  return (
    <Container style={{ marginTop: 30, overflowY: "scroll" }}>
      <Header content="Administrador de la Documentación" />
      <Menu attached="top" tabular>
        <Menu.Item
          name="Secciones"
          active={section === "sections" || section === undefined}
          as={Link}
          to={"/admin/resources/wiki/sections"}
        />
        <Menu.Item
          name="Subscripciones"
          active={section === "subscriptions"}
          as={Link}
          to={"/admin/resources/wiki/subscriptions"}
        />
      </Menu>
      {section === "sections" ? <AdminWikiSections {...props} /> : null}
      {section === "subscriptions" ? (
        <AdminWikiSubscriptions {...props} />
      ) : null}
    </Container>
  );
}

const i18nEnhancement = translate("translations")(AdminWikiView);

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigate: path => dispatch(push(path))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(i18nEnhancement);

function AdminWikiSections(props) {
  const [tree, setTree] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const feathers = props.services.feathers;
  useEffect(() => {
    reloadData();
  }, []);

  const reloadData = () => {
    feathers
      .service("api/groups")
      .find({
        query: {
          $limit: -1,
          type: "topic"
        }
      })
      .then(data => {
        setGroups(data);
        setLoading(false);
        let treeVal = toggleExpandedForAll({
          treeData: getTreeFromFlatData({
            flatData: data
              .map(a => ({ ...a, title: a.name }))
              .sort((a, b) => a.metadata.sort - b.metadata.sort),
            getParentKey: n => n.GroupId,
            rootKey: null
          })
        });
        console.log("tree", treeVal);
        setTree(treeVal);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  const saveChanges = async () => {
    try {
      const groups = getFlatDataFromTree({
        treeData: tree,
        ignoreCollapsed: false,
        getNodeKey: node => node.id
      }).map((a, idx) => ({
        name: a.node.name,
        metadata: { ...a.node.metadata, sort: idx },
        id: a.node.id,
        GroupId: !!a.parentNode ? a.parentNode.id : null
      }));
      setLoading(true);
      const promises = groups.map(aGroup =>
        feathers.service("api/groups").patch(aGroup.id, aGroup)
      );
      console.log("result", promises);
      reloadData();
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeNodeName = async ({ node, path, treeIndex }) => {
    let newName = window.prompt("Nuevo Nombre", node.title);
    if (newName != null && newName != node.title) {
      console.log("node", node);
      // const changedNode = {...node, title:newName, name: newName };
      setLoading(true);
      await feathers.service("api/groups").patch(node.id, { name: newName });
      reloadData();
    }
  };

  const deleteNode = async ({ node, path, treeIndex }) => {
    let confirmation = window.confirm("Estás seguro?");
    if (!!confirmation) {
      console.log("confirmation", confirmation);
      // const changedNode = {...node, title:newName, name: newName };
      setLoading(true);
      await feathers.service("api/groups").remove(node.id);
      reloadData();
    }
  };

  const createNode = async ({ node, path, treeIndex }) => {
    let newName = window.prompt("Nombre", "");
    try {
      if (newName != null && newName.length > 0) {
        const newGroup = {
          type: "topic",
          name: newName,
          GroupId: node.GroupId,
          metadata: { sort: -1 }
        };
        setLoading(true);
        let response = await feathers.service("api/groups").create(newGroup);
        console.log("response", response);
        reloadData();
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log("error", error);
    }
  };

  const createArticle = async ({ node }) => {
    let newName = window.prompt("Titulo del borrador de articulo", "");
    try {
      if (newName != null && newName.length > 0) {
        setLoading(true);
        const [ticket] = await feathers.service("api/tickets").create({
          subject: newName,
          UserId: 1,
          GroupId: node.id,
          global: false,
          type: "topic",
          tags: [],
          status: "open"
        });
        console.log("ticket", ticket);
        const prom = await Promise.all([
          feathers
            .service("api/tickets")
            .patch(ticket.id, { customSortIndex: ticket.id }),
          feathers.service("api/ticket-activities").create({
            type: "message",
            TicketId: ticket.id,
            UserId: 1,
            TicketCreatorId: 1,
            UserShortDescription: "Admin",
            role: "agent",
            mediaType: "html",
            body: ""
          })
        ]);
        console.log("response", ticket, prom);
        setLoading(false);
        props.navigate(`/wiki/open/${node.id}/${ticket.id}`);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log("error", error);
    }
  };

  return (
    <Segment attached="bottom" loading={loading}>
      {!error ? null : <Message negative content={error.message} />}
      <div>
        <Button
          icon="save"
          positive
          content="Guardar"
          disabled={loading}
          onClick={saveChanges}
        />
        {loading ? null : (
          <SortableTree
            style={{ height: Math.max(62 * groups.length, 62) }}
            treeData={tree}
            onChange={setTree}
            generateNodeProps={rowInfo => ({
              buttons: [
                <Button
                  icon="compose"
                  basic
                  color="yellow"
                  onClick={() => changeNodeName(rowInfo)}
                />,
                <Button
                  icon="plus"
                  basic
                  positive
                  onClick={() => createNode(rowInfo)}
                />,
                <Button
                  icon="trash"
                  negative
                  basic
                  onClick={() => deleteNode(rowInfo)}
                />,
                <Button
                  content="Crear Borrador de Articulo"
                  basic
                  onClick={() => createArticle(rowInfo)}
                />
              ]
            })}
          />
        )}
      </div>
    </Segment>
  );
}

function AdminWikiSubscriptions(props) {
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const feathers = props.services.feathers;
  const [subscriptionCollections, setSubscriptionCollections] = useState([]);
  const [activeCollection, setActiveCollection] = useState(null);
  const [nameValue, setNameValue] = useState("");

  const loadSubs = () => {
    setLoading(true);
    feathers
      .service("api/subscription-collections")
      .find({
        query: {
          $limit: -1
        }
      })
      .then(response => {
        console.log("response", response);
        setSubscriptionCollections(response);
        setLoading(false);
      })
      .catch(error => {
        console.log("error", error);
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    feathers
      .service("api/groups")
      .find({
        query: {
          $limit: -1
        }
      })
      .then(response => setGroups(response))
      .catch(error => setError(error));
    feathers
      .service("api/users")
      .find({
        query: {
          $limit: -1
        }
      })
      .then(response => setUsers(response))
      .catch(error => setError(error));
    loadSubs();
  }, []);

  const [newGroup, setNewGroup] = useState({
    GroupId: null,
    recursive: false,
    type: "topic"
  });

  const getFullPathOfNames = (group, groups, separator = " : ") => {
    if (!group.GroupId) {
      return group.name;
    }
    let parent = groups.find(a => a.id == group.GroupId);
    if (!parent) {
      return group.name;
    }
    return `${getFullPathOfNames(parent, groups, separator)}${separator}${
      group.name
    }`;
  };

  let topicGroups = groups.filter(a => a.type == "topic");
  topicGroups = topicGroups
    .map(a => ({ ...a, qualifiedName: getFullPathOfNames(a, topicGroups) }))
    .sort((a, b) => (a.qualifiedName < b.qualifiedName ? -1 : 1));
  const ticketGroups = groups.filter(a => a.type == "regular" || !a.type);

  const collection = !activeCollection
    ? null
    : (subscriptionCollections || []).find(a => a.id == activeCollection) || [];

  return (
    <Segment attached="bottom" loading={loading}>
      <Header as={"h1"}>Colecciones</Header>

      <Dropdown
        placeholder="Grupo de subscripción"
        size="6"
        attached="left"
        selection
        value={activeCollection}
        onChange={(e, { value }) => {
          setActiveCollection(value);
          setNameValue("");
        }}
        options={subscriptionCollections.map(a => ({
          key: a.id,
          text: `${a.name} - ID #${a.id}`,
          value: a.id
        }))}
      />
      <Button
        icon="plus"
        positive
        content="Nueva colección"
        onClick={() => {
          setLoading(true);
          feathers
            .service("api/subscription-collections")
            .create({ configuration: [] })
            .then(r => {
              setActiveCollection(r.id);
              setNameValue("");
              loadSubs();
            });
        }}
      />
      {!collection ? null : (
        <Header as="h2">
          Colección ID #{collection.id} - {collection.name}
        </Header>
      )}
      {!collection ? null : (
        <Segment basic>
          <Input
            size={3}
            label={"Nombre"}
            defaultValue={collection.name}
            key={collection.name}
            onBlur={event => {
              if (!loading) {
                setLoading(true);
                feathers
                  .service("api/subscription-collections")
                  .patch(collection.id, {
                    name: event.target.value
                  })
                  .then(r => loadSubs());
              }
            }}
          />
        </Segment>
      )}
      {!collection ? null : (
        <Button
          icon="copy"
          positive
          content="Duplicar Colección"
          onClick={() => {
            setLoading(true);
            setActiveCollection(null);
            feathers
              .service("api/subscription-collections")
              .create({
                configuration: [...collection.configuration]
              })
              .then(r => {
                setActiveCollection(r.id);
                setNameValue("");
                loadSubs();
              });
          }}
        />
      )}
      {!collection ? null : (
        <Button
          icon="trash"
          negative
          content="Eliminar Colección"
          onClick={() => {
            setLoading(true);
            setActiveCollection(null);
            feathers
              .service("api/subscription-collections")
              .remove(collection.id)
              .then(r => {
                setNameValue("");
                loadSubs();
              });
          }}
        />
      )}

      {!collection ? null : <Header>Subscripciones invertidas</Header>}
      {!collection ? null : (
        <Form>
          <Form.Group inline>
            <Form.Field>
              <Dropdown
                selection
                search
                value={collection.GroupId}
                placeholder={"Grupo Predefinido"}
                onChange={(e, { value }) => {
                  setLoading(true);
                  feathers
                    .service("api/subscription-collections")
                    .patch(activeCollection, { GroupId: value })
                    .then(() => loadSubs());
                }}
                options={ticketGroups.map(aG => ({
                  key: aG.id,
                  value: aG.id,
                  text: getFullPathOfNames(aG, topicGroups)
                }))}
              />
            </Form.Field>
            <Form.Field>
              <Dropdown
                selection
                search
                value={collection.UserId}
                placeholder={"Usuario Predefinido"}
                onChange={(e, { value }) => {
                  setLoading(true);
                  feathers
                    .service("api/subscription-collections")
                    .patch(activeCollection, { UserId: value })
                    .then(() => loadSubs());
                }}
                options={users.map(aUser => ({
                  key: aUser.id,
                  value: aUser.id,
                  text: aUser.fullName
                }))}
              />
            </Form.Field>
            <Form.Field>
              <Button
                onClick={() => {
                  setLoading(true);
                  feathers
                    .service("api/subscription-collections")
                    .patch(activeCollection, { UserId: null, GroupId: null })
                    .then(() => loadSubs());
                }}
              >
                Cancelar subscripcion invertida
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      )}
      {!collection ? null : <Header>Grupos de la colección</Header>}
      {!collection || !collection.configuration ? null : (
        <List divided>
          {collection.configuration.map((a, index) => {
            const group = groups.find(b => b.id == a.GroupId);
            if (!group) {
              return null;
            }
            return (
              <List.Item key={index}>
                <List.Content>{getFullPathOfNames(group, groups)}</List.Content>
                <List.Content floated="right">
                  <Button
                    icon="trash"
                    content={"Eliminar"}
                    basic
                    negative
                    onClick={() => {
                      setLoading(true);
                      feathers
                        .service("api/subscription-collections")
                        .patch(activeCollection, {
                          configuration: [
                            ...collection.configuration.filter(
                              (v, i) => i != index
                            )
                          ]
                        })
                        .then(() => loadSubs());
                    }}
                  />
                </List.Content>
                <List.Content floated="right">
                  <Checkbox
                    label={"Recursivo"}
                    checked={a.recursive}
                    onChange={() => {
                      setLoading(true);
                      feathers
                        .service("api/subscription-collections")
                        .patch(activeCollection, {
                          configuration: [
                            ...collection.configuration.map((c, index2) =>
                              index2 != index
                                ? c
                                : { ...c, recursive: !a.recursive }
                            )
                          ]
                        })
                        .then(() => loadSubs());
                    }}
                  />
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      )}
      {!collection || !collection.configuration ? null : (
        <Form>
          <Form.Group inline>
            <Form.Field>
              <Dropdown
                selection
                search
                value={newGroup.GroupId}
                placeholder={"Otro Grupo"}
                onChange={(e, { value }) =>
                  setNewGroup({ ...newGroup, GroupId: value })
                }
                options={topicGroups.map(aG => ({
                  key: aG.id,
                  value: aG.id,
                  text: getFullPathOfNames(aG, groups)
                }))}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={newGroup.recursive}
                label={"Recursivo"}
                onChange={(e, { value }) =>
                  setNewGroup({
                    ...newGroup,
                    recursive: !newGroup.recursive
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <Button
                content="Agregar"
                icon={"plus"}
                onClick={() => {
                  setLoading(true);
                  feathers
                    .service("api/subscription-collections")
                    .patch(activeCollection, {
                      ...collection,
                      configuration: [
                        ...collection.configuration,
                        { ...newGroup }
                      ]
                    })
                    .then(() => {
                      loadSubs();
                      setNewGroup({
                        GroupId: null,
                        recursive: false,
                        type: "topic"
                      });
                    });
                }}
                disabled={!newGroup.GroupId}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      )}
    </Segment>
  );
}
