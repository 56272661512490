import React, { Component } from "react";
import Radium, { Style } from "radium";
import ReactTable from "react-table";
import moment from "moment";
import { find } from "lodash";
import update from "immutability-helper";

import {
  Button,
  Breadcrumb,
  Container,
  Divider,
  Header,
  Icon,
  Message,
  Modal,
  Segment,
  Statistic
} from "semantic-ui-react";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import * as Routes from "../../../../../routes";
import { DEFAULT_FONT_SIZE } from "./constants";
import GroupsTable from "./GroupsTable";
import GroupsForm from "./GroupsForm";

const INITIAL_STATE = {
  groups: {
    loading: false,
    data: [],
    limit: 9999,
    skip: 0,
    total: 0,
    error: null
  },
  upsertModal: {
    open: false,
    loading: false,
    values: {
      id: null,
      name: "",
      description: "",
      color: "#ef6612",
      priority: 0,
      GroupId: null,
      logo: null
    },
    error: null,
    message: ""
  },
  removeModal: {
    open: false,
    loading: false,
    id: null,
    error: null
  }
};

class AdminGroupsView extends Component {
  constructor(props) {
    super(props);

    const { feathers } = this.props.services;

    this.state = INITIAL_STATE;
    this.GroupService = feathers.service("api/groups");
  }

  componentDidMount() {
    this.findGroups();
  }

  static mapStateToProps(state) {
    return {
      authentication: state.authentication
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path))
    };
  }

  openUpsertModal = (e, id) => {
    let { groups, upsertModal } = this.state;
    let group = null;
    let newState = {};

    if (id) {
      group = find(groups.data, g => g.id === id);

      newState = {
        upsertModal: {
          ...upsertModal,
          values: {
            id,
            name: group.name,
            description: group.description,
            priority: group.priority,
            GroupId: group.GroupId,
            color: group.color,
            logo: { serverId: group.logo, status: 5, mocked: true }
          },
          open: true
        }
      };
    } else {
      newState = {
        upsertModal: {
          ...INITIAL_STATE.upsertModal,
          open: true
        }
      };
    }

    this.setState(newState);
  };

  dissmissUpsertModal = (e) => {
    let { upsertModal } = this.state;
    let go = e == true;
    if (!go) {
      go = window.confirm("Todavia no has confirmado los cambios. Estás seguro que quieres salir?");
    }
    if (!go) {
      return
    }
    this.setState({
      upsertModal: INITIAL_STATE.upsertModal
    });
  };

  openRemoveModal = id => {
    let { removeModal } = this.state;
    this.setState({
      removeModal: {
        ...removeModal,
        id,
        open: true
      }
    });
  };

  dismissRemoveModal = () => {
    let { removeModal } = this.state;
    this.setState({
      removeModal: INITIAL_STATE.removeModal
    });
  };

  findGroups = () => {
    let { groups } = this.state;

    this.setState(
      update(this.state, {
        groups: {
          $merge: {
            ...INITIAL_STATE.groups,
            loading: true
          }
        }
      })
    );

    this.GroupService.find({ query: { $skip: 0, $limit: 9999 } })
      .then(response => {
        //console.log(response);
        this.setState(
          update(this.state, {
            groups: {
              $merge: {
                loading: false,
                ...response
              }
            }
          })
        );
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            groups: {
              $merge: {
                loading: false,
                error
              }
            }
          })
        );
      });
  };

  mapGroupsToOptions = groups => {
    let result = groups.map((group, index) => ({
      key: index + 1,
      text: group.name,
      value: group.id
    }));

    return [{ key: 0, text: "NINGUNO", value: "" }].concat(result);
  };

  createGroup = () => {
    const { groups } = this.props;
    const { upsertModal } = this.state;

    this.setState(
      update(this.state, {
        upsertModal: {
          $merge: { loading: true }
        }
      })
    );

    this.GroupService.create({
      ...upsertModal.values,
      logo:
        !!upsertModal.values.logo && !!upsertModal.values.logo.serverId
          ? upsertModal.values.logo.serverId
          : undefined
    })
      .then(response => {
        this.setState(
          update(this.state, {
            upsertModal: {
              $merge: {
                ...INITIAL_STATE.upsertModal,
                open: true,
                message: "Grupo creado con exito!"
              }
            }
          }),
          this.findGroups
        );
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            upsertModal: {
              $merge: {
                loading: false,
                error
              }
            }
          })
        );
      });
  };

  updateGroup = () => {
    const { groups } = this.props;
    const { upsertModal } = this.state;

    this.setState(
      update(this.state, {
        upsertModal: {
          $merge: { loading: true }
        }
      })
    );

    this.GroupService.patch(upsertModal.values.id, {
      ...upsertModal.values,
      logo:
        !!upsertModal.values.logo && !!upsertModal.values.logo.serverId
          ? upsertModal.values.logo.serverId.replace(/"/g, "")
          : undefined
    })
      .then(response => {
        this.setState(
          update(this.state, {
            upsertModal: {
              $merge: {
                ...INITIAL_STATE.upsertModal,
                message: "Usuario actualizado con exito!"
              }
            }
          }),
          this.findGroups
        );
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            upsertModal: {
              $merge: {
                loading: false,
                error
              }
            }
          })
        );
      });
  };

  upsertGroup = () => {
    const {
      upsertModal: { values }
    } = this.state;

    if (values.id) {
      this.updateGroup();
    } else {
      this.createGroup();
    }
  };

  removeGroup = () => {
    const { removeModal } = this.state;

    this.setState(
      update(this.state, {
        removeModal: {
          $merge: {
            loading: true
          }
        }
      })
    );

    this.GroupService.remove(removeModal.id)
      .then(response => {
        this.setState({
          removeModal: INITIAL_STATE.removeModal
        });

        this.dismissRemoveModal();
        this.findGroups();
      })
      .catch(error => {
        this.setState(
          update(this.state, {
            removeModal: {
              $merge: {
                loading: false,
                error
              }
            }
          })
        );
      });
  };

  inputHandler = (e, input) => {
    const { upsertModal } = this.state;

    if (e.hex) {
      this.setState({
        upsertModal: {
          ...upsertModal,
          values: {
            ...upsertModal.values,
            color: e.hex
          }
        }
      });
    } else {
      this.setState({
        upsertModal: {
          ...upsertModal,
          values: {
            ...upsertModal.values,
            [input.name]: input.value
          }
        }
      });
    }
  };

  render() {
    const { t } = this.props;
    const { groups, upsertModal, removeModal } = this.state;

    return (
      <Container style={{ padding: 12, overflowY: "scroll" }}>
        <Style
          rules={{
            ".edit-icon-hover": {
              cursor: "pointer"
            },
            ".edit-icon-hover:hover": {
              color: "blue"
            },
            ".trash-icon-hover": {
              cursor: "pointer",
              color: "red"
            },
            ".trash-icon-hover:hover": {
              color: "orange"
            }
          }}
        />

        <Breadcrumb>
          <Breadcrumb.Section as={Link} to={Routes.DASHBOARD}>
            Principal
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section as={Link} link to={Routes.ADMIN}>
            Administracion
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section as={Link} link to={Routes.ADMIN_RESOURCES}>
            Recursos
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Groups</Breadcrumb.Section>
        </Breadcrumb>

        <Divider />

        <Header dividing size="large" style={{ margin: 0 }}>
          <Icon name="users" />
          <Header.Content>
            ADMINISTRACION DE GROUPS
            <Header.Subheader>
              Alta, Baja y Modificacion de Grupos
            </Header.Subheader>
          </Header.Content>
        </Header>

        <Statistic.Group style={{ margin: 12 }}>
          <Statistic
            horizontal
            label="Registros"
            value={groups.total}
            size="mini"
            style={{ marginTop: 0, marginBottom: 0, marginRight: 0 }}
          />
          <Statistic
            horizontal
            label="Limitar"
            value={groups.limit}
            size="mini"
            style={{ marginTop: 0, marginBottom: 0 }}
          />
          <Statistic
            horizontal
            label="Saltar"
            value={groups.skip}
            size="mini"
            style={{ marginTop: 0, marginBottom: 0 }}
          />
        </Statistic.Group>

        <Segment clearing size="mini" vertical>
          <Button
            icon="refresh"
            color="green"
            size="mini"
            labelPosition="left"
            content="Refrescar"
            onClick={this.findGroups}
          />

          <Button
            icon="add"
            color="blue"
            size="mini"
            labelPosition="left"
            floated="right"
            content="Nuevo"
            onClick={e => this.openUpsertModal(e)}
          />
        </Segment>

        <Segment
          vertical
          style={{ fontFamily: "monospace", fontSize: DEFAULT_FONT_SIZE }}>
          <GroupsTable
            data={groups.data}
            loading={groups.loading}
            navigate={this.props.navigate}
            updateGroup={this.openUpsertModal}
            removeGroup={this.openRemoveModal}
          />
        </Segment>

        <Modal
          open={upsertModal.open}
          onClose={this.dissmissUpsertModal}
          size="small">
          <Header
            icon={upsertModal.values.id ? "edit" : "add"}
            content={upsertModal.values.id ? "Editar" : "Nuevo"}
          />
          <Modal.Content>
            <GroupsForm
              loading={upsertModal.loading}
              groups={this.mapGroupsToOptions(groups.data)}
              submit={this.upsertGroup}
              values={upsertModal.values}
              inputHandler={this.inputHandler}
            />
            {upsertModal.error && (
              <Message
                negative
                header={upsertModal.error.name}
                list={
                  upsertModal.error.errors &&
                  upsertModal.error.errors.map(e => e.message)
                }
              />
            )}
            {upsertModal.message && (
              <Message positive header={upsertModal.message} />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              color="red"
              disabled={upsertModal.loading || upsertModal.loading}
              onClick={this.dissmissUpsertModal}>
              <Icon name="remove" /> Cancelar
            </Button>
            <Button
              color="green"
              disabled={upsertModal.loading || upsertModal.loading}
              onClick={this.upsertGroup}>
              <Icon name="checkmark" /> Aceptar
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={removeModal.open} basic size="small">
          <Header icon="trash" content="Eliminar Grupo" />
          <Modal.Content>
            <p>Seguro desea eliminar el grupo ''?</p>
            {removeModal.error && (
              <Message
                negative
                header={removeModal.error.message}
                list={
                  Array.isArray(removeModal.error.errors)
                    ? removeModal.error.errors.map(e => e.message)
                    : []
                }
              />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              color="red"
              disabled={removeModal.loading}
              inverted
              onClick={this.dismissRemoveModal}>
              <Icon name="remove" /> No
            </Button>
            <Button
              color="green"
              disabled={removeModal.loading}
              inverted
              onClick={this.removeGroup}>
              <Icon name="checkmark" />{" "}
              {removeModal.loading ? "Eliminando . . ." : "Si"}
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

const StyledView = Radium(AdminGroupsView);
const i18nEnhancement = translate("translations")(StyledView);

export default connect(
  AdminGroupsView.mapStateToProps,
  AdminGroupsView.mapDispatchToProps
)(i18nEnhancement);
