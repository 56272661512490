import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import queryString from "qs";
import { Icon, Menu, Segment, Sidebar, Responsive } from "semantic-ui-react";
import * as ROUTES from "../../routes";
import * as Views from "./";
import Authorize from "../Authorize";
import NavigationBar from "../navigation/NavigationBar";
import Footer from "../navigation/Footer";

// Views
import WelcomeView from "../views/WelcomeView";
import SignInView from "../views/SignInView";
import VerifyView from "../views/VerifyView";
import ForgotView from "../views/ForgotView";
import DashboardView from "../views/dashboard/DashboardView";
import NotificationsView from "../views/notifications/NotificationsView";

import AdminView from "../views/admin/AdminView";
import AdminResourcesView from "../views/admin/resources/AdminResourcesView";
import AdminUsersView from "../views/admin/resources/users/AdminUsersView";
import AdminGroupsView from "../views/admin/resources/groups/AdminGroupsView";
import AdminSubscriptionsView from "../views/admin/resources/subscriptions/AdminSubscriptionsView";
import AdminWikiView from "../views/admin/resources/AdminWikiView";

import GroupDetailView from "../views/groups/GroupDetailView";
import GroupTicketsView from "../views/groups/GroupTicketsView";
import GroupsView from "../views/groups/GroupsView";

import WatchListsView from "../views/watchlists/WatchListsView";

import TicketCreateView from "../views/tickets/TicketCreateView";
import TicketView from "../views/tickets/TicketView";
import TicketQueryView from "../views/tickets/TicketQueryView";
import ProfileView from "../views/ProfileView";
import DocumentationView from "../views/documentation/DocumentationView";
import ChatView from "../views/tickets/chat-view/ChatView";

const AUProfileView = Authorize(ProfileView, [], { public: false });
const AUWelcomeView = Authorize(WelcomeView, [], { public: false });
const AUSignView = Authorize(SignInView, [], { public: true });
const AUVerifyView = Authorize(VerifyView, [], { public: true });
const AUForgotView = Authorize(ForgotView, [], { public: true });
const AUDashboardView = Authorize(DashboardView, [], { public: false });
const AUNotificationsView = Authorize(NotificationsView, [], { public: false });
const AUChatView = Authorize(ChatView, [], { public: false });
// admin views
const AUAdminView = Authorize(AdminView, ["sysadmin", "agent"], {
  public: false
});
const AUAdminResourcesView = Authorize(
  AdminResourcesView,
  ["sysadmin", "agent"],
  { public: false }
);
const AUAdminUsersView = Authorize(AdminUsersView, ["sysadmin"], {
  public: false
});
const AUAdminGroupsView = Authorize(AdminGroupsView, ["sysadmin", "agent"], {
  public: false
});

const AUAdminWikiView = Authorize(AdminWikiView, ["sysadmin"], {
  public: false
});
const AUAdminSubscriptionsView = Authorize(
  AdminSubscriptionsView,
  ["sysadmin", "agent"],
  { public: false }
);
// groups views
const AUGroupsView = Authorize(GroupsView, [], { public: false });
const AUGroupDetailView = Authorize(GroupDetailView, [], { public: false });
// ticket views
const AUGroupTicketsView = Authorize(GroupTicketsView, [], { public: false });
const AUTicketCreateView = Authorize(TicketCreateView, [], { public: false });
const AUTicketView = Authorize(TicketView, [], { public: false });
const AUTicketQueryView = Authorize(TicketQueryView, [], { public: false });
// watchlists
const AUWatchListsView = Authorize(WatchListsView, [], { public: false });
// documentation
const AUDocumentationView = Authorize(DocumentationView, [], { public: false });

class RootView extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static mapStateToProps(state) {
    return {
      routing: state.routing,
      authentication: state.authentication,
      visibility: state.visibility
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      navigate: path => dispatch(push(path))
    };
  }

  render() {
    let { visibility } = this.props;

    const RouterSwitch = (
      <Switch>
        <Route path={ROUTES.ROOT} exact component={AUWelcomeView} />
        <Route path={ROUTES.LOGIN} exact component={AUSignView} />
        <Route path={ROUTES.VERIFY} component={AUVerifyView} />
        <Route path={ROUTES.FORGOT_PASSWORD} exact component={AUForgotView} />
        <Route path={ROUTES.PROFILE} exact component={AUProfileView} />
        <Route path={ROUTES.DASHBOARD} component={AUChatView} />

        <Route path={ROUTES.WIKI} component={AUChatView} />
        <Route path={ROUTES.WIKI_GROUP} component={AUChatView} />
        <Route path={ROUTES.WIKI_TOPIC} component={AUChatView} />
        <Route path={ROUTES.NOTIFICATIONS} component={AUNotificationsView} />
        <Route exact path={ROUTES.GROUPS} component={AUGroupsView} />
        <Route exact path={ROUTES.GROUP_DETAIL} component={AUGroupDetailView} />
        <Route
          exact
          path={ROUTES.GROUP_TICKETS}
          component={AUGroupTicketsView}
        />
        <Route exact path={ROUTES.WATCHLISTS} component={AUWatchListsView} />
        <Route exact path={ROUTES.TICKET} component={AUChatView} />
        <Route exact path={ROUTES.TICKET_ALT} component={AUChatView} />
        <Route
          exact
          path={ROUTES.TICKET_CREATE}
          component={AUTicketCreateView}
        />
        <Route exact path={ROUTES.QUERY_TICKET} component={AUTicketQueryView} />
        <Route exact path={ROUTES.ADMIN} component={AUAdminView} />
        <Route
          exact
          path={ROUTES.ADMIN_RESOURCES}
          component={AUAdminResourcesView}
        />
        <Route
          exact
          path={ROUTES.ADMIN_RESOURCES_WIKI}
          component={AUAdminWikiView}
        />
        <Route
          exact
          path={ROUTES.ADMIN_RESOURCES_USERS}
          component={AUAdminUsersView}
        />
        <Route
          exact
          path={ROUTES.ADMIN_RESOURCES_GROUPS}
          component={AUAdminGroupsView}
        />
        <Route
          exact
          path={ROUTES.ADMIN_RESOURCES_SUBSCRIPTIONS}
          component={AUAdminSubscriptionsView}
        />
        <Route
          exact
          path={ROUTES.ADMIN_RESOURCES_SUBSCRIPTIONS_FOR_GROUP}
          component={AUAdminSubscriptionsView}
        />
        <Route path={ROUTES.DOCUMENTATION} component={AUDocumentationView} />

        <Route
          exact
          path={"/reload"}
          render={props => {
            const { path } = queryString.parse(props.location.search, {
              ignoreQueryPrefix: true
            });

            if (path) {
              return <Redirect to={path} />;
            }

            return <Redirect to={"/dashboard"} />;
          }}
        />

        <Route component={Views.NotFoundView} />
      </Switch>
    );

    return (
      <div>
        <Responsive minWidth={769}>
          <div
            style={{
              display: "flex",
              flex: 1,
              height: "100vh",
              flexDirection: "column"
            }}>
            <NavigationBar />
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flex: "1",
                overflow: "hidden"
              }}>
              {RouterSwitch}
            </div>
            {/* <Footer /> */}
          </div>
        </Responsive>
        <Responsive maxWidth={768}>
          <div
            style={{
              display: "flex",
              minHeight: "100vh",
              flexDirection: "column"
            }}>
            <Sidebar.Pushable as={Segment} style={{ minHeight: "100vh" }}>
              <Sidebar
                as={Menu}
                animation="overlay"
                width="thin"
                visible={visibility.get("navigationSidebar")}
                icon="labeled"
                vertical
                inverted>
                <Menu.Item name="home" as={Link} to={ROUTES.DASHBOARD}>
                  <Icon name="home" />
                  Tickets
                </Menu.Item>
                <Menu.Item name="wiki" as={Link} to={ROUTES.WIKI}>
                  <Icon name="wikipedia w" />
                  Manuales
                </Menu.Item>
                <Menu.Item name="admin" as={Link} to={ROUTES.ADMIN_RESOURCES}>
                  <Icon name="adn" />
                  Administración
                </Menu.Item>
              </Sidebar>
              <Sidebar.Pusher
                style={{
                  height: "100%",
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column"
                }}
                id="pusherMobile">
                <NavigationBar mobile />
                {RouterSwitch}
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </div>
        </Responsive>
      </div>
    );
  }
}

export default connect(
  RootView.mapStateToProps,
  RootView.mapDispatchToProps
)(RootView);
