export default class ImageDrop {

  /**
   * Instantiate the module given a quill instance and any options
   * @param {Quill} quill
   * @param {Object} options
   */
  constructor (quill, options = {}) {
    // save the quill reference
    this.quill = quill;
    this.options = options;
    // bind handlers to this instance
    this.handleDrop = this.handleDrop.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    // listen for drop and paste events
    this.quill.root.addEventListener('drop', this.handleDrop, false);
    this.quill.root.addEventListener('paste', this.handlePaste, false);
  }

  /**
   * Handler for drop event to read dropped files from evt.dataTransfer
   * @param {Event} evt
   */
  handleDrop (evt) {
    evt.preventDefault();
    if (evt.dataTransfer && evt.dataTransfer.files && evt.dataTransfer.files.length) {
      this.showLoading(true);
      if (!!this.checkFileTypes(evt.dataTransfer.files)) {
        this.showLoading(true);
        evt.preventDefault();
      } else {
        this.showLoading(false);
      }
      if (document.caretRangeFromPoint) {
        const selection = document.getSelection();
        const range = document.caretRangeFromPoint(evt.clientX, evt.clientY);
        if (selection && range) {
          selection.setBaseAndExtent(range.startContainer, range.startOffset, range.startContainer, range.startOffset);
        }
      }

      let data = evt.dataTransfer.files;
      for (let i = 0; i < data.length; i += 1) {
        this.insert(null, null, data[i]);
      }

      this.readFiles(evt.dataTransfer.files, this.insert.bind(this));
    }
  }

  checkFileTypes = (files) => {
    //console.log("files", files);
    if (!files) return false;
    let i = 0;
    while (i < files.length) {
      let file = files[i];
      if(!!file.type.match(/^(image|audio|application|video)\/(gif|jpe?g|a?png|svg|webp|pdf|msword|x?-?mpeg3?|x?-?zip-?|x-?z?i?p?-compressed|x?-?excel|x-msexcel|pdf|vnd.ms-excel|bmp|vnd\.microsoft\.icon)/i)) {
        return true;
      };
      i++;
    }
    return false;
  };

  /**
   * Handler for paste event to read pasted files from evt.clipboardData
   * @param {Event} evt
   */
  handlePaste (evt) {
    //console.log("handlePaste");
    if (evt.clipboardData && evt.clipboardData.items && evt.clipboardData.items.length) {
      //console.log('event', evt);
      this.showLoading(true);
      if (!!this.checkFileTypes(evt.clipboardData.items)) {
        this.showLoading(true);
        evt.preventDefault();
      } else {
      	//console.log("showLoading False");
        this.showLoading(false);
        return;
      }
      let data = evt.clipboardData.items;
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].kind == 'file') {
          this.insert(null, null, data[i].getAsFile());
        }
      }

      this.readFiles(evt.clipboardData.items, (dataUrl, fileType) => {
        const selection = this.quill.getSelection();
        if (selection) {
          //console.log("Selection");
          this.quill.removeFormat(selection.index, selection.length, "api");
          // we must be in a browser that supports pasting (like Firefox)
          // so it has already been placed into the editor
        }
        else {
          // otherwise we wait until after the paste when this.quill.getSelection()
          // will return a valid index
        }
        setTimeout(() => this.insert(dataUrl, fileType), 0);
      });
    }
    return false;
  }

  showLoading (value = true) {
    if (!!this.options && !!this.options.handleLoading) {
      //console.log("handleLoading", value);
      this.options.handleLoading(value);
    }
  }

  /**
   * Insert the image into the document at the current cursor position
   * @param {String} dataUrl  The base64-encoded image URI
   */
  insert (dataUrl, fileType, file) {
    if (!!this.options && !!this.options.handleUri && !!dataUrl) {
      this.showLoading(false);
      this.options.handleUri(dataUrl, fileType);
    }
    if (!!this.options && !!this.options.handleFile && !!file) {
      this.showLoading(false);
      this.options.handleFile(file);
    }
    //this.quill.insertEmbed(index, 'image', dataUrl, 'user');
  }

  /**
   * Extract image URIs a list of files from evt.dataTransfer or evt.clipboardData
   * @param {File[]} files  One or more File objects
   * @param {Function} callback  A function to send each data URI to
   */
  readFiles (files, callback) {
    // check each file for an image
    [].forEach.call(files, file => {
      if(!!file.type.match(/^(image|audio|application|video)\/(gif|jpe?g|a?png|svg|webp|pdf|msword|x?-?mpeg3?|x?-?zip-?|x-?z?i?p?-compressed|x?-?excel|x-msexcel|pdf|vnd.ms-excel|bmp|vnd\.microsoft\.icon)/i)) {
        // file is not an image
        // Note that some file formats such as psd start with image/* but are not readable
        return;
      }
      // set up file reader
      const reader = new FileReader();
      let type = file.type;
      //console.log("fileType", type);
      reader.onload = (evt) => {
        callback(evt.target.result, type);
      };
      // read the clipboard item or file
      const blob = file.getAsFile ? file.getAsFile() : file;
      if (blob instanceof Blob) {
        reader.readAsDataURL(blob);
      }
    });
  }

}